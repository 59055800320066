import { useNavigate, useParams, useLocation } from "react-router-dom";
import Axios from "js/common/Axios";
import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Dropzone from "react-dropzone";
import SubjectIcon from '@mui/icons-material/Subject';
import AppContext from "js/common/AppContext";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "js/common/ui/button/IconButton";
import TextBox from "js/common/ui/input/TextBox";
import Util from "js/common/Util";
import SportsKabaddiOutlinedIcon from '@mui/icons-material/SportsKabaddiOutlined';

interface BoardMngWriteProps {
    boardCode: string
}
const BoardMngWrite = ({boardCode}: BoardMngWriteProps) => {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const QuillRef = React.useRef<ReactQuill>();
    const [boardName, setBoardName] = React.useState<string>("");
    const [boardTitle, setBoardTitle] = React.useState<string>("");
    const [boardCtt, setBoardCtt] = React.useState<string>("");
    const [boardFiles, setBoardFiles] = React.useState<File[]>([]);
    const [uldBoardFiles, setUldBoardFiles] = React.useState<boardFileProps[]>([]);
    interface boardFileProps {
        fileSn: number
        fileNm: string
        fileExt: string
        fileSize: number
    }

    const insBoardInfo = () => {
        if (Util.isEmpty(boardTitle)) {
            AppContext.openAlert({
                title: "제목 미입력",
                msg: "제목을 입력하세요",
                icon: "check"
            });
            return;
        }
        if (Util.isEmpty(boardCtt)) {
            AppContext.openAlert({
                title: "내용 미입력",
                msg: "내용을 입력하세요",
                icon: "check"
            });
            return;
        }
        let url = "";
        let fgNm = "";
        let param = {};
        if (Util.isEmpty(params.boardSn)) {
            url = "admin/board/insBoardInfo.do";
            fgNm = "등록";
            param = {
                boardCode: boardCode,
                boardTitle: boardTitle,
                boardCtt: boardCtt,
                isSecret: 0,
                isTop: 0,
                boardFiles: boardFiles
            };
        } else {
            url = "admin/board/updBoardInfo.do";
            fgNm = "수정";
            param = {
                boardSn: params.boardSn,
                boardTitle: boardTitle,
                boardCtt: boardCtt,
                boardFiles: boardFiles
            };
        }
        AppContext.openAlert({
            title: "게시글 " + fgNm,
            msg: "게시글을 " + fgNm + "하시겠습니까?",
            icon: "check",
            confirmText: fgNm,
            handleConfirm: () => {
                AppContext.showSpinner();
                Axios.dataAccess({
                    url: url,
                    methodType: "post",
                    isMultipart: true,
                    paramData: param,
                    onSuccessFn: () => {
                        AppContext.openAlert({
                            title: "게시글 " + fgNm + " 완료",
                            msg: "게시글이 " + fgNm + "되었습니다.",
                            icon: "check"
                        });
                        if (location.pathname.startsWith('/admin/notice', 0)) {
                            navigate("/admin/notice");
                        } else if (location.pathname.startsWith('/system/expert', 0)) {
                            navigate("/system/expert");
                        }
                        AppContext.hideSpinner();
                    }
                });
            }
        });
    }

    // 이미지를 업로드 하기 위한 함수
    const imageHandler = () => {
        // 파일을 업로드 하기 위한 input 태그 생성
        const input = document.createElement("input");

        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        // 파일이 input 태그에 담기면 실행 될 함수
        input.onchange = async () => {
            const file = input.files;

            if (file !== null) {
                Axios.dataAccess({
                    url: "admin/board/saveBoardQuillFile.do",
                    methodType: "post",
                    isMultipart: true,
                    paramData: {
                        boardImageFile: file[0]
                    },
                    onSuccessFn: (res: any) => {
                        const extNm = file[0].name.substring(file[0].name.lastIndexOf(".") + 1).toLowerCase();
                        const url = AppContext.backendUrl + "/common/image/quill/" + res.item + "." + extNm;
                        const range = QuillRef.current?.getEditor().getSelection()?.index;
                        if (range !== null && range !== undefined) {
                            const quill = QuillRef.current?.getEditor();
                            quill?.setSelection(range, 1);
                            quill?.clipboard.dangerouslyPasteHTML(range, `<img src=${url} alt=${url} />`);
                        }
                    }
                });
            }
        };
    };

    // quill에서 사용할 모듈을 설정하는 코드 입니다.
    // 원하는 설정을 사용하면 되는데, 저는 아래와 같이 사용했습니다.
    // useMemo를 사용하지 않으면, 키를 입력할 때마다, imageHandler 때문에 focus가 계속 풀리게 됩니다.
    const modules = React.useMemo(
        () => ({
            toolbar: {
                container: [
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [{ size: ["small", false, "large", "huge"] }, { color: [] }],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                        { align: [] },
                    ],
                    ["image", "video"],
                ],
                handlers: {
                    image: imageHandler,
                },
            },
        }),
        []
    );

    React.useEffect(() => {
        if (Util.isEmpty(boardCode) || boardCode === undefined) {
            return;
        }
        setBoardName(Util.getValToCode("1012", boardCode));
        if (Util.isEmpty(params.boardSn)) {
            return;
        }
        Axios.dataAccess({
            url: "admin/board/selBoardInfo.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                boardCode: boardCode,
                boardSn: params.boardSn
            },
            onSuccessFn: (res: any) => {
                setBoardTitle(res.item.boardInfo.boardTitle);
                setBoardCtt(res.item.boardInfo.boardCtt);
                setUldBoardFiles(res.item.boardFileList);
            }
        });
    }, [params.boardSn, boardCode]);

    return (
        <React.Fragment>
            <div className="dpFlx jcC aiC h150">
                <span className="fm-c fc222222 fs40 ls12">{boardName} {Util.isEmpty(params.boardSn) ? "등록" : "수정"}</span>
            </div>
            <div className="dpFlx jcC">
                <div className='w1200'>
                    <div className='dpFlx jcSb aiC pb20'>
                        <div className='dpFlx jcSb aiC'>
                            <SportsKabaddiOutlinedIcon/>
                            <span className='fs23 ls12 ml10'>{boardName} {Util.isEmpty(params.boardSn) ? "등록" : "수정"}</span>
                        </div>
                        <div className='dpFlx jcSb aiC'>
                            {Util.isEmpty(params.boardSn) ?
                                <IconButton btnName="목록으로" iconPos="left" iconNm="list" className="h35 bdN fs14 fcWhite bgcA4A4A4 mr3" onClick={() => {
                                    AppContext.openAlert({
                                        title: "알림",
                                        msg: "목록으로 이동하시겠습니까?",
                                        icon: "check",
                                        confirmText: "목록",
                                        handleConfirm: () => {
                                            if (location.pathname === "/admin/notice/reg") {
                                                navigate("/admin/notice");
                                            } else if (location.pathname === "/system/expert/reg") {
                                                navigate("/system/expert");
                                            }
                                        }
                                    });
                                }} />
                            : <></>}
                            {Util.isNotEmpty(params.boardSn) ?
                                <IconButton btnName="수정취소" iconPos="left" iconNm="delete" className="h35 bdN fs14 fcWhite bgcA4A4A4 mr3" onClick={() => {
                                    AppContext.openAlert({
                                        title: "알림",
                                        msg: "수정을 취소하시겠습니까?",
                                        icon: "check",
                                        confirmText: "수정취소",
                                        handleConfirm: () => {
                                            if (location.pathname.startsWith('/admin/notice', 0)) {
                                                navigate("/admin/notice/view/" + params.boardSn);
                                            } else if (location.pathname.startsWith('/system/expert', 0)) {
                                                navigate("/system/expert/view/" + params.boardSn);
                                            }
                                        }
                                    });
                                }} />
                            : <></>}
                            <IconButton btnName="저장하기" iconPos="right" iconNm="arrowRight" className="h35 fs14 fcWhite bdN bgc1E413A" onClick={() => {
                                insBoardInfo();
                            }} />
                        </div>
                    </div>
                    <div className='dpFlx jcSb aiC pb10'>
                        <div className='dpFlx jcSb aiC'>
                            <SubjectIcon style={{ fontSize: 25, color: 'black', paddingRight: 3 }} />
                            <span className='fs18 ls12'>제목</span>
                        </div>
                    </div>
                    <TextBox
                        value={boardTitle}
                        className="bd1 pw5 bgcWhite"
                        height={40}
                        fontSize={14}
                        fontFamily="fm-b"
                        maxLength={250}
                        placeholder="게시글 제목을 입력하세요."
                        onChangeFunc={(text: string) => {
                            setBoardTitle(text);
                        }}
                    />
                    <div className='dpFlx jcSb aiC pt20 pb10'>
                        <div className='dpFlx jcSb aiC'>
                            <SubjectIcon style={{ fontSize: 25, color: 'black', paddingRight: 3 }} />
                            <span className='fs18 ls12'>내용</span>
                        </div>
                    </div>
                    <ReactQuill
                        ref={(element) => {
                            if (element !== null) {
                                QuillRef.current = element;
                            }
                        }}
                        value={boardCtt}
                        onChange={setBoardCtt}
                        modules={modules}
                        theme="snow"
                        placeholder="내용을 입력해주세요."
                        style={{height: "300px", paddingBottom: "40px", fontFamily: "fm-b"}}
                    />
                    <div className='dpFlx jcSb aiC pb10 pt20'>
                        <div className='dpFlx jcSb aiC'>
                            <SubjectIcon style={{ fontSize: 25, color: 'black', paddingRight: 3 }} />
                            <span className='fs18 ls12'>첨부파일</span>
                        </div>
                    </div>
                    <Dropzone
                        onDrop={(acceptFiles: File[]) => {
                            if (uldBoardFiles.length + boardFiles.length + acceptFiles.length > 10) {
                                AppContext.openAlert({
                                    title: "첨부파일 갯수 제한",
                                    msg: "게시글 내 첨부파일은 10개를 초과할 수 없습니다.",
                                    icon: "check"
                                });
                                return;
                            }
                            for (const acceptFile of acceptFiles) {
                                const extNm = acceptFile.name.substring(acceptFile.name.lastIndexOf(".") + 1).toLowerCase();
                                if (AppContext.fileWhiteExt.indexOf(extNm) === -1) {
                                    AppContext.openAlert({
                                        title: "파일 확장자 체크",
                                        msg: extNm + " 확장자는 업로드 불가한 확장자의 파일입니다.",
                                        icon: "error"
                                    });
                                    break;
                                }
                                boardFiles.push(acceptFile);
                                setBoardFiles(boardFiles);
                            }
                        }}
                    >
                        {({getRootProps, getInputProps}) => (
                            <section>
                                <div className="w100p mh100 bdDash1 bdcCECECE csP pl10 pr10 pb10 bdBox" {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {uldBoardFiles.length === 0 && boardFiles.length === 0 ?
                                        <div className="dpFlx aiC jcC h90 bdBox">
                                            <span className="fm-b fs14 fc333333 ls08 pt10">첨부할 파일을 드래그하세요.</span>
                                        </div>
                                    : <></>}
                                    {uldBoardFiles.map((item: any, idx: number) => {
                                        return (
                                            <div key={idx} className="bdrd5 bd1 bdcCECECE p10 csP dpInBlock mr10 mt10 bgcFFE6CE" onClick={(e) => {
                                                e.stopPropagation();
                                                Axios.dataAccess({
                                                    url: "common/fileDownload.do",
                                                    methodType: "get",
                                                    isFileDown: true,
                                                    paramData: {
                                                        callType: "board",
                                                        fileName: params.boardSn + "-" + item.fileSn + "." + item.fileExt,
                                                        orgFileName: item.fileNm
                                                    }
                                                });
                                            }}>
                                                <div className="dpFlx aiC jcSb">
                                                    <span className="fm-b fs13 fcBlack ls08">{item.fileNm}</span>
                                                    <CloseIcon
                                                        style={{ fontSize: 20, color: 'black', paddingLeft: 5 }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            AppContext.openAlert({
                                                                title: "첨부파일 삭제",
                                                                msg: "첨부파일을 삭제하시겠습니까?",
                                                                icon: "check",
                                                                confirmText: "파일삭제",
                                                                handleConfirm: () => {
                                                                    AppContext.showSpinner();
                                                                    Axios.dataAccess({
                                                                        url: "admin/board/delBoardFileInfo.do",
                                                                        methodType: "post",
                                                                        paramData: {
                                                                            boardSn: item.boardSn,
                                                                            fileSn: item.fileSn,
                                                                            fileExt: item.fileExt
                                                                        },
                                                                        onSuccessFn: () => {
                                                                            const tempFiles = [];
                                                                            for (let i = 0; i < uldBoardFiles.length; i++) {
                                                                                if (i !== idx) {
                                                                                    tempFiles.push(uldBoardFiles[i]);
                                                                                }
                                                                            }
                                                                            setUldBoardFiles(tempFiles);
                                                                            AppContext.openAlert({
                                                                                title: "첨부파일 삭제완료",
                                                                                msg: "게시글 첨부파일이 삭제되었습니다.",
                                                                                icon: "check"
                                                                            });
                                                                            AppContext.hideSpinner();
                                                                        }
                                                                    });
                                                                }
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })}
                                    {boardFiles.map((item: any, idx: number) => {
                                        return (
                                            <div key={idx} className="bdrd5 bd1 bdcCECECE p10 csP dpInBlock mr10 mt10" onClick={(e) => {
                                                e.stopPropagation();
                                            }}>
                                                <div className="dpFlx aiC jcSb">
                                                    <span className="fm-b fs13 fcBlack ls08">{item.name}</span>
                                                    <CloseIcon
                                                        style={{ fontSize: 20, color: 'black', paddingLeft: 5 }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            const tempFiles = [];
                                                            for (let i = 0; i < boardFiles.length; i++) {
                                                                if (i !== idx) {
                                                                    tempFiles.push(boardFiles[i]);
                                                                }
                                                            }
                                                            setBoardFiles(tempFiles);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </div>
            </div>
            <div className='w100p h40 dpFlx aiC jcC pt20 pb30'>
                {Util.isEmpty(params.boardSn) ?
                    <IconButton btnName="목록으로" iconPos="left" iconNm="list" className="h35 bdN fs14 fcWhite bgcA4A4A4 mr3" onClick={() => {
                        AppContext.openAlert({
                            title: "알림",
                            msg: "목록으로 이동하시겠습니까?",
                            icon: "check",
                            confirmText: "목록",
                            handleConfirm: () => {
                                if (location.pathname === "/admin/notice/reg") {
                                    navigate("/admin/notice");
                                } else if (location.pathname === "/system/expert/reg") {
                                    navigate("/system/expert");
                                }
                            }
                        });
                    }} />
                : <></>}
                {Util.isNotEmpty(params.boardSn) ?
                    <IconButton btnName="수정취소" iconPos="left" iconNm="delete" className="h35 bdN fs14 fcWhite bgcA4A4A4 mr3" onClick={() => {
                        AppContext.openAlert({
                            title: "알림",
                            msg: "수정을 취소하시겠습니까?",
                            icon: "check",
                            confirmText: "수정취소",
                            handleConfirm: () => {
                                if (location.pathname.startsWith('/admin/notice', 0)) {
                                    navigate("/admin/notice/view/" + params.boardSn);
                                } else if (location.pathname.startsWith('/system/expert', 0)) {
                                    navigate("/system/expert/view/" + params.boardSn);
                                }
                            }
                        });
                    }} />
                : <></>}
                <IconButton btnName="저장하기" iconPos="right" iconNm="arrowRight" className="h35 fs14 fcWhite bdN bgc1E413A" onClick={() => {
                    insBoardInfo();
                }} />
            </div>
        </React.Fragment>
    )
}
export default BoardMngWrite;