import Util from "js/common/Util";
import React from "react";

interface TextAreaBoxProps {
    value: string
    setValue: React.Dispatch<React.SetStateAction<string>>
    className: string
    onChangeFunc?: Function
    autoHeight?: boolean
    placeholder?: string
    maxLength: number
}
const TextAreaBox = ({
    value,
    setValue,
    className,
    onChangeFunc,
    autoHeight,
    placeholder,
    maxLength
}: TextAreaBoxProps) => {
    const textareaRef = React.useRef<HTMLTextAreaElement | null>(null);

    const controlElHeight = () => {
        // textarea 높이 조절
        if (textareaRef && textareaRef.current) {
            const scrollHeight = textareaRef.current.scrollHeight;
            textareaRef.current.style.height = scrollHeight + "px";
        }
    }

    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.currentTarget.value);
        controlElHeight();
    };

    React.useEffect(() => {
        controlElHeight();
    }, [value]);

    return (
        <textarea
            ref={textareaRef}
            className={className}
            value={value}
            placeholder={Util.isEmpty(placeholder) ? "내용을 입력하세요." : placeholder}
            onChange={(e) => {
                if (autoHeight) {
                    onChange(e);
                }
                if (onChangeFunc !== undefined) {
                    onChangeFunc(e.target.value);
                }
            }}
            maxLength={maxLength}
        />
    )
}
export default TextAreaBox;