import React from "react";
import { Routes, Route } from "react-router-dom";
import { useRecoilValue } from "recoil";
import * as Atom from "js/common/Atom";
import Util from "js/common/Util";
import Login from 'js/portal/Login';
import Join from 'js/portal/Join';
import Cowork from "js/portal/Cowork";
import Mypage from "js/system/mypage/Mypage";
import UserMng from "js/admin/user/UserMng";
import SysMng from "js/admin/system/SysMng";
import MypageEdit from "js/system/mypage/MypageEdit";
import BoardWrite from "js/system/board/BoardWrite";
import Board from "js/system/board/Board";
import BoardView from "js/system/board/BoardView";
import Jury from "js/admin/jury/Jury";
import JuryView from "js/admin/jury/JuryView";
import JuryWrite from "js/admin/jury/JuryWrite";
import Intro from "js/portal/Intro";
import TermUse from "js/portal/TermUse";
import PrivacyPolicy from "js/portal/PrivacyPolicy";
import UserJury from "js/admin/user/UserJury";
import UserJuror from "js/admin/user/UserJuror";

function Body() {
    const isAdmin = useRecoilValue(Atom.isAdmin);
    const userInfo = useRecoilValue(Atom.userInfo);
    const menuList = [
        { url: "/portal/intro", el: <Intro /> }, // 서비스소개
        { url: "/portal/login", el: <Login /> }, // 로그인
        { url: "/portal/join", el: <Join /> }, // 회원가입
        { url: "/portal/cowork", el: <Cowork /> }, // 협력업체
        { url: "/portal/notice", el: <Board boardCode="BRD002" /> }, // 공지사항
        { url: "/portal/notice/view/:boardSn", el: <BoardView boardCode="BRD002" /> }, // 공지사항조회
        { url: "/portal/termuse", el: <TermUse /> }, // 이용약관
        { url: "/portal/privacy", el: <PrivacyPolicy /> }, // 개인정보처리방침
    ];
    const rendering = () => {
        const resEls = [<Route key="index" path='/' element={<Board boardCode="BRD002" />} />]; // 인덱스
        if (Util.isNotEmpty(userInfo.userSn)) {
            menuList.push({ url: "/system/mypage", el: <Mypage /> }); // 마이페이지
            menuList.push({ url: "/system/mypage/mod", el: <MypageEdit /> }); // 마이페이지수정
            menuList.push({ url: "/system/expert", el: <Board boardCode="BRD003" /> }); // 전문가커뮤니티
            menuList.push({ url: "/system/expert/reg", el: <BoardWrite boardCode="BRD003" /> }); // 전문가커뮤니티 등록
            menuList.push({ url: "/system/expert/mod/:boardSn", el: <BoardWrite boardCode="BRD003" /> }); // 전문가커뮤니티 수정
            menuList.push({ url: "/system/expert/view/:boardSn", el: <BoardView boardCode="BRD003" /> }); // 전문가커뮤니티 조회

            if (isAdmin) {
                menuList.push({ url: "/admin/user", el: <UserMng /> }); // 회원관리
                menuList.push({ url: "/admin/user/jury/:userSn", el: <UserJury /> }); // 회원 재판글
                menuList.push({ url: "/admin/user/juror/:userSn", el: <UserJuror /> }); // 회원 판결글
                menuList.push({ url: "/admin/notice", el: <Board boardCode="BRD002" /> }); // 공지사항관리
                menuList.push({ url: "/admin/notice/reg", el: <BoardWrite boardCode="BRD002" /> }); // 공지사항등록
                menuList.push({ url: "/admin/notice/mod/:boardSn", el: <BoardWrite boardCode="BRD002" /> }); // 공지사항수정
                menuList.push({ url: "/admin/notice/view/:boardSn", el: <BoardView boardCode="BRD002" /> }); // 공지사항조회
                menuList.push({ url: "/admin/system", el: <SysMng /> }); // 시스템관리
                menuList.push({ url: "/admin/jury", el: <Jury /> }); // 재판글관리
                menuList.push({ url: "/admin/jury/view/:lawSn/:callPage/:userSn", el: <JuryView /> }); // 재판조회
                menuList.push({ url: "/admin/jury/reg", el: <JuryWrite /> }); // 재판등록
                menuList.push({ url: "/admin/jury/mod/:lawSn", el: <JuryWrite /> }); // 재판수정
            }
        }
        interface menuProps {
            url: string
            el: JSX.Element
        }
        menuList.forEach((menuInfo: menuProps, idx: number) => {
            resEls.push(<Route key={idx} path={menuInfo.url} element={menuInfo.el} />);
        });
        return resEls;
    };
    return (
        <div className='posR zIdx0 mw1200 mh500 mb130 pw10'>
            <Routes>
                {rendering()}
            </Routes>
        </div>
    )
}
export default React.memo(Body);