import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import SubjectIcon from '@mui/icons-material/Subject';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';

const IconEl = ({iconNm}: any) => {
    if (iconNm === "arrowLeft") {
        return (
            <KeyboardArrowLeftIcon fontSize="inherit" sx={{padding: 0, fontSize: 18}} />
        )
    } else if (iconNm === "arrowRight") {
        return (
            <KeyboardArrowRightIcon fontSize="inherit" sx={{padding: 0, fontSize: 18}} />
        )
    } else if (iconNm === "search") {
        return (
            <SearchOutlinedIcon sx={{padding: 0, paddingLeft: "3px", fontSize: 18}} />
        )
    } else if (iconNm === "add") {
        return (
            <AddCircleOutlineOutlinedIcon sx={{padding: 0, paddingLeft: "3px", fontSize: 18}} />
        )
    } else if (iconNm === "delete") {
        return (
            <HighlightOffOutlinedIcon sx={{padding: 0, paddingLeft: "3px", fontSize: 18}} />
        )
    } else if (iconNm === "check") {
        return (
            <CheckCircleOutlineOutlinedIcon sx={{padding: 0, paddingLeft: "3px", fontSize: 18}} />
        )
    } else if (iconNm === "close") {
        return (
            <RefreshOutlinedIcon sx={{padding: 0, paddingLeft: "3px", fontSize: 18}} />
        )
    } else if (iconNm === "list") {
        return (
            <SubjectIcon sx={{padding: 0, paddingLeft: "3px", fontSize: 18}} />
        )
    } else if (iconNm === "file") {
        return (
            <AttachFileOutlinedIcon sx={{padding: 0, paddingLeft: "3px", fontSize: 18}} />
        )
    } else if (iconNm === "cancel") {
        return (
            <CancelOutlinedIcon sx={{padding: 0, paddingLeft: "3px", paddingRight: "3px", fontSize: 18}} />
        )
    } else if (iconNm === "calc") {
        return (
            <CalculateOutlinedIcon sx={{padding: 0, paddingLeft: "3px", paddingRight: "3px", fontSize: 18}} />
        )
    } else if (iconNm === "write") {
        return (
            <CreateOutlinedIcon sx={{padding: 0, paddingLeft: "3px", paddingRight: "3px", fontSize: 18}} />
        )
    } else {
        return (
            <></>
        )
    }
}

export default function IconButton({ btnName, iconPos, iconNm, className, onClick }: any) {
    return (
        <button 
            className={"dpFlx jcC aiC fm-b csP " + (iconPos === "left" ? "pr13" : "") + (iconPos === "right" ? "pl13" : "") + " " + className}
            onClick={onClick}
        >
            { iconPos === "left" ? <IconEl iconNm={iconNm} /> : <></> }
            <span className='ls12'>{btnName}</span>
            { iconPos === "right" ? <IconEl iconNm={iconNm} /> : <></> }
        </button>
    );
}