import { Link, useNavigate } from 'react-router-dom';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import NavBar from 'js/layout/NavBar';
import Axios from 'js/common/Axios';
import Util from 'js/common/Util';
import CustomButton from 'js/common/ui/CustomButton';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import * as Atom from "js/common/Atom";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import AppContext from 'js/common/AppContext';

function Header() {
    const navigate = useNavigate();
    const userInfo = useRecoilValue(Atom.userInfo);
    const delUserInfo = useResetRecoilState(Atom.userInfo);
    const tokenCrtDt = localStorage.getItem("tokenCrtDt");
    const setIsAdmin = useSetRecoilState(Atom.isAdmin);

    const goLoginPage = () => {
        AppContext.openAlert({
            title: "로그아웃",
            msg: "로그아웃 하시겠습니까?",
            icon: "check",
            confirmText: "로그아웃",
            handleConfirm: () => {
                goLogout();
            }
        });
    };

    const goLogout = () => {
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "system/home/insUserLog.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                logCode: "LOG004"
            },
            onSuccessFn: () => {
                delUserInfo(); // 유저정보 초기화
                localStorage.removeItem('tokenCrtDt');
                localStorage.removeItem('grantType');
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('isAdmin');
                setIsAdmin(false);
                navigate('/portal/login');
                AppContext.hideSpinner();
            }
        });
    };

    return (
        <div className="h149 posR zIdx1">
            <div className="w1200 h96 m0auto dpFlx pw10 bdBox">
                <Link to="/" className="flx2 dpFlx jcC aiC">
                    <img className="csP w80" src={require('img/portal/logo.png')} alt="싸판" />
                </Link>
                <div className="flx1 dpFlx jcR aiC">
                    {Util.isNotEmpty(tokenCrtDt) ? (
                        <>
                            <CustomButton
                                btnName={userInfo.nickName + "님 어서오세요."}
                                style={{
                                    borderRadius: 5,
                                    border: "1px solid #959595",
                                    boxShadow: "none"
                                }}
                                className="h35 mr30-i fs12-i bgcWhite-i fcBlack-i"
                                endIcon={<PersonSearchOutlinedIcon />}
                            />
                            <div className="dpFlx">
                                <div className="w50 csP dpFlx flxdCol aiC jcC">
                                    <LogoutOutlinedIcon style={{ fontSize: 40, color: 'black' }} onClick={goLoginPage} />
                                    <span className="fs10 fcBlack ls1 mt2">로그아웃</span>
                                </div>
                                {/* <Link to="/system/mypage" className="w50 csP dpFlx flxdCol aiC jcC tdN">
                                    <PersonOutlineOutlinedIcon style={{ fontSize: 40, color: 'black' }} />
                                    <span className="fs10 fcBlack ls1 mt2">MYINFO</span>
                                </Link> */}
                            </div>
                        </>
                    ) : (
                        <div className="dpFlx">
                            <div className="w50 csP dpFlx flxdCol aiC jcC">
                                <ExitToAppOutlinedIcon
                                    style={{ fontSize: 40, color: 'black' }}
                                    onClick={() => {navigate('/portal/login')}}
                                />
                                <span className="fs10 fcBlack ls1 mt2">로그인</span>
                            </div>
                            {/* <Link to="/portal/join" className="w50 csP dpFlx flxdCol aiC jcC tdN fcBlack">
                                <PersonOutlineOutlinedIcon style={{ fontSize: 40, color: 'black' }} />
                                <span className="fs10 fcBlack ls1 mt2">회원가입</span>
                            </Link> */}
                        </div>
                    )}
                </div>
            </div>
            <NavBar />
        </div>
    );
}
export default Header;