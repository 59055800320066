import React from "react";
import MypageUser from "js/system/mypage/edit/MypageUser";

const MypageEdit = () => {
    const [menuFlag, setMenuFlag] = React.useState<string>("userInfo");

    return (
        <div className="dpFlx flxdCol jcC">
            <h2 className="w100p m0 pt75 pb35 taC fs36 fm-c ls1">
                {menuFlag === "userInfo" ? "회원 기본정보 수정" : ""}
            </h2>
            <div className="w1200 m0auto">
                <div className="dpFlx w100p bdBox aiC pl50 pr50 pt20 pb20">
                    <div className='flx1 h100p dpFlx aiC jcC' onClick={() => {
                        setMenuFlag("userInfo");
                    }}>
                        <div className={'dpFlx aiC jcC w130 pb10 csP bdb4 ' + (menuFlag === "userInfo" ? "bdcForest" : "bdcWhite")}>
                            <span className={'fm-b fs20 ls08 ' + (menuFlag === "userInfo" ? "fc009696" : "fc505050")}>기본정보</span>
                        </div>
                    </div>
                    <div className='flx1 h100p dpFlx aiC jcC' onClick={() => {
                        setMenuFlag("bsnmInfo");
                    }}>
                        <div className={'dpFlx aiC jcC w130 pb10 csP bdb4 ' + (menuFlag === "bsnmInfo" ? "bdcForest" : "bdcWhite")}>
                            <span className={'fm-b fs20 ls08 ' + (menuFlag === "bsnmInfo" ? "fc009696" : "fc505050")}>사업자정보</span>
                        </div>
                    </div>
                    <div className='flx1 h100p dpFlx aiC jcC' onClick={() => {
                        setMenuFlag("subId");
                    }}>
                        <div className={'dpFlx aiC jcC w130 pb10 csP bdb4 ' + (menuFlag === "subId" ? "bdcForest" : "bdcWhite")}>
                            <span className={'fm-b fs20 ls08 ' + (menuFlag === "subId" ? "fc009696" : "fc505050")}>서브아이디</span>
                        </div>
                    </div>
                    <div className='flx1 h100p dpFlx aiC jcC' onClick={() => {
                        setMenuFlag("ticketMng");
                    }}>
                        <div className={'dpFlx aiC jcC w130 pb10 csP bdb4 ' + (menuFlag === "ticketMng" ? "bdcForest" : "bdcWhite")}>
                            <span className={'fm-b fs20 ls08 ' + (menuFlag === "ticketMng" ? "fc009696" : "fc505050")}>이용권관리</span>
                        </div>
                    </div>
                </div>
            </div>
            {menuFlag === "userInfo" ? <MypageUser /> : <></>}
        </div>
    )
}
export default MypageEdit;