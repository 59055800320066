import { Tooltip } from 'react-tooltip'

type PlacesType =
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'
type VariantType = 'dark' | 'light' | 'success' | 'warning' | 'error' | 'info'

interface IToolTip {
    id?: string
    place?: PlacesType
    className?: string
    variant?: VariantType
    clickable?: boolean
}
const ToolTip = ({id, place, className, variant, clickable}: IToolTip) => {
    return (
        <Tooltip id={id} place={place} className={'fs12-i fm-b ls08 wsPw zIdx1000 ' + className} variant={variant} clickable={clickable} offset={0} delayShow={500} />
    )
}
export default ToolTip;