const AppContext = {
    showSpinner: () => {},
    hideSpinner: () => {},
    showSpinnerDim: () => {},
    openAlert: ({title, msg, icon, closeText, handleClose, confirmText, handleConfirm}: any) => {},
    codeList: [],
    menuList: [
        {
            role: "portal",
            url: "/portal/intro",
            name: "서비스소개"
        },
        {
            role: "portal",
            url: "/portal/intrcn",
            name: "메뉴2",
            subMenuList: [
                { url: "/portal/intrcn", name: "서브메뉴1" },
                { url: "/portal/intrcn", name: "서브메뉴2" },
                { url: "/portal/intrcn", name: "서브메뉴3" }
            ]
        },
        {
            role: "portal",
            url: "/portal/intrcn",
            name: "메뉴3",
            subMenuList: [
                { url: "/portal/intrcn", name: "서브메뉴1" },
                { url: "/portal/intrcn", name: "서브메뉴2" },
                { url: "/portal/intrcn", name: "서브메뉴3" },
                { url: "/portal/intrcn", name: "서브메뉴4" },
                { url: "/portal/intrcn", name: "서브메뉴5" }
            ]
        },
        {
            role: "portal",
            url: "/portal/intrcn",
            name: "메뉴4",
            subMenuList: [
                { url: "/portal/intrcn", name: "서브메뉴1" },
                { url: "/portal/intrcn", name: "서브메뉴2" }
            ]
        },
        {
            role: "portal",
            url: "/portal/intrcn",
            name: "메뉴5",
            subMenuList: [
                { url: "/portal/intrcn", name: "서브메뉴1" },
                { url: "/portal/intrcn", name: "서브메뉴2" },
                { url: "/portal/intrcn", name: "서브메뉴3" }
            ]
        },
        {
            role: "portal",
            url: "/portal/notice",
            name: "공지사항"
        },
        {
            role: "admin",
            url: "/admin/user",
            name: "관리자시스템",
            subMenuList: [
                { url: "/admin/user", name: "회원관리" },
                { url: "/admin/notice", name: "공지사항관리" },
                { url: "/admin/jury", name: "재판글관리" }
            ]
        }
    ],
    fileWhiteExt: [""],
    imageWhiteExt: [""],
    maxfileSize: 0,
    backendUrl: "",
    accessTokenExpireTime: 0,
    refreshTokenExpireTime: 0
};
export default AppContext;