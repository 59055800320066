import Util from 'js/common/Util';
import Select from 'react-select';
import { CustomDropdownIndicator } from '../input/CustomDropdownIndicator';

interface codeProps {
    codeNm: string
    code: string
}
interface SelectBoxProps {
    name?: string
    onChange?: Function
    onChangeFunc?: Function
    codeArray: codeProps[]
    value?: string
    width?: number
    height?: number
    fontSize: number
    placeholder?: string
    textAlign?: "left" | "center" | "right"
    disabled?: boolean
    dsbOptVals?: string[]
    paddingRight?: number
    border?: string
}
function SelectBox({name, onChange, onChangeFunc, codeArray, value, width, height, fontSize, placeholder, textAlign, disabled, dsbOptVals, paddingRight, border}: SelectBoxProps) {
    const optBoxBgColor = (isDisabled: boolean, isSelected: boolean, isFocused: boolean) => {
        if (isDisabled) {
            return "#E8E8E8";
        }
        if (isSelected) {
            return "#1E413A";
        }
        if (isFocused) {
            return "#D7D7D7";
        }
    }
    const optBoxFontColor = (isDisabled: boolean, isSelected: boolean, isFocused: boolean) => {
        if (isDisabled) {
            return "#ffffff";
        }
        if (isSelected) {
            return "#ffffff";
        }
        if (isFocused) {
            return "#000000";
        }
    }
    return (
        <Select
            name={name}
            options={codeArray}
            value={codeArray.find((c: any) => c.code === value?.toString())}
            onChange={(item: any) => {
                if (onChange !== undefined) {
                    onChange(item.code);
                }
                if (onChangeFunc !== undefined) {
                    onChangeFunc(item.code);
                }
            }}
            getOptionValue={(option) => option.code}
            getOptionLabel={(option) => option.codeNm}
            isOptionDisabled={(option: any) => dsbOptVals !== undefined ? dsbOptVals.indexOf(option.code) !== -1 : false}
            placeholder={placeholder || "선택하세요."}
            noOptionsMessage={() => "검색된 항목이 없습니다."}
            maxMenuHeight={170}
            menuPlacement="auto"
            styles={{
                control: (baseStyles, state) => ({
                    border: Util.isEmpty(border) ? "none" : border,
                    borderColor: state.isFocused ? '#2B2B2B' : '#dddddd',
                    borderRadius: 0,
                    width: Util.isEmpty(width) ? "100%" : width,
                    height: Util.isEmpty(height) ? "100%" : height,
                    fontSize: fontSize + "px",
                    letterSpacing: "-1px",
                    display: "flex",
                    textAlign: textAlign,
                    boxSizing: "border-box",
                    paddingLeft: 0,
                    paddingRight: paddingRight
                }),
                option: (styles: any, {data, isDisabled, isSelected, isFocused }) => ({
                    ...styles,
                    height: height + "px",
                    fontSize: fontSize + "px",
                    color: optBoxFontColor(isDisabled, isSelected, isFocused),
                    letterSpacing: "-1px",
                    display: "flex",
                    justifyContent: textAlign,
                    alignItems: "center",
                    zIndex: 1,
                    backgroundColor: optBoxBgColor(isDisabled, isSelected, isFocused),
                    ":active": {
                        backgroundColor: "#D7D7D7",
                    }
                }),
                input: (base: any) => ({
                    ...base,
                    'input:focus': {
                        boxShadow: 'none',
                        border: '1px solid #60B3D1'
                    }
                })
            }}
            className={(Util.isEmpty(width) ? "w100p" : "w" + width) + (Util.isEmpty(height) ? " h100p" : " h" + height) + " SpoqaHanSansNeo-Medium "}
            classNamePrefix="select"
            isDisabled={disabled}
            components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: CustomDropdownIndicator
            }}
        />
    )
};
export default SelectBox;