import React from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import * as Atom from "js/common/Atom";
import { useNavigate } from 'react-router-dom';
import TextBox from "js/common/ui/input/TextBox";
import IconButton from "js/common/ui/button/IconButton";
import JSEncrypt from 'jsencrypt'
import Util from "js/common/Util";
import Axios from "js/common/Axios";
import AppContext from "js/common/AppContext";

const MypageUser = () => {
    const navigate = useNavigate();
    const [password, setPassword] = React.useState<string>("");
    const [newPassword, setNewPassword] = React.useState<string>("");
    const [cfmNewPassword, setCfmNewPassword] = React.useState<string>("");
    const [userInfo, setUserInfo] = useRecoilState(Atom.userInfo);
    const [userNm, setUserNm] = React.useState<string>(userInfo.userNm);
    const setIsAdmin = useSetRecoilState(Atom.isAdmin);

    const updUserInfo = () => {
        if (Util.isEmpty(userNm)) {
            AppContext.openAlert({
                title: "성명 필수입력",
                msg: "성명을 입력하세요.",
                icon: "check"
            });
            return;
        }
        if (Util.isEmpty(password)) {
            AppContext.openAlert({
                title: "기존 비밀번호 입력",
                msg: "기존 비밀번호를 입력하세요.",
                icon: "check"
            });
            return;
        }
        if (password.length < 8) {
            AppContext.openAlert({
                title: "기존 비밀번호 자릿수 확인",
                msg: "기존 비밀번호는 8자리 이상이어야 합니다.",
                icon: "check"
            });
            return;
        }
        if (Util.isNotEmpty(newPassword)) {
            if (newPassword.length < 10) {
                AppContext.openAlert({
                    title: "새 비밀번호 자릿수 확인",
                    msg: "새 비밀번호는 10자리 이상이어야 합니다.",
                    icon: "check"
                });
                return;
            }
            if (Util.isEmpty(cfmNewPassword)) {
                AppContext.openAlert({
                    title: "새 비밀번호 확인 입력",
                    msg: "새 비밀번호 확인을 입력하세요.",
                    icon: "check"
                });
                return;
            }
            if (newPassword !== cfmNewPassword) {
                AppContext.openAlert({
                    title: "비밀번호 불일치",
                    msg: "새 비밀번호가 일치하지 않습니다.",
                    icon: "check"
                });
                return;
            }
            if (password === newPassword) {
                AppContext.openAlert({
                    title: "기존 비밀번호와 일치",
                    msg: "새 비밀번호는 기존 비밀번호와 일치할 수 없습니다.",
                    icon: "check"
                });
                return;
            }
        }
        AppContext.openAlert({
            title: "회원정보 수정",
            msg: "회원정보를 수정하시겠습니까?",
            icon: "check",
            confirmText: "수정",
            handleConfirm: () => {
                setPassword("");
                setNewPassword("");
                setCfmNewPassword("");
                Axios.dataAccess({
                    url: "system/mypage/genRsaKeyByUserSn.do",
                    methodType: "post",
                    onSuccessFn: (res: any) => {
                        if (res.item.loginRslt !== "O") {
                            AppContext.hideSpinner();
                            AppContext.openAlert({
                                title: "회원정보 수정 실패",
                                msg: res.item.blockMsg,
                                icon: "warning"
                            });
                            return;
                        }
                        const pubKey = res.item.pubKeyStr;
                        const encrypt = new JSEncrypt();
                        encrypt.setPublicKey(pubKey);
                        Axios.dataAccess({
                            url: "system/mypage/updUserInfo.do",
                            methodType: "post",
                            paramType: "json",
                            paramData: {
                                userNm: userNm,
                                encPassword: encrypt.encrypt(password),
                                encNewPassword: Util.isNotEmpty(newPassword) ? encrypt.encrypt(newPassword) : null
                            },
                            onSuccessFn: (res: any) => {
                                setUserInfo(res.item);
                                localStorage.setItem("isAdmin", res.item.isAdmin ? "1" : "0");
                                setIsAdmin(res.item.isAdmin);
                                AppContext.openAlert({
                                    title: "회원정보 변경 완료",
                                    msg: "회원정보 변경이 완료되었습니다.",
                                    icon: "check"
                                });
                            }
                        });
                    }
                });
            }
        });
    }

    return (
        <React.Fragment>
            <div className="w1200 m0auto">
                <div className="bd1 bdcDFDFDF pt30 pb30 pr80 pl80">
                    <table className="w100p h150 fs14">
                        <colgroup>
                            <col className="w120"/>
                            <col/>
                            <col className="w120"/>
                            <col/>
                            <col className="w120"/>
                            <col/>
                        </colgroup>
                        <tbody>
                            <tr className="h70">
                                <td className="taL">
                                    <i className="w4 h4 dpInBlock mr10 bgc96c832 bdrd50p vtaM"></i>
                                    <span className="fm-b ls08">이메일주소</span>
                                </td>
                                <td>
                                    <span className="fm-b ls08">{userInfo.emailAddress}</span>
                                </td>
                                <td className="taL">
                                    <i className="w4 h4 dpInBlock mr10 bgc96c832 bdrd50p vtaM"></i>
                                    <span className="fm-b ls08">성명</span>
                                </td>
                                <td>
                                    <TextBox value={userNm} width={180} height={30} fontSize={14} className="mr3" placeholder="이름을 입력하세요." onChangeFunc={(text: string) => {
                                        setUserNm(text);
                                    }} />
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr className="h70">
                                <td className="taL">
                                    <i className="w4 h4 dpInBlock mr10 bgc96c832 bdrd50p vtaM"></i>
                                    <span className="fm-b ls08">기존비밀번호</span>
                                </td>
                                <td>
                                    <TextBox type="password" value={password} width={180} height={30} fontSize={13} maxLength={20} className="mr3" placeholder="기존 비밀번호를 입력하세요." onChangeFunc={(text: string) => {
                                        setPassword(text);
                                    }} />
                                </td>
                                <td className="taL">
                                    <i className="w4 h4 dpInBlock mr10 bgc96c832 bdrd50p vtaM"></i>
                                    <span className="fm-b ls08">새 비밀번호</span>
                                </td>
                                <td>
                                    <TextBox type="password" value={newPassword} width={180} height={30} fontSize={13} maxLength={20} className="mr3" placeholder="변경할 비밀번호를 입력하세요." onChangeFunc={(text: string) => {
                                        setNewPassword(text);
                                    }} />
                                </td>
                                <td className="taL">
                                    <i className="w4 h4 dpInBlock mr10 bgc96c832 bdrd50p vtaM"></i>
                                    <span className="fm-b ls08">새 비밀번호 확인</span>
                                </td>
                                <td>
                                    <TextBox type="password" value={cfmNewPassword} width={180} height={30} fontSize={13} maxLength={20} className="mr3" placeholder="변경할 비밀번호를 입력하세요." onChangeFunc={(text: string) => {
                                        setCfmNewPassword(text);
                                    }} />
                                </td>
                            </tr>
                            <tr className="h70">
                                <td className="taL">
                                    <i className="w4 h4 dpInBlock mr10 bgc96c832 bdrd50p vtaM"></i>
                                    <span className="fm-b ls08">전화번호</span>
                                </td>
                                <td><span className="fm-b ls08">{Util.cvtPhoneStrForm(userInfo.phoneNum, "-")}</span></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='w100p h40 dpFlx aiC jcC mt20 mb50'>
                <IconButton btnName="저장" iconPos="right" iconNm="arrowRight" className="w100 h35 fs14 fcWhite bdcForest bgcForest mr3" onClick={updUserInfo} />
                <IconButton btnName="취소" iconPos="right" iconNm="cancel" className="w100 h35 bdc878787 fs14 fcWhite bgcA4A4A4" onClick={() => {
                    navigate("/system/mypage");
                }} />
            </div>
        </React.Fragment>
    )
}
export default MypageUser;