import React from "react";
import Util from "js/common/Util";

interface TextBoxProps {
    type?: string
    value?: string
    width?: number
    height?: number
    borderOffColor?: string
    borderOnColor?: string
    fontSize: number
    fontFamily?: string
    phFontFamily?: string
    color?: string
    offColor?: string
    maxLength?: number
    className?: string
    mode?: string
    placeholder?: string
    iconEl?: JSX.Element
    inputClassName?: string
    onChangeFunc?: Function
    onKeyDown?: Function
}
function TextBox({
    type,
    value,
    width,
    height,
    borderOffColor,
    borderOnColor,
    fontSize,
    fontFamily,
    phFontFamily,
    color,
    offColor,
    maxLength,
    className,
    mode,
    placeholder,
    iconEl,
    inputClassName,
    onChangeFunc,
    onKeyDown
}: TextBoxProps) {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [focusState, setFocusState] = React.useState<boolean>(false);
    const [hoverState, setHoverState] = React.useState<boolean>(false);

    const calcFontColor = () => {
        if (mode === undefined || mode === "" || mode === null) {
            if (Util.isEmpty(color)) {
                return "#505050";
            } else {
                return color;
            }
        } else {
            if (Util.isEmpty(offColor)) {
                return "#505050";
            } else {
                return offColor;
            }
        }
    }

    React.useLayoutEffect(() => {
        if (inputRef.current) {
            inputRef.current.style.setProperty('--placeholder-color', (offColor === undefined ? "#999999" : offColor))
            inputRef.current.style.setProperty('--placeholder-font-family', (phFontFamily === undefined ? "" : phFontFamily))
        }
    }, [offColor, phFontFamily])

    return (
        <div
            className={"dpFlx jcSb aiC bdBox ofH olN " + (Util.isNotEmpty(className) ? className : "")}
            style={{
                width: Util.isEmpty(width) ? "100%" : width + "px",
                height: Util.isEmpty(height) ? "100%" : height + "px",
                lineHeight: Util.isEmpty(height) ? "100%" : height + "px",
                borderColor: (focusState || hoverState ? (Util.isEmpty(borderOnColor) ? "#DDDDDD" : borderOnColor) : (Util.isEmpty(borderOffColor) ? "#DDDDDD" : borderOffColor)),
            }}
            onMouseOver={() => setHoverState(true)}
            onMouseOut={() => setHoverState(false)}
            onClick={() => inputRef.current?.focus()}
        >
            <input
                ref={inputRef}
                type={type || "text"}
                value={value || ""}
                maxLength={maxLength || 10}
                style={{
                    fontSize: fontSize + "px",
                    fontFamily: Util.isEmpty(fontFamily) ? "SpoqaHanSansNeo-Regular" : fontFamily,
                    color: calcFontColor()
                }}
                className={"bgcTp bd0 olN w100p h100p bdBox " + inputClassName}
                onChange={(e) => {
                    if (onChangeFunc === undefined) {
                        return;
                    }
                    onChangeFunc(e.target.value);
                }}
                onKeyDown={(e) => {
                    if (onKeyDown === undefined) {
                        return;
                    }
                    onKeyDown(e);
                }}
                onFocus={() => setFocusState(true)}
                onBlur={() => setFocusState(false)}
                disabled={mode === "disabled"}
                readOnly={mode === "readonly"}
                placeholder={placeholder || ""}
            />
            {iconEl}
        </div>
    )
}
export default TextBox;