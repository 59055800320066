import { useNavigate, useLocation } from "react-router-dom";
import Axios from 'js/common/Axios';
import React from 'react';
import { useParams } from 'react-router-dom';
import IconButton from "js/common/ui/button/IconButton";
import "react-quill/dist/quill.snow.css";
import Util from "js/common/Util";
import AppContext from "js/common/AppContext";
import MuiListFilter from "js/common/ui/list/MuiListFilter";
import ForestTableStyle from 'js/common/ui/style/CustomStyle';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useRecoilValue } from "recoil";
import * as Atom from "js/common/Atom";
import SportsKabaddiOutlinedIcon from '@mui/icons-material/SportsKabaddiOutlined';

interface BoardProps {
    boardCode: string
}
const BoardView = ({boardCode}: BoardProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const userInfo = useRecoilValue(Atom.userInfo);
    const [boardTitle, setBoardTitle] = React.useState<string>("");
    const [boardCtt, setBoardCtt] = React.useState<string>("");
    const [boardFiles, setBoardFiles] = React.useState<boardFileProps[]>([]);
    interface boardFileProps {
        fileSn: number
        fileNm: string
        fileExt: string
        fileSize: number
    }
    const [replyCtt, setReplyCtt] = React.useState<string>("");
    const [boardSelTime, setBoardSelTime] = React.useState<Date>(new Date());
    const [filterDataArr, setFilterDataArr] = React.useState<any[]>([]);

    const goBack = () => {
        if (location.pathname.startsWith('/admin/notice', 0)) {
            navigate("/admin/notice");
        } else if (location.pathname.startsWith('/system/expert', 0)) {
            navigate("/system/expert");
        } else if (location.pathname.startsWith('/portal/notice', 0)) {
            navigate("/portal/notice");
        }
    }

    const goModify = () => {
        if (location.pathname.startsWith('/admin/notice', 0)) {
            navigate("/admin/notice/mod/" + params.boardSn);
        } else if (location.pathname.startsWith('/system/expert', 0)) {
            navigate("/system/expert/mod/" + params.boardSn);
        }
    }

    React.useEffect(() => {
        Axios.dataAccess({
            url: "portal/board/selBoardInfo.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                boardSn: Number(params.boardSn)
            },
            onSuccessFn: (res: any) => {
                setBoardTitle(res.item.boardInfo.boardTitle);
                setBoardCtt(res.item.boardInfo.boardCtt);
                setBoardFiles(res.item.boardFileList);
                setFilterDataArr(res.item.boardReplyList);
            }
        });
    }, [params.boardSn, boardSelTime]);

    return (
        <React.Fragment>
            <div className="dpFlx jcC aiC h150">
                <span className="fm-c fc222222 fs40 ls12">{Util.getValToCode("1012", boardCode)} 조회</span>
            </div>
            <div className="dpFlx jcC">
                <div className='w1200 h100p'>
                    <div className='dpFlx jcSb aiC pb20'>
                        <div className='dpFlx jcSb aiC'>
                            <SportsKabaddiOutlinedIcon />
                            <span className='fs23 ls12 ml10'>{boardTitle}</span>
                        </div>
                        <div className='dpFlx jcSb aiC'>
                            <IconButton btnName="목록으로" iconPos="left" iconNm="list" className="h35 bdN fs14 fcWhite bgcA4A4A4 mr3" onClick={() => {
                                goBack();
                            }} />
                            {location.pathname.startsWith('/admin/notice', 0) || location.pathname.startsWith('/system/expert', 0) ?
                                <IconButton btnName="수정하기" iconPos="left" iconNm="list" className="h35 bdN fs14 fcWhite bgc1E413A mr3" onClick={() => {
                                    goModify();
                                }} />
                            : <></>}
                        </div>
                    </div>
                    <div className="view ql-editor fm-b ls08" dangerouslySetInnerHTML={{ __html: boardCtt}} />
                    {boardFiles.length > 0 ?
                        <div className="w100p bdDot1 bdcCECECE pl10 pr10 pb10 mt30 bdBox">
                            {boardFiles.map((item: any, idx: number) => {
                                return (
                                    <div key={idx} className="bdrd5 bd1 bdcCECECE p10 csP dpInBlock mr10 mt10" onClick={(e) => {
                                        Axios.dataAccess({
                                            url: "common/fileDownload.do",
                                            methodType: "get",
                                            isFileDown: true,
                                            paramData: {
                                                callType: "board",
                                                fileName: params.boardSn + "-" + item.fileSn + "." + item.fileExt,
                                                orgFileName: item.fileNm
                                            }
                                        });
                                    }}>
                                        <div className="dpFlx aiC jcSb">
                                            <span className="fm-b fs13 fcBlack ls08">{item.fileNm}</span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    : <></>}
                    <div className="w100p h70 bd1 bdcCECECE bdBox mt20 dpFlx jcSb aiC p10 mb20">
                        <textarea className="w100p h100p rszN olN bd0 bdBox fm-b fs13 fcBlack ls08" value={replyCtt} onChange={(e) => setReplyCtt(e.target.value)}></textarea>
                        <IconButton btnName="댓글작성" iconPos="right" iconNm="write" className="w120 h50 bdN fs14 fcWhite bgc1E413A ml10" onClick={() => {
                            if (Util.isEmpty(replyCtt)) {
                                AppContext.openAlert({
                                    title: "댓글 미입력",
                                    msg: "댓글 내용을 작성하세요.",
                                    icon: "check"
                                });
                                return;    
                            }
                            AppContext.openAlert({
                                title: "댓글 작성",
                                msg: "댓글을 작성하시겠습니까?",
                                icon: "check",
                                confirmText: "댓글작성",
                                handleConfirm: () => {
                                    Axios.dataAccess({
                                        url: "system/board/insBoardReplyInfo.do",
                                        methodType: "post",
                                        paramType: "json",
                                        paramData: {
                                            boardSn: Number(params.boardSn),
                                            replyCtt: replyCtt
                                        },
                                        onSuccessFn: () => {
                                            setReplyCtt("");
                                            setBoardSelTime(new Date());
                                        }
                                    });
                                }
                            });
                        }} />
                    </div>
                    <MuiListFilter tableInfo={{
                        headVsb: true,
                        styleType: ForestTableStyle,
                        fldInfs: [
                            { fldNm: 'boardSn', pk: true },
                            { fldNm: 'replySn', pk: true },
                            { type: 'text', fldNm: 'replyCtt', wAuto: true, headAlign: "center", dataAlign: "center", label: '댓글내용' },
                            { type: 'text', fldNm: 'fstInsNm', width: 120, headAlign: "center", dataAlign: "center", label: '작성자' },
                            { type: 'text', fldNm: 'fstInsDt', width: 160, headAlign: "center", dataAlign: "center", label: '작성일자' },
                            { type: 'el', width: 30, headAlign: "center", label: '삭제', el: (item: any) => 
                                <DeleteForeverOutlinedIcon style={{ fontSize: 25, color: 'black', cursor: 'pointer', outline: 0, display: userInfo.userSn === item.replyUserSn ? "" : "none" }} onClick={() => {
                                    AppContext.openAlert({
                                        title: "댓글 삭제",
                                        msg: "댓글을 삭제하시겠습니까?",
                                        icon: "check",
                                        confirmText: "댓글삭제",
                                        handleConfirm: () => {
                                            Axios.dataAccess({
                                                url: "system/board/delBoardReplyInfo.do",
                                                methodType: "post",
                                                paramType: "json",
                                                paramData: {
                                                    boardSn: item.boardSn,
                                                    replySn: item.replySn
                                                },
                                                onSuccessFn: () => {
                                                    setBoardSelTime(new Date());
                                                }
                                            });
                                        }
                                    });
                                }} />
                            },
                        ],
                        ordFld: { name: "regDt", direction: "desc" },
                        dataArr: filterDataArr,
                        maxRowNums: [10],
                        trHeight: 40,
                        remainArea: false
                    }}>
                    </MuiListFilter>
                </div>
            </div>
        </React.Fragment>
    )
}
export default BoardView;