import React from 'react';
import Button from '@mui/material/Button';
import Util from '../Util';

export default function CustomButton({ btnName, style, hoverStyle, className, startIcon, endIcon, onClick }: any) {
    const [mount, setMount] = React.useState(false);
    const [hover, setHover] = React.useState(false);
    const [newStyle, setNewStyle] = React.useState({
        fontFamily: "NanumSquareNeo-cBd",
        letterSpacing: "-1.1px"
    });
    const handleMouseEnter = () => {
      setHover(true);
    };
    const handleMouseLeave = () => {
      setHover(false);
    };
    React.useEffect(() => {
        if (!mount) {
            setMount(true);
            if (Util.isEmpty(hoverStyle)) {
                setNewStyle({...newStyle, ...style, ...hoverStyle});
            }
        }
    }, [hover, style, hoverStyle, newStyle, setNewStyle, mount]);
    return (
        <Button
            sx={{borderRadius: 0, boxShadow: 0}}
            style={newStyle}
            variant="contained"
            startIcon={startIcon}
            endIcon={endIcon}
            className={className}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={onClick}
        >
            {btnName}
        </Button>
    );
}