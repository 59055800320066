import { useNavigate } from "react-router-dom";
import Util from "js/common/Util";
import SearchInput from "js/common/ui/SearchInput";
import MuiListFilter, { fldInfProps } from "js/common/ui/list/MuiListFilter";
import React from "react";
import ForestTableStyle from 'js/common/ui/style/CustomStyle';
import AppContext from "js/common/AppContext";
import Axios from "js/common/Axios";
import CustomButton from 'js/common/ui/CustomButton';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import SportsKabaddiOutlinedIcon from '@mui/icons-material/SportsKabaddiOutlined';

const Jury = () => {
    const navigate = useNavigate();
    const fldInfs: fldInfProps[] = [
        { type: 'text', fldNm: 'reqDt', width: 160, headAlign: "center", dataAlign: "center", label: '의뢰일시' },
        { type: 'text', fldNm: 'lawTime', width: 90, headAlign: "center", dataAlign: "center", label: '시간(분)' },
        { type: 'code', fldNm: 'lawOpenCode', width: 80, headAlign: "center", dataAlign: "center", label: '구분', codeTy: "1002" },
        { type: 'el', fldNm: 'reqUserNm', width: 100, headAlign: "center", label: '원고', el: (item: any) => item.reqUserNm + "(" + item.reqJurorCnt + ")"},
        { type: 'el', fldNm: 'defUserNm', width: 100, headAlign: "center", label: '피고', el: (item: any) => item.defUserNm + "(" + item.defJurorCnt + ")"},
        { type: 'text', fldNm: 'lawTitle', width: 110, headAlign: "center", dataAlign: "center", label: '제목' },
        { type: 'text', fldNm: 'viewCnt', width: 60, headAlign: "center", dataAlign: "center", label: '조회수' },
        { type: 'code', fldNm: 'viewPosCode', width: 90, headAlign: "center", dataAlign: "center", label: '조회권한', codeTy: "1007" },
        { type: 'text', fldNm: 'fstInsNm', width: 110, headAlign: "center", dataAlign: "center", label: '의뢰인' },
        { type: 'text', fldNm: 'picCnt', width: 50, headAlign: "center", dataAlign: "center", label: '사진' },
        { type: 'text', fldNm: 'crimeCnt', width: 50, headAlign: "center", dataAlign: "center", label: '신고' },
        { type: 'text', fldNm: 'itrstCnt', width: 50, headAlign: "center", dataAlign: "center", label: '하트' },
        { type: 'text', fldNm: 'replyCnt', width: 50, headAlign: "center", dataAlign: "center", label: '댓글' },
        { type: 'el', width: 30, headAlign: "center", label: '삭제', el: (item: any) => (
            <DeleteForeverOutlinedIcon style={{ fontSize: 25, color: 'black', cursor: 'pointer', outline: 0 }} onClick={(e) => {
                e.stopPropagation();
                delJuryInfo(item.lawSn);
            }} />
        )}
    ];
    const ordFld = { name: "reqDt", direction: "desc" };
    const [dataArr, setDataArr] = React.useState<any[]>([]);
    const [filterDataArr, setFilterDataArr] = React.useState<any[]>([]);
    const [srchText, setSrchText] = React.useState<string>("");
    const [jurySelTime, setJurySelTime] = React.useState<Date>(new Date());

    const onClickSrchBtn = () => {
        setFilterDataArr(Util.filterData(dataArr, fldInfs, srchText));
    }

    const delJuryInfo = (lawSn: number) => {
        AppContext.openAlert({
            title: "재판글 삭제",
            msg: "재판글을 삭제하시겠습니까?",
            icon: "check",
            confirmText: "삭제",
            handleConfirm: () => {
                AppContext.showSpinner();
                Axios.dataAccess({
                    url: "admin/jury/delJuryInfo.do",
                    methodType: "post",
                    paramType: "object",
                    paramData: {
                        lawSn: lawSn
                    },
                    onSuccessFn: () => {
                        AppContext.openAlert({
                            title: "재판글 삭제 완료",
                            msg: "재판글이 삭제되었습니다.",
                            icon: "check"
                        });
                        AppContext.hideSpinner();
                        setJurySelTime(new Date());
                    }
                });
            }
        });
    }

    React.useEffect(() => {
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "admin/jury/selJuryList.do",
            methodType: "post",
            paramType: "json",
            onSuccessFn: (res: any) => {
                setDataArr(res.list);
                setFilterDataArr(res.list);
                AppContext.hideSpinner();
            }
        });
    }, [jurySelTime]);

    return (
        <React.Fragment>
            <div className="dpFlx jcC aiC h120">
                <span className="fm-c fc222222 fs40 ls12">재판 관리</span>
            </div>
            <div className="dpFlx jcC">
                <div className='w1200'>
                    <div className='dpFlx jcSb aiC pb5'>
                        <div className='dpFlx jcSb aiC'>
                            <SportsKabaddiOutlinedIcon />
                            <span className='fs23 ls12 ml10'>재판리스트</span>
                        </div>
                        <div className='dpFlx jcSb aiC'>
                            <SearchInput data={srchText} setData={setSrchText} onClick={onClickSrchBtn} className="w200 h35 pl3 bd1 bdcCECECE" />
                            <CustomButton
                                btnName="재판등록"
                                className="h35 fs12-i bd1-i bdcBlack-i bgcWhite-i fcBlack-i ml5-i"
                                endIcon={<AddCircleOutlineOutlinedIcon />}
                                onClick={() => navigate("/admin/jury/reg")}
                            />
                        </div>
                    </div>
                    <MuiListFilter tableInfo={{
                        headVsb: true,
                        styleType: ForestTableStyle,
                        fldInfs: fldInfs,
                        ordFld: ordFld,
                        dataArr: filterDataArr,
                        maxRowNums: [10, 20, 30],
                        trHeight: 40,
                        remainArea: false,
                        rowClickFunc: (item: any) => {
                            navigate("/admin/jury/view/" + item.lawSn + "/juryList/0");
                        }
                    }}>
                    </MuiListFilter>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Jury;