const PrivacyPolicy = () => {
    return (
        <div className='dpFlx flxdCol jcC aiC'>
            <div className="w1200">
                <div className="dpFlx jcC aiC h150">
                    <span className="fm-c fc222222 fs40 ls12">개인정보처리방침</span>
                </div>
                <span className="fm-b fs14 ls06">
                    싸판은 이용자의 개인정보를 매우 중요하게 생각하며 정보통신 서비스 제공자가 준수하여야 하는 관련 법령 및 규정을 준수하고 있습니다.<br/><br/>
                    본 개인정보처리방침은 싸판의 웹사이트 및 모바일 웹, 앱 등에 적용되며, 다음과 같은 내용을 담고 있습니다.<br/><br/>
                    * 목차<br/>
                    1.	개인정보의 수집 및 이용 목적<br/>
                    2.	수집하는 개인정보 항목 및 수집방법<br/>
                    3.	개인정보의 제3자 제공<br/>
                    4.	개인정보 보유 및 이용기간<br/>
                    5.	개인정보 파기절차 및 방법<br/>
                    6.	이용자 및 법정대리인의 권리<br/>
                    7.	쿠키의 운영 및 수집 거부 방법<br/>
                    8.	개인정보 보호를 위한 기술적∙관리적 보호 대책<br/>
                    9.	개인정보 보호책임자 및 담당부서<br/>
                    10.	링크 사이트에 대한 책임<br/>
                    11.	개인정보처리방침의 고지 의무<br/>
                    12.	개정이력<br/><br/><br/>
                    <span className="fm-c">1. 개인정보의 수집</span><br/><br/>
                    서비스 제공을 위한 최소한의 개인정보를 수집하고 있습니다.<br/>
                    회원 가입 시 또는 서비스 이용 과정에서 아래와 같은 서비스 제공을 위해 필요한 최소한의 개인정보를 수집하고 있습니다.<br/><br/>
                </span>
                <table className={
                    "list-table w100p bgcWhite bdt1 bdb1 bdcDDDDDD tr-h44 mb20 " +
                    "th-bdb1 th-bdcDDDDDD th-fm-b th-fs14 th-fw500 " +
                    "td-p10 td-lh20 td-fs14 td-fm-b td-ls1 td-fc666666 td-bdcDDDDDD"
                }>
                <colgroup>
                    <col className="w30p" />
                    <col className="w30p" />
                    <col className="w40p" />
                </colgroup>
                <tbody>
                <tr>
                    <th className="taC" title='수집시기'>수집 시기</th>
                    <th className="taC" title='수집 방법'>수집 방법</th>
                    <th className="taC" title='수집 항목'>수집 항목</th>
                </tr>
                <tr>
                    <td>회원가입 시</td>
                    <td>모바일 앱</td>
                    <td>필수항목 : 이메일주소 또는 SNS ID, 비밀번호 , 닉네임<br/>선택항목 : 이름, 성별</td>
                </tr>
                <tr>
                    <td>본인인증 시</td>
                    <td>모바일 앱</td>
                    <td>필수항목 : 휴대전화번호, 이름, 성별, 출생년도, 내국인여부</td>
                </tr>
                <tr>
                    <td>별도 동의시</td>
                    <td>모바일 앱, 웹사이트</td>
                    <td>체험단 이용시 배송지정보, SNS ID</td>
                </tr>
                <tr>
                    <td>기타</td>
                    <td>서비스 이용과정에서 이용자로부터 수집하는 개인정보</td>
                    <td>PC웹, 모바일웹/앱 이용 과정에서 단말기정보(OS, 디바이스아이디, 폰기종), IP 주소, 쿠키, 방문일시, 부정이용기록, 서비스 이용기록 등의 정보가 자동으로 생성되어 수집될 수 있습니다.</td>
                </tr>
                </tbody>
                </table>
                <span className="fm-b fs14 ls06">
                    <span className="fm-c">2. 개인정보의 이용</span><br/><br/>
                    싸판은 아래와 같은 목적으로 서비스 제공을 위한 최소한의 개인정보만을 수집하며, 수집한 정보를 목적 외로 사용하거나, 이용자의 동의 없이 외부에 공개하지 않습니다.<br/><br/>
                    1) 회원관리 회원제 서비스 제공에 따른 개인식별, 가입의사 확인, 이용약관 및 운영정책 위반 회원에 대한 이용제한 조치, 가입 및 가입횟수 제한, 서비스 부정 이용 제재, 비인가 사용 방지, 만 14세 미만 아동의 개인정보 수집 시 법정대리인 동의 여부 확인, 추후 법정대리인 본인 확인, 고충 처리 및 분쟁 조정을 위한 기록 보존, 고지사항 전달, 회원탈퇴 의사의 확인<br/><br/>
                    2) 신규 서비스 개발 신규 서비스 개발 및 맞춤 서비스 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 이벤트·광고성 정보 및 참여 기회 제공, 접속빈도 파악, 회원의 서비스 이용에 대한 통계<br/><br/>
                    <span className="fm-c">3. 개인정보의 제공</span><br/><br/>
                    개인정보 제3자 제공<br/>
                    싸판은 이용자의 개인정보를 별도 동의가 있는 경우나 법령에 규정된 경우를 제외하고는 3자에게 제공하지 않습니다.<br/><br/>
                    개인정보 처리위탁<br/>
                    서비스 제공에 있어 필요한 업무 중 일부를 외부 업체로 하여금 수행하도록 개인정보를 위탁하고 있습니다. 그리고 위탁 받은 업체가 관계 법령을 준수하는 것을 확인했습니다.<br/>
                </span>
                <table className={
                    "list-table w100p bgcWhite bdt1 bdb1 bdcDDDDDD tr-h44 mb20 mt20 " +
                    "th-bdb1 th-bdcDDDDDD th-fm-b th-fs14 th-fw500 " +
                    "td-p10 td-lh20 td-fs14 td-fm-b td-ls1 td-fc666666 td-bdcDDDDDD"
                }>
                <colgroup>
                    <col className="w50p" />
                    <col className="w50p" />
                </colgroup>
                <tbody>
                <tr>
                    <th className="taC" title='제공 받는 자'>제공 받는 자</th>
                    <th className="taC" title='위탁업무 목적'>위탁업무 목적</th>
                </tr>
                <tr>
                    <td>구글(Analytics)</td>
                    <td>로그 분석 및 앱푸시 발송</td>
                </tr>
                </tbody>
                </table>
                <span className="fm-b fs14 ls06">
                    <span className="fm-c">4. 개인정보 보유 및 이용기간</span><br/><br/>
                    싸판은 원칙적으로 이용자의 개인정보 수집·이용 목적이 달성되면 지체 없이 파기합니다. 단, 다음의 경우는 예외로 합니다
                </span>
                <table className={
                    "list-table w100p bgcWhite bdt1 bdb1 bdcDDDDDD tr-h44 mb20 mt20 " +
                    "th-bdb1 th-bdcDDDDDD th-fm-b th-fs14 th-fw500 " +
                    "td-p10 td-lh20 td-fs14 td-fm-b td-ls1 td-fc666666 td-bdcDDDDDD"
                }>
                <colgroup>
                    <col className="w40p" />
                    <col className="w40p" />
                    <col className="w20p" />
                </colgroup>
                <tbody>
                <tr>
                    <th className="taC" title='보존 근거'>보존 근거</th>
                    <th className="taC" title='보존 기록'>보존 기록</th>
                    <th className="taC" title='보유 기간'>보유 기간</th>
                </tr>
                <tr>
                    <td>통신비밀보호법</td>
                    <td>웹사이트 방문기록</td>
                    <td>3개월</td>
                </tr>
                <tr>
                    <td>싸판 내부 방침 (이용제한 중 부정가입 및 이용 방지, 사법기관 수사의뢰 시 증거자료)</td>
                    <td>개인정보 및 부정이용기록(불량 혹은 비정상 이용기록): 이름, 이메일주소, IP, 로그기록, 단말기 정보(Unique ID)</td>
                    <td>1년</td>
                </tr>
                <tr>
                    <td>싸판 내부 방침(이벤트 신청 이용에 따른 소비자의 불만 또는 분쟁처리에 관한 기록 보존)</td>
                    <td>이벤트 신청 등 서비스 이용기록: 이름, 휴대전화번호, 이메일주소, 성별, 생년월일</td>
                    <td>3년</td>
                </tr>
                <tr>
                    <td>정보통신망법</td>
                    <td>1년간 서비스를 이용하지 않은 회원의 개인정보</td>
                    <td>1년</td>
                </tr>
                </tbody>
                </table>
                <span className="fm-b fs14 ls06">
                    <span className="fm-c">5. 개인정보 파기절차 및 방법</span><br/><br/>
                    싸판은 원칙적으로 개인정보 처리목적이 달성된 경우 지체 없이 해당 개인정보를 파기하며, 파기절차 및 파기방법은 다음과 같습니다.<br/>
                    1.	파기절차 이용자가 회원가입 등을 위해 입력한 정보는 수집 목적이 달성된 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, 별도의 DB로 옮겨진 개인정보는 법률에 의한 경우가 외에는 다른 목적으로 이용되지 않습니다.<br/>
                    2.	파기방법<br/>
                    1{")"}	전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.<br/>
                    2{")"}	종이에 출력된 개인 정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.<br/><br/>
                    <span className="fm-c">6. 이용자 및 법정대리인의 권리</span><br/><br/>
                    이용자 또는 법정대리인은 싸판에 대해 언제든지 자신 혹은 만 14세 미만 아동의 개인정보 보호 관련 권리를 행사할 수 있습니다. 이용자 또는 법정대리인은 싸판의 개인정보 처리에 동의하지 않는 경우 동의 철회 혹은 회원 탈퇴를 요청할 수 있습니다. 단, 이 경우 서비스의 일부 또는 전부의 이용이 어려울 수 있습니다.<br/>
                    1.	개인정보 조회, 수정을 위해서는 서비스 내 “기본정보” 또는 “계정관리” 를, 회원탈퇴를 위해서는 서비스 내 "회원탈퇴" 또는 싸판의 전자우편을 통해 회원탈퇴를 신청한 후 본인 확인 절차를 거치고 직접 열람, 정정 또는 탈퇴가 가능합니다.<br/>
                    2.	혹은 개인정보 보호책임자에게 서면 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.<br/>
                    3.	이용자가 개인정보의 오류에 대한 정정을 요청하는 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.<br/>
                    4.	싸판은 이용자 또는 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 "5. 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.<br/><br/>
                    <span className="fm-c">7. 쿠키의 운영 및 수집 거부 방법</span><br/><br/>
                    1.	싸판은 이용자에게 맞춤형 서비스를 제공하고, 보다 신속한 서비스 제공을 위해 이용자에 대한 정보를 저장하고 수시로 불러오는 ‘쿠키(Cookie)’를 사용합니다. 쿠키란 웹사이트 방문 시, 서버가 이용자의 하드디스크에 저장하는 작은 기록 정보 파일을 말합니다. 이후 이용자가 웹사이트에 방문할 경우 웹사이트 서버는 이용자의 하드디스크에 저장되어 있는 쿠키의 내용을 읽어 이용자의 환경설정을 유지하고 맞춤화된 서비스를 제공합니다. 쿠키는 개인을 식별하는 정보를 자동적·능동적으로 수집하지 않으며, 이용자는 언제든지 이러한 쿠키의 저장을 거부하거나 삭제할 수 있습니다. 단, 쿠키 저장을 거부하는 경우에는 일부 서비스 이용이 어려울 수 있습니다.<br/>
                    2.	쿠키의 설치 / 운용 및 거부 방법 안내 이용자는 사용하는 웹 브라우저의 옵션을 설정함으로써 모든 쿠키를 허용 혹은 거부하거나, 쿠키가 저장될 때마다 확인을 거치도록 지정할 수 있습니다.<br/><br/>
                    Internet Explorer 의 경우<br/>
                    [도구] 메뉴에서 [인터넷 옵션]을 선택합니다.<br/>
                    [개인정보 탭]에서 설정합니다.<br/>
                    [개인정보취급 수준]을 설정합니다.<br/>
                    Chrome의 경우<br/>
                    [설정] 하단 [고급설정 표시]를 클릭합니다.<br/>
                    [개인정보] 컨텐츠 설정 {"->"} [쿠키] 영역에서 원하시는 정책을 선택합니다.<br/><br/>
                    <span className="fm-c">8. 개인정보 보호를 위한 기술적/관리적 보호 대책</span><br/><br/>
                    싸판은 이용자들의 개인정보 보호를 위해 다음과 같은 기술적∙관리적 노력을 하고 있습니다.<br/>
                    1.	개인정보 암호화 이용자의 비밀번호 등 중요정보는 암호화되어 저장, 관리되고 있으며, 개인정보의 확인 및 변경은 본인에 의해서만 가능합니다.<br/>
                    2.	해킹 등에 대비한 대책 싸판은 해킹이나 악성코드에 의하여 이용자들의 개인정보가 유출· 훼손되는 것을 방지하기 위하여 침입차단 시스템을 24시간 운영하여 외부로부터의 무단접근을 통제하고 있으며, 백신 프로그램을 설치하여 시스템이 악성코드나 바이러스에 감염되지 않도록 노력하고 있습니다. 또한 개인정보의 훼손에 대비하여 자료를 수시로 백업하고 있고, 암호화통신 등을 통하여 네트워크 상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 더불어 싸판은 더욱 안전한 개인정보 처리를 위해 최대한의 기술적 방법을 구비하기 위해 노력하고 있습니다.<br/>
                    3.	개인정보 처리 직원의 최소화 및 교육 싸판은 개인정보를 처리하는 직원을 최소한으로 제한하고 있으며, 관련 직원들에 대한 수시 교육을 실시하여 개인정보처리방침의 중요성을 인지시키고 있습니다.<br/><br/>
                    <span className="fm-c">9. 개인정보 보호책임자 및 담당부서</span><br/><br/>
                    이용자는 싸판의 서비스를 이용하는 중 발생하는 모든 개인정보 관련 문의, 불만처리 등에 관한 사항을 개인정보 보호책임자 혹은 담당부서로 문의할 수 있습니다. 싸판은 이용자의 문의에 대한 신속하고 성실한 답변 및 처리를 위해 노력하고 있습니다.<br/>
                    [ 개인정보 보호책임자 ]<br/>
                    1.	담당부서/직위: 아레오파고스<br/>
                    2.	개인정보 보호책임자: 박종영<br/>
                    3.	이메일주소: ssapancso@gmail.com<br/>
                    기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관으로 문의하시기 바랍니다.<br/>
                    1.	개인정보침해신고센터, 개인정보 분쟁조정위원회 (privacy.kisa.or.kr / 국번없이 118)<br/>
                    2.	대검찰청 사이버수사과 (www.spo.go.kr / 02-3480-3571)<br/>
                    3.	전화번호: 02-501-6245<br/>
                    4.	경찰청 사이버테러대응센터 (www.ctrc.go.kr / 1566-0112)<br/><br/>
                    <span className="fm-c">10. 링크 사이트에 대한 책임</span><br/><br/>
                    싸판은 이용자에게 다른 웹사이트에 대한 링크를 제공할 수 있습니다. 그러나 링크 웹사이트들은 싸판의 개인정보처리방침이 적용되지 않으므로, 해당 링크를 통해 외부 웹사이트로 이동하시는 경우, 해당 서비스의 정책을 검토하시기 바랍니다.<br/><br/>
                    <span className="fm-c">11. 개인정보처리방침의 고지 의무</span><br/><br/>
                    싸판은 개인정보처리방침에 대한 변경이 있을 경우에는 개정 개인정보처리방침의 시행일로부터 최소 7일 전에 홈페이지의 공지사항 또는 이메일을 통해 고지합니다. 또한 필요 시 이용자 동의를 다시 받을 수도 있습니다.<br/>
                </span>
            </div>
        </div>
    )
}
export default PrivacyPolicy;