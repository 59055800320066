import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import ForestStyle from 'js/common/ui/style/CustomStyle';

function CheckBox({ onChange, value, label, className, checked }: any) {
    return (
        <FormControlLabel
            control={<Checkbox sx={ForestStyle.checkbox} checked={checked} />}
            label={<Typography sx={ForestStyle.checkboxLabel}>{label}</Typography>}
            onChange={onChange}
            value={value || ''}
            sx={{ marginLeft: 0}}
            className={className}
        />
    )
}
export default CheckBox;