const TermUse = () => {
    return (
        <div className='dpFlx flxdCol jcC aiC'>
            <div className="w1200">
                <div className="dpFlx jcC aiC h150">
                    <span className="fm-c fc222222 fs40 ls12">이용약관</span>
                </div>
                <span className="fm-b fs14 ls06">
                    <span className="fm-c fs15">제 1 조 (목적)</span><br/>
                    본 약관은 아레오파고스가 제공하는 모바일앱 '싸판'(이하 "서비스"라 함)의 이용조건 및 절차, 회원과 회사간의 권리, 의무 및 책임사항 등 기본적인 사항을 규정함을 목적으로 합니다.<br/><br/>
                    <span className="fm-c fs15">제 2 조 (용어의 정의)</span><br/>
                    본 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br/>
                    1.	“싸판“이라 함은 ”회사”가 운영하는 가상의 사업장을 말합니다.<br/>
                    2. "서비스" : 회사가 제공하는 모바일앱 서비스를 의미합니다.<br/>
                    3.	"회원" : 서비스를 이용하기 위하여 회사와 이용계약을 체결하고 회사가 제공하는 서비스를 이용하는 자를 말합니다.<br/>
                    4.	"아이디(ID)" : 회원의 식별과 서비스 이용을 위하여 회원이 설정하고 회사가 승인하는 문자와 숫자의 조합을 말합니다.<br/>
                    5.	"비밀번호" : 회원이 부여 받은 아이디와 일치되는 회원임을 확인하고 회원의 권익 및 비밀보호를 위해 회원 자신이 설정한 문자와 숫자의 조합을 말합니다.<br/>
                    6.	‘닉네임’이란 회원식별과 회원의 서비스 이용을 위하여 회원이 선정하고 회사가 승인하는 문자열을 말합니다.<br/>
                    7.	"유료서비스" : 회사가 유료로 제공하는 서비스를 말합니다.<br/>
                    8.	"게시물" : 회원이 서비스를 이용함에 있어 회원이 작성한 글, 사진, 동영상 등의 정보를 말합니다.<br/><br/>
                    <span className="fm-c fs15">제 3 조 (약관의 효력 및 변경)</span><br/>
                    1.	본 약관은 서비스를 이용하고자 하는 모든 회원에게 그 효력이 발생합니다.<br/>
                    2.	회원이 회사의 서비스 이용을 위해 회원가입을 진행하였을 경우 본 약관의 내용을 모두 읽고 이를 충분히 이해하였으며, 회사가 운영하는 서비스를 지속적으로 사용하는 것에 동의함을 의미합니다. 변경된 약관에 대한 정보를 알지 못하여 발생하는 회원의 피해에 대하여 회사는 책임이 없습니다.<br/>
                    3.	회사는 위치정보의 보호 및 이용 등에 관한 법률, 콘텐츠산업 진흥법, 전자상거래 등에서의 소비자보호에 관한 법률, 소비자기본법 약관의 규제에 관한 법률 등 관련법령을 위배하지 않는 범위에서 본 약관을 변경할 수 있습니다.<br/>
                    4.	회사가 약관을 변경할 경우에는 적용일자와 변경사유를 서비스 화면에 게시하거나 기타의 방법으로 공지합니다. 다만, 변경 내용이 회원에게 불리할 수 있는 중대한 내용의 약관 변경의 경우에는 그 적용일자 30일 전부터 해당 서비스 내에 고지하고 서비스 홈페이지에 게시하거나 회원에게 전자적 형태(전자우편, SMS 등)로 고지합니다.<br/>
                    5.	이용자가 연락처를 기재하지 않았거나 변경하여 발송이 어려울 경우에는 본 서비스 화면에 변경 약관을 공지하는 것을 개별 발송한 것으로 간주합니다.<br/>
                    6.	회사가 본 조 제4항에 따라 변경약관을 공지하거나 통지하면서, 회원이 약관 변경 적용일까지 거부 의사를 표시하지 아니하면 약관 변경에 동의한 것으로 간주한다는 내용을 공지 또는 통지하였음에도 회원이 변경된 약관의 효력 발생일까지 약관 변경에 대한 거부 의사를 표시하지 않을 때에는 회원이 변경약관에 동의한 것으로 간주합니다. 회원은 변경된 약관에 동의하지 않으면 본 서비스의 이용을 중단하고 이용계약을 해지 할 수 있습니다.<br/>
                    7.	회사는 이용자가 요구하면 본 약관의 사본을 이용자에게 교부하며, 이용자가 서비스 내에서 본 약관을 인쇄할 수 있도록 조치합니다.<br/>
                    8.	회사는 서비스 중 특정 서비스에 적용하기 위한 약관(이하 “개별약관”이라 함)을 별도로 제정할 수 있으며, 이용자가 개별약관에 동의한 경우 개별약관은 이용계약의 일부를 구성합니다. 개별약관에 본 약관과 상충하는 내용이 있을 경우 달리 정함이 없는 한 개별약관의 내용이 우선 적용됩니다.<br/>
                    9.	회사는 본 약관에 세부적으로 규정되지 않는 내용에 대해 상세히 정하기 위하여 운영정책을 제정할 수 있고, 본 약관 및 운영정책의 구체적인 적용 및 싸판 서비스의 이용방법을 안내하는 도움말을 작성하여 안내할 수 있으며, 해당 내용을 싸판 공지를 통해 게시합니다.<br/><br/>
                    <span className="fm-c fs15">제 4 조 (이용계약 성립 및 가입의 제한)</span><br/>
                    1.	회원이 되기 위해서는 약관의 내용에 대하여 동의를 한 다음 회원가입 신청을 하고 회사가 이러한 신청에 대하여 승낙함으로써 성립됩니다.<br/>
                    2.	회사는 가입신청자의 신청에 대하여 서비스 이용을 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.<br/>
                    1)	가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 단 회사의 회원 재가입 승낙을 얻은 경우에는 예외로 함.<br/>
                    2)	실명이 아니거나 타인의 명의를 이용한 경우<br/>
                    3)	허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우<br/>
                    4)	회원의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우<br/>
                    5)	사회의 안녕질서 또는 미풍양속을 저해하거나, 저해할 목적으로 신청한 경우<br/>
                    3.	회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.<br/>
                    4.	제2항과 제3항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, 회사는 원칙적으로 이를 가입신청자에게 알리도록 합니다.<br/>
                    5.	이용계약의 성립 시기는 회사가 가입완료를 신청절차 상에서 표시한 시점으로 합니다.<br/>
                    6.	회사는 가입신청자가 관계 법령에서 규정하는 미성년자일 경우에 서비스별 안내에서 정하는 바에 따라 승낙을 보류할 수 있습니다.<br/>
                    7.	회사는 회원에 대해 회사정책에 따라 등급별로 구분하여 이용시간, 이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.<br/>
                    8.	회사는 회원에 대하여 청소년보호법등에 따른 등급 및 연령 준수를 위해 이용제한이나 등급별 제한을 할 수 있습니다.<br/><br/>
                    <span className="fm-c fs15">제 5 조 (회원정보의 변경)</span><br/>
                    1.	회원은 개인정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 이메일 등은 수정이 불가능합니다.<br/>
                    2.	회원은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 회사에 대하여 그 변경사항을 알려야 합니다.<br/>
                    3.	제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.<br/><br/>
                    <span className="fm-c fs15">제 6 조 (개인정보의 보호)</span><br/>
                    여러분의 개인정보의 안전한 처리는 회사에게 있어 가장 중요한 일 중 하나입니다. 여러분의 개인정보는 서비스의 원활한 제공을 위하여 여러분이 동의한 목적과 범위 내에서만 이용됩니다. 관련 법령에 의하거나 여러분이 별도로 동의하지 아니하는 한 회사가 여러분의 개인정보를 제 3제에게 제공하는 일은 결코 없으므로, 안심하셔도 좋습니다. 회사가 여러분의 개인정보를 안전하게 처리하기 위하여 기울이는 노력이나 기타 자세한 사항은 싸판 개인정보처리방침을 참고해주시기 바랍니다.<br/><br/>
                    <span className="fm-c fs15">제 7 조 (회사의 의무)</span><br/>
                    1.	회사는 회원이 희망한 서비스 제공 개시일에 특별한 사정이 없는 한 서비스를 이용할 수 있도록 하여야 합니다.<br/>
                    2.	회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보 보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다.<br/>
                    3.	회사는 서비스이용과 관련하여 발생하는 이용자의 불만 또는 피해구제요청을 적절하게 처리할 수 있도록 필요한 인력 및 시스템을 구비합니다.<br/>
                    4.	회사는 서비스이용과 관련하여 회원으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 적절한 절차를 거쳐 이를 처리하여야 합니다. 회원이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 회원에게 처리과정 및 결과를 전달합니다.<br/><br/>
                    <span className="fm-c fs15">제 8 조 (회원의 의무)</span><br/>
                    1.	회원은 회원가입 신청 또는 회원정보 변경 시 모든 사항을 사실에 근거하여 본인의 진정한 정보로 작성하여야 하며, 허위 또는 타인의 정보를 등록할 경우 이와 관련된 모든 권리를 주장할 수 없습니다.<br/>
                    2.	회원은 약관에서 규정하는 사항과 기타 회사가 정한 운영정책 등의 제반 규정, 공지사항 등 회사가 공지하는 사항 및 관계 법령을 준수하여야 하며, 기타 회사의 업무에 방해가 되는 행위, 회사의 명예를 손상시키는 행위, 타인에게 피해를 주는 행위를 해서는 안됩니다.<br/>
                    3.	회원은 회사의 사전 승낙 없이 서비스를 이용하여 영업활동을 할 수 없으며, 그 영업활동의 결과에 대해 회사는 책임을 지지 않습니다. 또한 회원은 이와 같은 영업활동으로 회사가 손해를 입은 경우, 회원은 회사에 대해 손해배상의무를 지며, 회사는 해당 회원에 대해 서비스 이용제한 및 적법한 절차를 거쳐 손해배상 등을 청구할 수 있습니다.<br/>
                    4.	회원은 회사의 명시적 동의가 없는 한 서비스의 이용권한, 기타 이용계약상의 지위를 타인에게 양도, 증여할 수 없으며 이를 담보로 제공할 수 없습니다.<br/>
                    5.	회원은 회사 및 기타 제3자의 지적 재산권을 포함한 제반 권리를 침해하는 행위를 해서는 안됩니다.<br/><br/>
                    <span className="fm-c fs15">제 9 조 (게시물에 대한 저작권)</span><br/>
                    1.	회원이 서비스 내에 게시한 게시물은 해당 게시물의 저작자에게 귀속되며, 회사와의 제휴계약에 따라 제공되는 저작물의 저작권은 해당 저작물의 저작자에게 귀속됩니다.<br/>
                    2.	회원이 서비스 내에 게시물을 게시하는 경우, 해당 게시물은 일부 수정, 복제, 편집되어 회사의 서비스에 노출될 수 있습니다.<br/>
                    3.	회원은 회사가 서비스를 운영, 개선, 홍보, 새로운 서비스를 개발하기 위한 범위 내에서 게시물을 사용, 저장, 수정, 복제, 공중송신, 전시, 배포 등의 방식으로 이용할 수 있도록 사용을 허락하는 전 세계적이고 영구적인 라이선스를 회사에 제공합니다. 해당 라이선스는 회원이 서비스 사용을 중단하거나 서비스를 탈퇴한 이후에도 존속합니다.<br/>
                    4.	회원은 언제든지 서비스 내 관리기능을 통해 작성한 게시물에 대한 삭제 등의 조치를 취할 수 있습니다.회원은 자신의 게시물이 회원 탈퇴 후 서비스에서 이용되는 것을 원하지 않는 경우 회원 탈퇴 전에 직접 게시물을 삭제해야 합니다.<br/>
                    5.	회사는 제 2항의 이외의 목적과 방법으로 회원의 게시물을 이용하고자 하는 경우에는 전화, 전자우편 등을 통해 사전에 회원의 동의를 득합니다.<br/>
                    6.	회사는 회사의 운영정책상의 사유로 원래의 게시물의 내용을 변경하지 않고 게시물의 게시 위치를 변경할 수 있습니다.<br/>
                    7.	회원이 타 회원, 제휴업체의 저작물을 사용하고자 하는 경우, 회원이 직접 해당 저작물의 저작자로부터 별도의 허락을 득해야하며, 만일 회원이 해당 저작물의 저작권을 침해하여 발생하는 문제는 회원이 일체의 책임을 부담합니다.<br/><br/>
                    <span className="fm-c fs15">제 10 조 (게시물의 관리)</span><br/>
                    1.	회사는 회원이 게시하거나 전달하는 서비스 내의 모든 게시물이 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 ‘정보통신망법’)및 저작권법 등 관련 법령에 위반하는 내용을 포함하는 경우 권리자는 관계 법령이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며, 회사는 관계 법령에 따라 조치를 취하여야 합니다.<br/>
                    2.	회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관계 법령에 위반되는 경우에는 통지 없이 삭제할 수 있습니다.<br/>
                    3.	다만, 회사는 서비스에 게시되는 모든 게시물을 검토할 의무는 지지 않습니다.<br/>
                    4.	회원이 서비스에 등록하는 게시물 등으로 인하여 본인 또는 타인에게 손해나 기타 문제가 발생하는 경우 회원은 이에 대한 일체의 책임을 부담합니다.<br/>
                    5.	회원의 게시물 등으로 인한 법률상 이익 침해를 근거로, 다른 회원 또는 제3자가 회원 또는 회사를 대상으로 하여 민형사상의 법적 조치를 취할 경우, 회사는 법원의 확정판결이 있을 때 까지 관련 게시물 등에 대한 접근을 잠정적으로 제한할 수 있습니다. 게시물 등의 접근 제한과 관련한 법적 조치의 소명, 법원의 확정 판결에 대한 소명 책임은 게시물 등에 대한 조치를 요청하는 자가 부담합니다.<br/><br/>
                    <span className="fm-c fs15">제 11 조 (이용제한 등)</span><br/>
                    1.	회사는 회원이 이 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우, 경고, 일시정지, 영구이용정지 등으로 서비스 이용을 단계적으로 제한할 수 있습니다.<br/>
                    2.	회사는 전항에도 불구하고, 저작권법 및 컴퓨터프로그램보호법을 위반한 불법프로그램의 제공 및 운영방해, 정보통신망법을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시 영구이용정지를 할 수 있습니다. 본 항에 따른 영구이용정지 시 서비스 이용을 통해 획득한 혜택 등은 모두 소멸되며, 회사는 이에 대해 별도로 보상하지 않습니다.<br/>
                    3.	회사는 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은 서비스의 커뮤니티 운영 규칙에서 정하는 바에 의합니다.<br/>
                    4.	본 조에 따라 서비스 이용을 제한하거나 계약을 해지하는 경우에는 회사는 서비스 영역 내에서 통지합니다.<br/>
                    5.	회원은 본 조에 따른 이용제한 등에 대해 회사가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 회사가 인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.<br/><br/>
                    <span className="fm-c fs15">제 12 조 (정보의 제공 및 광고의 게재)</span><br/>
                    1.	회사는 서비스를 운영함에 있어 각종 정보를 서비스화면에 게재하거나 SMS, 전자우편, 어플리케이션 Push 알림 등의 방법으로 이용자에게 제공할 수 있습니다.<br/>
                    2.	회사는 관계 법령에 따라 적법한 절차를 통해 회원에게 광고를 제공할 수 있습니다.<br/>
                    3.	이용자가 서비스상에 게재되어 있는 광고를 이용하거나 서비스를 통한 광고주의 판촉활동에 참여하는 등의 방법으로 교신 또는 거래를 하는 것은 전적으로 이용자와 광고주 간의 문제입니다. 만약 이용자와 광고주간에 문제가 발생할 경우에도 이용자와 광고주가 직접 해결하여야 하며, 이와 관련하여 회사는 어떠한 책임도 부담하지 않습니다.<br/><br/>
                    <span className="fm-c fs15">제 13 조 (계약의 해지)</span><br/>
                    1.	회원이 언제든지 회사가 정하는 절차에 따라 이용계약 해지 신청을 할 수 있으며, 회사는 관계 법령 등이 정하는 바에 따라 이를 즉시 처리합니다.<br/>
                    2.	회원이 이용계약을 해지할 경우, 해지 즉시 회원의 개인 정보 및 서비스 이용 기록은 소멸됩니다. 단, 공용게시판에 등록된 게시물은 탈퇴 후에도 삭제되지 않고 유지되니 사전에 삭제 후 탈퇴하시기 바랍니다.<br/>
                    3.	회원이 제 8조[회원의 의무]의 규정을 위반한 경우 회사는 사전통보 없이 일방적으로 본 계약을 해지할 수 있고, 이로 인하여 서비스 운영에 손해가 발생한 경우 이에 대한 민형사상 책임을 물을 수 있습니다.<br/><br/>
                    <span className="fm-c fs15">제 14 조 (서비스의 제공 및 변경)</span><br/>
                    1.	회사는 게시판 서비스, 온라인 콘텐츠 제공 서비스, 다른 회사와의 제휴계약 등을 통해 회원에게 제공하는 일체의 서비스 등 회원이 인터넷과 모바일로 이용할 수 있는 다양한 서비스를 제공합니다.<br/>
                    2.	서비스 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간 운영을 원칙으로 합니다. 단, 회사 정책 및 관계 법령에 따라 회원 별로 서비스 이용의 범위, 방법, 내용, 제공시간, 횟수 등이 제한될 수 있습니다.<br/>
                    3.	회사는 시스템 정기점검, 증설 및 교체를 위해 회사가 정한 날이나 시간에 서비스를 일시 중단할 수 있으며, 예정되어 있는 작업으로 인한 서비스 일시 중단은 서비스 내 공지사항 등을 통해 사전에 공지합니다.<br/>
                    4.	회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 사전 예고 없이 일시적으로 서비스의 전부 또는 일부를 중단할 수 있습니다. 이 경우 회사는 제15조[회원에 대한 통지]에 정한 방법으로 회원에게 통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.<br/>
                    5.	서비스의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경사유, 변경될 서비스의 내용 및 제공일자 등은 그 변경 전에 해당 서비스 초기화면에 게시하여야 합니다.<br/>
                    6.	회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 서비스를 변경할 수 있습니다.<br/>
                    7.	회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지 않습니다.<br/><br/>
                    <span className="fm-c fs15">제 15 조 (회원에 대한 통지)</span><br/>
                    1.	회사가 회원에 대하여 통지를 하는 경우 회원이 서비스에 등록한 전자우편 주소로 할 수 있습니다.<br/>
                    2.	회사는 불특정다수 회원에게 통지를 해야 할 경우 회사의 공지 또는 서비스의 공지사항에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.<br/><br/>
                    <span className="fm-c fs15">제 16 조 (책임제한)</span><br/>
                    1.	회사는 천재지변, 전쟁, 기간통신사업자의 서비스 중지 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다.<br/>
                    2.	회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대한 책임이 면제됩니다.<br/>
                    3.	회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여는 책임을 지지 않습니다.<br/>
                    4.	회사는 회원이 서비스를 이용하여 기대하는 수익을 얻지 못하거나 상실한 것에 대하여 책임을 지지 않으며, 서비스를 이용하면서 얻은 자료로 인한 손해에 대하여 책임을 지지 않습니다.<br/>
                    5.	회사는 회원이 서비스에 관련하여 게재한 정보, 자료, 사실의 신뢰도 정확성 등의 내용에 관하여는 책임을 지지 않습니다.<br/>
                    6.	회사는 회원 간 또는 회원과 제3자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.<br/>
                    7.	회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.<br/><br/>
                    <span className="fm-c fs15">제 17 조 (준거법 및 재판관할)</span><br/>
                    1.	서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생한 경우, 회사와 회원은 분쟁의 해결을 위해 성실히 협의합니다.<br/>
                    2.	제1항의 협의에서도 분쟁이 해결되지 않을 경우 양 당사자는 대한민국 서울중앙지방법원을 관할 법원으로 합니다.<br/>
                </span>
            </div>
        </div>
    )
}
export default TermUse;