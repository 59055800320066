import { atom } from "recoil";

export const redirectUrl = atom<string>({ key: 'redirectUrl', default: "" });
export const hshldSelModalOpen = atom<boolean>({ key: 'hshldSelModalOpen', default: false });
export const isAdmin = atom<boolean>({key: 'isAdmin', default: false});

interface userProps {
    userSn: number
    userId: string
    userNm: string
    nickName: string
    emailAddress: string
    phoneNum: string
    isAdmin: boolean
}
export const userInfo = atom<userProps>({key: 'userInfo', default: {} as userProps });
export const currFormMode = atom<string | null>({ key: 'currFormMode', default: "" });