import React from 'react';
// import { Link } from 'react-router-dom';
import JSEncrypt from 'jsencrypt'
import Util from 'js/common/Util';
import Axios from 'js/common/Axios';
import AppContext from 'js/common/AppContext';
import { useSetRecoilState } from "recoil";
import * as Atom from "js/common/Atom";
import CheckBox from 'js/common/ui/input/CheckBox';
import { useNavigate } from 'react-router-dom';

function Login() {
    const navigate = useNavigate();
    const setIsAdmin = useSetRecoilState(Atom.isAdmin);
    const setUserInfo = useSetRecoilState(Atom.userInfo);
    const [userId, setUserId] = React.useState<string>('');
    const [userPw, setUserPw] = React.useState<string>('');
    const [saveUserId, setSaveUserId] = React.useState<boolean>(false);

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.code === "Enter") {
            onClickLogin();
        }
    }
    const handleInputId = (e: { target: { value: React.SetStateAction<string> } }) => {
        setUserId(e.target.value);
    }

    const handleInputPw = (e: { target: { value: React.SetStateAction<string> } }) => {
        setUserPw(e.target.value);
    }

    // login 버튼 클릭 이벤트
    const onClickLogin = () => {
        if (Util.isEmpty(userId)) {
            AppContext.openAlert({
                title: "아이디 확인",
                msg: "아이디를 입력해주세요.",
                icon: "warning"
            });
            return;
        }
        if (Util.isEmpty(userPw)) {
            AppContext.openAlert({
                title: "비밀번호 확인",
                msg: "비밀번호를 입력해주세요.",
                icon: "warning"
            });
            return;
        }
        AppContext.showSpinnerDim();
        Axios.dataAccess({
            url: "portal/auth/genRsaKeyByUserId.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                userId: userId
            },
            onSuccessFn: (res1: any) => {
                if (res1.item.loginRslt !== "O") {
                    AppContext.hideSpinner();
                    AppContext.openAlert({
                        title: "로그인 실패",
                        msg: res1.item.blockMsg,
                        icon: "warning"
                    });
                    return;
                }
                const pubKey = res1.item.pubKeyStr;
                const encrypt = new JSEncrypt();
                encrypt.setPublicKey(pubKey);
                const encUserPw = encrypt.encrypt(userPw);
                Axios.dataAccess({
                    url: "portal/auth/login.do",
                    methodType: "post",
                    paramType: "json",
                    paramData: {
                        userId: userId,
                        encUserPw: encUserPw
                    },
                    onSuccessFn: (res2: any) => {
                        if (res2.item.loginRslt !== "O") {
                            AppContext.hideSpinner();
                            AppContext.openAlert({
                                title: "로그인 실패",
                                msg: res2.item.blockMsg,
                                icon: "warning"
                            });
                            return;
                        }
                        setUserInfo(res2.item.userInfo);
                        const tokenCrtDt = Util.getSpecificDate("-", "day", 0, "SS", "");
                        if (tokenCrtDt !== undefined) {
                            localStorage.setItem("tokenCrtDt", tokenCrtDt);
                        }
                        localStorage.setItem("grantType", res2.item.grantType);
                        localStorage.setItem("accessToken", res2.item.accessToken);
                        localStorage.setItem("refreshToken", res2.item.refreshToken);
                        localStorage.setItem('isAdmin', res2.item.userInfo.isAdmin ? "1" : "0");
                        setIsAdmin(res2.item.userInfo.isAdmin);
                        if (saveUserId) {
                            localStorage.setItem("loginUserId", userId);
                        } else {
                            localStorage.removeItem("loginUserId");
                        }
                        if (res2.item.userInfo.isAdmin) {
                            navigate('/admin/user');
                        }
                        AppContext.hideSpinner();
                    }
                });
            }
        });
    }

    React.useEffect(() => {
        const loginUserId = localStorage.getItem("loginUserId");
        if (loginUserId === null) {
            setSaveUserId(false);
            return;
        }
        setSaveUserId(true);
        setUserId(loginUserId);
    }, []);

    return (
        <div className='dpFlx flxdCol jcC aiC'>
            <div className="dpFlx jcC aiC h150 mb50">
                <span className="fm-c fc222222 fs40 ls12">로그인</span>
            </div>
            <div className='w400 dpFlx flxdCol p40 bdrd3 bd1 bdcDFDFDF bgcFAFAFA'>
                <div className='posR pr100'>
                    <input type="text" onChange={handleInputId} value={userId} placeholder='아이디' className='w90p h39 prl13 mb8 fs18 bdrd5 bd1 ls08 bdcDFDFDF focusBorder NanumSquareNeo-bRg'/>
                    <input type="password" onChange={handleInputPw} value={userPw} onKeyDown={handleKeyDown} placeholder='비밀번호' className='w90p h39 prl13 fs18 ls08 bdrd5 bd1 bdcDFDFDF focusBorder NanumSquareNeo-bRg' />
                    <button type="button" onClick={onClickLogin} className='posA w90 h90 fcWhite fs20 t0 r0 bd0 bdrd5 ls2 lh26 fs20 NanumSquareNeo-bRg bgc1E413A csP'>로그인</button>
                </div>
                <div className='mt5'>
                    <CheckBox label="아이디 저장" checked={saveUserId} onChange={(e: any) => {
                        setSaveUserId(e.target.checked);
                    }} />
                </div>
                {/* <div className='mt6 pt24 h95 borderBox bdt1 bdcDFDFDF'>
                    <p className='m0 p0 posR mb6'><span className='point pl10 dpFlx ls08 jcSb fc505050 lh32 fs14'>싸판 계정이 없으신가요?<Link to="/portal/login" className='w86 lh30 taC bdrd4 fc505050 bd1 bdcCECECE tdN bgcWhite-i'>가입</Link></span></p>
                    <p className='m0 p0 posR'><span className='point pl10 dpFlx ls08 jcSb fc505050 lh32 fs14'>계정을 잊으셨나요?<Link to="/portal/login" className='w86 lh30 taC bdrd4 fc505050 bd1 bdcDFDFDF tdN bgcWhite-i'>찾기</Link></span></p>
                </div> */}
            </div>
        </div>
    )
}
export default Login