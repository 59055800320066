const CustomStyle = {
    // alert창
    alert: {
        whiteSpace: "break-spaces",
        ".MuiDialog-root": {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
        },
        ".MuiPaper-root": {
            minWidth: "200px",
            maxWidth: "100%",
            width: "auto",
        },
        ".MuiTypography-root": {
            position: "relative",
            boxSizing: "border-box",
            padding: "0",
            height: "45px",
            backgroundColor: "#1E413A",
            fontFamily: "fm-a",
            fontWeight: "700",
            fontSize: "15px",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        },
        ".MuiDialogContent-root": {
            padding: "0"
        },
        ".MuiDialogActions-root>:not(:first-of-type)": {
            marginLeft: "3px"
        }
    },
    // 리스트모듈 start
    paper: { borderRadius: 0, boxShadow: 0 },
    // container: { height: "300px" },
    tableBorder: { borderTop: 1, borderColor: "#009696" },
    tableHeadRow: {
        backgroundColor: "#F9F9F9", height: "40px",
        ".MuiTableCell-root": { borderBottom: 0 }
    },
    tableHeadCell: {
        padding: "0px", paddingLeft: "10px", paddingRight: "10px", fontSize: 13, fontFamily: "NanumSquareNeo-cBd", borderTop: "1px solid #cecece", letterSpacing: "-1.2px",
        ".MuiButtonBase-root.Mui-active .MuiSvgIcon-root": { display: "inline-block" },
        ".MuiButtonBase-root:hover .MuiSvgIcon-root": { display: "inline-block" },
        ".MuiButtonBase-root .MuiSvgIcon-root": { display: "none" }
    },
    tableHeadChkBoxCell: {
        padding: "0px", 
        borderTop: "1px solid #cecece",
        width: "42px",
        ".MuiButtonBase-root .MuiSvgIcon-root": { width: "23px", color: "#005E5E" }
    },
    tableHeadSortLabel: {
        ".MuiTableSortLabel-icon": { fontSize: 16, marginLeft: 0 }
    },
    tableBody: {
        ".MuiTableRow-root.Mui-selected": {backgroundColor: '#DEEDE4'}
    },
    tableBodyRowHover: {
        "&:hover": {
            backgroundColor: '#DEEDE4',
            cursor: "pointer"
        },
    },
    tableBodyCell: { paddingTop: "0px", paddingBottom: "0px", paddingLeft: "10px", paddingRight: "10px", fontSize: 13, fontFamily: "NanumSquareNeo-bRg", letterSpacing: "-1.2px", textOverflow: "ellipsis" },
    tablePagination: {
        ".MuiTablePagination-selectLabel": { fontSize: 13, fontFamily: "NanumSquareNeo-cBd !important" },
        ".MuiInputBase-colorPrimary": { fontSize: 13, fontFamily: "NanumSquareNeo-cBd !important" },
        ".MuiTablePagination-displayedRows": { fontSize: 13, fontFamily: "NanumSquareNeo-cBd !important" }
    },
    // 리스트모듈 end

    // 라디오버튼
    radioEl: {
        ".MuiFormControlLabel-root": { marginLeft: 0 },
        ".MuiTypography-root": { fontSize: 13, fontFamily: "NanumSquareNeo-bRg", letterSpacing: "-1.1px", display: "flex", alignItems: "center", lineHeight: "revert", paddingLeft: "5px", minWidth: "40px" },
        ".MuiButtonBase-root": { padding: "0px" }
    },
    formDatePicker: {
        paddingTop: 0,
        overflow: "inherit",
        width: "185px", height: "35px", fontSize: "15px",
        '.MuiOutlinedInput-root': { fontFamily: "NanumSquareNeo-bRg", borderRadius: "0", width: "100%" },
        '.MuiOutlinedInput-root:focus': { borderColor: "#96c832" },
        '.MuiTextField-root': { width: "100%", minWidth: "0 !important" },
        '.MuiFormLabel-root': { fontSize: 12, fontFamily: "NanumSquareNeo-bRg" },
        '.MuiInputBase-root': { lineHeight: 0, letterSpacing: 0, height: 35, paddingRight: 0 },
        '.MuiInputBase-input': {
            fontSize: "14px",
            padding: 0,
            paddingLeft: "10px",
            border: "1px solid #cecece",
            height: "100%",
            boxSizing: "border-box",
            animation: "none"
        },
        '.MuiInputBase-input:focus, .MuiInputBase-input:hover': { borderColor: "#96c832" },
        '.MuiOutlinedInput-notchedOutline': { borderWidth: "1 !important", padding: 0, display: "none" },
        '.MuiInputAdornment-root': { position: "absolute", right: 15 },
        '.MuiIconButton-root': { padding: 0 }
    },
    // 체크박스
    checkbox: {
        color: "#cccccc", padding: 0,
        '&.Mui-checked': { color: "#1E413A" },
        ".MuiButtonBase-root": { padding: 0 }
    }, 
    checkboxLabel: { fontFamily: 'NanumSquareNeo-bRg', userSelect: 'none', letterSpacing: '-0.5px', fontSize: 13, lineHeight: 0, paddingLeft: "3px" }
};
export default CustomStyle;