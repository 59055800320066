import IconButton from "js/common/ui/button/IconButton";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from "recoil";
import * as Atom from "js/common/Atom";
import Util from "js/common/Util";

function Mypage() {
    const navigate = useNavigate();
    const userInfo = useRecoilValue(Atom.userInfo);
    // 마이페이지 수정버튼 클릭
    const editBtnClickFunc = () => {
        navigate("/system/mypage/mod");
    };

    return (
        <div className="dpFlx flxdCol jcC">
            <h2 className="w100p m0 pt75 pb35 taC fs36 fm-c ls1">마이페이지</h2>
            <div className="w1200 mh360 m0auto">
                <div className="bd1 bdcDFDFDF pt50 pb50 pr80 pl80">
                    <div className="dpFlx jcSb aiC fs20 pb20 mb20 bdb1 bdcDFDFDF">
                        <div className="dpFlx aiC">
                            <AccountCircleIcon className="w35-i h35-i fc009696" />
                            <span className="fc009696 ml5 mr3 fm-c ls08">{userInfo.userNm}</span>
                            <span className="fc333333 fm-b ls08">님, 안녕하세요.</span>
                        </div>
                        <IconButton btnName="수정" iconPos="right" iconNm="check" onClick={editBtnClickFunc} className="w100 h30 bdcBlack fs14 fcBlack bgcWhite"/>
                    </div>
                    <table className="w100p h150 fs14">
                        <colgroup>
                            <col className="w135"/>
                            <col className="w200"/>
                            <col className="w135"/>
                            <col className="w200"/>
                            <col className="w135"/>
                            <col className="w200"/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <td className="taL">
                                    <i className="w4 h4 dpInBlock mr10 bgc96c832 bdrd50p vtaM"></i>
                                    <span className="fm-b ls08">이메일주소</span>
                                </td>
                                <td>
                                    <span className="fm-b ls08">{userInfo.emailAddress}</span>
                                </td>
                                <td className="taL">
                                    <i className="w4 h4 dpInBlock mr10 bgc96c832 bdrd50p vtaM"></i>
                                    <span className="fm-b ls08">라이선스</span>
                                </td>
                                <td>-</td>
                                <td className="taL">
                                    <i className="w4 h4 dpInBlock mr10 bgc96c832 bdrd50p vtaM"></i>
                                    <span className="fm-b ls08">상호</span>
                                </td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td className="taL">
                                    <i className="w4 h4 dpInBlock mr10 bgc96c832 bdrd50p vtaM"></i>
                                    <span className="fm-b ls08">성명</span>
                                </td>
                                <td><span className="fm-b ls08">{userInfo.userNm}</span></td>
                                <td className="taL">
                                    <i className="w4 h4 dpInBlock mr10 bgc96c832 bdrd50p vtaM"></i>
                                    <span className="fm-b ls08">이용기간</span>
                                </td>
                                <td>-</td>
                                <td className="taL">
                                    <i className="w4 h4 dpInBlock mr10 bgc96c832 bdrd50p vtaM"></i>
                                    <span className="fm-b ls08">소재지</span>
                                </td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td className="taL">
                                    <i className="w4 h4 dpInBlock mr10 bgc96c832 bdrd50p vtaM"></i>
                                    <span className="fm-b ls08">전화번호</span>
                                </td>
                                <td><span className="fm-b ls08">{Util.cvtPhoneStrForm(userInfo.phoneNum, "-")}</span></td>
                                <td className="taL">
                                    <i className="w4 h4 dpInBlock mr10 bgc96c832 bdrd50p vtaM"></i>
                                    <span className="fm-b ls08">결제금액</span>
                                </td>
                                <td>-</td>
                                <td className="taL">
                                    <i className="w4 h4 dpInBlock mr10 bgc96c832 bdrd50p vtaM"></i>
                                    <span className="fm-b ls08">사업자등록번호</span>
                                </td>
                                <td>-</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
  }
  export default Mypage