import Draggable from "react-draggable";
import ModalPortal from "./ModalPortal";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Util from "js/common/Util";

function Modal({modalOpen, setModalOpen, title, dim, children, closeCallBack}: any) {
    const modalId = "modal_" + Util.getSpecificDate("", "day", 0, "SS", "");

    if (modalOpen) {
        return (
            <ModalPortal>
                <div className={'posF t0 zIdx1 dpFlx w100p h100p jcC aiC ' + (dim ? 'bgcDim' : '')}>
                    <Draggable handle={"#" + modalId} bounds="body">
                        <div className='bgcWhite bdrd3'>
                            <div className="dpFlx aiC jcSb pl15 pr15 bgc1E413A">
                                <div id={modalId} className='w100p h40 dpFlx aiC jcSb csP bdrdtl3 bdrdtr3'>
                                    <span className="fcWhite ls12">{title}</span>
                                </div>
                                <CloseOutlinedIcon className="fcWhite csP" onClick={() => {
                                    setModalOpen(false);
                                    if (Util.isNotEmpty(closeCallBack)) {
                                        closeCallBack();
                                    }
                                }} />
                            </div>
                            {children}
                        </div>
                    </Draggable>
                </div>
            </ModalPortal>
        )
    } else {
        return (
            <></>
        )
    }
}
export default Modal;