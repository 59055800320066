import React from 'react';
import { useNavigate } from "react-router-dom";
import Util from "js/common/Util";
import AppContext from "js/common/AppContext";
import * as Atom from "js/common/Atom";
import { useRecoilValue } from "recoil";

function NavBar() {
    const isAdmin = useRecoilValue(Atom.isAdmin);
    const navigate = useNavigate();
    const goMenuPage = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!(event.target instanceof HTMLDivElement)) {
            return;
        }
        const url = event.target.dataset["url"];
        if (typeof url === 'string') {
            const tokenCrtDt = localStorage.getItem("tokenCrtDt");
            if (url.startsWith("/system", 0)) { // 사용자 인증 후 접근 가능
                if (Util.isEmpty(tokenCrtDt)) {
                    AppContext.openAlert({
                        title: "알림",
                        msg: "로그인 후 이용 가능합니다.",
                        icon: "warning"
                    });
                    return;
                }
            } else if (url.startsWith("/admin", 0)) { // 관리자 인증 후 접근 가능
                if (Util.isEmpty(tokenCrtDt)) {
                    AppContext.openAlert({
                        title: "알림",
                        msg: "로그인 후 이용 가능합니다.",
                        icon: "warning"
                    });
                    return;
                }
                if (!isAdmin) {
                    AppContext.openAlert({
                        title: "알림",
                        msg: "관리자만 이용 가능합니다.",
                        icon: "warning"
                    });
                    return;
                }
            }
            navigate(url);
        }
    }
    return (
        <div className="gnb">
            <div className='nav-bar'>
                {AppContext.menuList.map((menuInfo: any, idx: number) => {
                    if (menuInfo.role === "admin" && !isAdmin) { // 관리자권한 체크
                        return <React.Fragment key={idx}></React.Fragment>
                    }
                    return (
                        <div key={idx} className="nav-item" data-url={menuInfo.url} onClick={goMenuPage}>{menuInfo.name}</div>
                    )
                })}
            </div>
            <div className="dropdown-content">
                <div className="submenu-area">
                    {AppContext.menuList.map((menuInfo: any, idx: number) => {
                        if (menuInfo.role === "admin" && !isAdmin) { // 관리자권한 체크
                            return <React.Fragment key={idx}></React.Fragment>
                        }
                        return (
                            <div key={idx} className="submenu-group">
                                {Util.isNotEmpty(menuInfo.subMenuList) ? 
                                    menuInfo.subMenuList.map((subInfo: any, subIdx: number) => {
                                        return (
                                            <div key={subIdx} className={"submenu-box" + (Util.isEmpty(subInfo.url) ? " off" : " on")} data-url={subInfo.url} onClick={goMenuPage}>{subInfo.name}</div>
                                        )
                                    }) 
                                : <></>}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
export default NavBar