import AppContext from "js/common/AppContext";
import Axios from "js/common/Axios";
import Util from "js/common/Util";
import IconButton from "js/common/ui/button/IconButton";
import SelectBox from "js/common/ui/input/SelectBox";
import TextBox from "js/common/ui/input/TextBox";
import React from "react";

interface UserDialogProps {
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    userSn?: number | null
    setUserSelTime: React.Dispatch<React.SetStateAction<Date>>
}
const UserDialog = ({setModalOpen, userSn, setUserSelTime}: UserDialogProps) => {
    const [userId, setUserId] = React.useState<string>();
    const [phoneNum, setPhoneNum] = React.useState<string>();
    const [nickName, setNickName] = React.useState<string>();
    const [userNm, setUserNm] = React.useState<string>();
    const [isAdmin, setIsAdmin] = React.useState<string>();
    const [userSttCode, setUserSttCode] = React.useState<string>("");

    const updUserInfo = () => {
        let fgNm = "";
        let url = "";
        if (Util.isEmpty(userSn)) {
            fgNm = "등록";
            url = "insUserInfo.do";
        } else {
            fgNm = "저장";
            url = "updUserInfo.do";
        }
        AppContext.openAlert({
            title: "회원정보 " + fgNm,
            msg: "회원정보를 " + fgNm + "하시겠습니까?",
            icon: "check",
            confirmText: "저장",
            handleConfirm: () => {
                AppContext.showSpinner();
                Axios.dataAccess({
                    url: "admin/user/" + url,
                    methodType: "post",
                    paramType: "json",
                    paramData: {
                        userSn: userSn,
                        userId: userId,
                        phoneNum: phoneNum,
                        nickName: nickName,
                        userNm: userNm,
                        isAdmin: Number(isAdmin)
                    },
                    onSuccessFn: () => {
                        setModalOpen(false);
                        setUserSelTime(new Date());
                        AppContext.hideSpinner();
                        AppContext.openAlert({
                            title: "회원정보 저장완료",
                            msg: "회원정보가 저장되었습니다.",
                            icon: "check"
                        });
                        return;
                    }
                });
            }
        });
    }

    const resetUserPassword = () => {
        AppContext.openAlert({
            title: "비밀번호 초기화",
            msg: "회원의 비밀번호를 초기화하시겠습니까?",
            icon: "check",
            confirmText: "초기화",
            handleConfirm: () => {
                AppContext.showSpinner();
                Axios.dataAccess({
                    url: "admin/user/resetUserPassword.do",
                    methodType: "post",
                    paramData: {
                        userSn: userSn
                    },
                    onSuccessFn: () => {
                        setModalOpen(false);
                        setUserSelTime(new Date());
                        AppContext.hideSpinner();
                        AppContext.openAlert({
                            title: "비밀번호 초기화 완료",
                            msg: "회원의 비밀번호가 초기화 되었습니다.",
                            icon: "check"
                        });
                        return;
                    }
                });
            }
        });
    }

    const pmsUserInfo = () => {
        AppContext.openAlert({
            title: "회원 사용승인",
            msg: userNm + " 회원을 사용승인 하시겠습니까?",
            icon: "check",
            confirmText: "사용승인",
            handleConfirm: () => {
                AppContext.showSpinner();
                Axios.dataAccess({
                    url: "admin/user/pmsUserInfo.do",
                    methodType: "post",
                    paramData: {
                        userSn: userSn
                    },
                    onSuccessFn: () => {
                        setModalOpen(false);
                        setUserSelTime(new Date());
                        AppContext.hideSpinner();
                        AppContext.openAlert({
                            title: "사용승인 완료",
                            msg: userNm + " 회원이 사용승인 되었습니다.",
                            icon: "check"
                        });
                        return;
                    }
                });
            }
        });
    }

    const banUserInfo = () => {
        AppContext.openAlert({
            title: "회원 사용정지",
            msg: userNm + " 회원을 사용정지 하시겠습니까?",
            icon: "check",
            confirmText: "사용정지",
            handleConfirm: () => {
                AppContext.showSpinner();
                Axios.dataAccess({
                    url: "admin/user/banUserInfo.do",
                    methodType: "post",
                    paramData: {
                        userSn: userSn
                    },
                    onSuccessFn: () => {
                        setModalOpen(false);
                        setUserSelTime(new Date());
                        AppContext.hideSpinner();
                        AppContext.openAlert({
                            title: "사용정지 완료",
                            msg: userNm + " 회원이 사용정지 처리되었습니다.",
                            icon: "check"
                        });
                        return;
                    }
                });
            }
        });
    }

    React.useEffect(() => {
        if (Util.isEmpty(userSn)) {
            setUserId("");
            setPhoneNum("");
            setNickName("");
            setUserNm("");
            setIsAdmin("");
            return;
        }
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "admin/user/selUserInfo.do",
            methodType: "post",
            paramData: {
                userSn: userSn
            },
            onSuccessFn: (res: any) => {
                setUserId(res.item.userId);
                setPhoneNum(res.item.phoneNum);
                setNickName(res.item.nickName);
                setUserNm(res.item.userNm);
                setIsAdmin(res.item.isAdmin ? "1" : "0");
                setUserSttCode(res.item.userSttCode);
                AppContext.hideSpinner();
            }
        });
    }, [userSn]);

    return (
        <div className='w400 p10'>
            <table className="form-table tr-h43">
                <colgroup>
                    <col className="w40p" />
                    <col className="w60p" />
                </colgroup>
                <tbody>
                <tr>
                    <th className="taC" title='아이디'>아이디</th>
                    <td colSpan={3}>
                        {Util.isEmpty(userSn) ? 
                            <TextBox
                                value={userId}
                                className="bd1 pw5 bgcWhite"
                                height={36}
                                fontSize={14}
                                fontFamily="fm-b"
                                maxLength={10}
                                placeholder="아이디를 입력하세요."
                                onChangeFunc={(text: string) => {
                                    setUserId(text);
                                }}
                            /> :
                            <span className="fs13 fc222222 fm-b ls06 ml5">{userId}</span>
                        }
                    </td>
                </tr>
                <tr>
                    <th className="taC" title='회원상태'>회원상태</th>
                    <td className="taL"><span className="fs13 fc222222 ml5 ls06">{Util.getValToCode("1009", userSttCode)}</span></td>
                </tr>
                <tr>
                    <th className="taC" title='사용자명'>사용자명</th>
                    <td>
                        <TextBox
                            value={userNm}
                            className="bd1 pw5 bgcWhite"
                            height={36}
                            fontSize={14}
                            fontFamily="fm-b"
                            maxLength={10}
                            placeholder="사용자 이름을 입력하세요."
                            onChangeFunc={(text: string) => {
                                setUserNm(text);
                            }}
                        />
                    </td>
                </tr>
                <tr>
                    <th className="taC" title='닉네임'>닉네임</th>
                    <td>
                        <TextBox
                            value={nickName}
                            className="bd1 pw5 bgcWhite"
                            height={36}
                            fontSize={14}
                            fontFamily="fm-b"
                            maxLength={10}
                            placeholder="닉네임을 입력하세요."
                            onChangeFunc={(text: string) => {
                                setNickName(text);
                            }}
                        />
                    </td>
                </tr>                
                <tr>
                    <th className="taC" title='휴대폰번호'>휴대폰번호</th>
                    <td colSpan={3}>
                        <TextBox
                            type="phone"
                            className="bd1 pw5 bgcWhite"
                            value={Util.setPhoneNum(phoneNum)}
                            height={36}
                            fontSize={14}
                            fontFamily="fm-b"
                            maxLength={13}
                            placeholder="휴대폰 번호를 입력하세요."
                            onChangeFunc={(text: string) => {
                                setPhoneNum(text);
                            }}
                        />
                    </td>
                </tr>
                <tr>
                    <th className="taC" title='사용자유형'>사용자유형</th>
                    <td colSpan={3}>
                        <SelectBox
                            value={isAdmin}
                            codeArray={Util.getCodeArray("1011")}
                            placeholder="관리자여부를 선택해주세요."
                            height={36}
                            fontSize={14}
                            paddingRight={5}
                            onChangeFunc={(data: string) => setIsAdmin(data)}
                            border="1px solid #dddddd"
                        />
                    </td>
                </tr>
                </tbody>
            </table>
            <div className='w100p h40 dpFlx aiC jcSb pt10'>
                <div className="dpFlx aiC">
                {userSttCode === "1001" || userSttCode === "1010" ?
                    <IconButton btnName="사용승인" iconPos="right" iconNm="check" className="h32 bdrd3 fs14 fcWhite bdcGreen bgcGreen mr3" onClick={() => {
                        pmsUserInfo();
                    }} />
                : <></>}
                {userSttCode === "1002" ?
                    <IconButton btnName="사용정지" iconPos="right" iconNm="check" className="h32 bdrd3 fs14 fcWhite bdcB36666 bgcB36666 mr3" onClick={() => {
                        banUserInfo();
                    }} />
                : <></>}
                <IconButton btnName="비밀번호 초기화" iconPos="right" iconNm="check" className="h32 bdrd3 fs14 fcWhite bdN bgcOrange mr3" onClick={() => {
                    resetUserPassword();
                }} />
                </div>
                <IconButton btnName="저장" iconPos="right" iconNm="check" className="w70 h32 bdrd3 fs14 fcWhite bgc1E413A bdN mr3" onClick={() => updUserInfo()} />
            </div>
        </div>
    )
}
export default UserDialog;