import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Util from 'js/common/Util';
import ReactPaginate from 'react-paginate';
import LinesEllipsis from 'react-lines-ellipsis';
import 'css/common/ui/react-paginate.css';
import ToolTip from '../ToolTip';

export type fldInfProps = {
    type?: string
    pk?: boolean
    fldNm?: string
    width?: number
    wAuto?: boolean
    headAlign?: string
    dataAlign?: string
    label?: string
    codeTy?: string
    el?: Function
    dfltVal?: string
    class?: string
    func?: Function
}

type Order = 'asc' | 'desc';
function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (Util.nvl(b[orderBy], "") < Util.nvl(a[orderBy], "")) {
        return -1;
    }
    if (Util.nvl(b[orderBy], "") > Util.nvl(a[orderBy], "")) {
        return 1;
    }
    return 0;
}

const chkSetupInfoValidation = (tableInfo: any) => {
    if (Util.isEmpty(tableInfo)) {
        alert("테이블 설정 정보가 없습니다.");
        return false;
    }
    if (Util.isEmpty(tableInfo["ordFld"])) {
        alert("테이블 리스트를 정렬할 컬럼 정보가 없습니다.");
        return false;
    }
    if (Util.isEmpty(tableInfo["maxRowNums"])) {
        alert("테이블 항목수 배열 정보가 없습니다.");
        return false;
    }
    return true;
};
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
    chkBoxExist: boolean; // 체크박스 배치 여부
    numSelected: number; // 체크한 row count
    rowCount: number; // 전체 row count
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void; // 전체 체크 이벤트
    order: Order;
    orderBy: string;
    onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
    styleType: any;
    headCells: any;
    visible: boolean;
}
function EnhancedTableHead(props: EnhancedTableProps) {
    const { chkBoxExist, numSelected, rowCount, onSelectAllClick, order, orderBy, onRequestSort, styleType, headCells, visible } = props;
    const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead style={{ visibility: (visible ? "visible" : "collapse") }}>
            <TableRow sx={styleType.tableHeadRow}>
                {chkBoxExist
                    ?
                        <TableCell
                            padding="checkbox"
                            sx={[styleType.tableHeadChkBoxCell]}
                        >
                            <Checkbox
                                color="primary"
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={rowCount > 0 && numSelected === rowCount}
                                onChange={onSelectAllClick}
                                inputProps={{
                                    'aria-label': 'select all desserts',
                                }}
                            />
                        </TableCell>
                    : <></>
                }
                {headCells.map((headCell: any, idx: number) => {
                    if (Util.isNotEmpty(headCell.type)) {
                        let tableSortLabel;
                        if (headCell.type === "text" || headCell.type === "code" || headCell.type === "phone" || (headCell.type === "el" && Util.isNotEmpty(headCell.fldNm))) {
                            tableSortLabel =
                                <TableSortLabel
                                    active={orderBy === headCell.fldNm}
                                    direction={orderBy === headCell.fldNm ? order : 'asc'}
                                    onClick={createSortHandler(headCell.fldNm)}
                                    sx={styleType.tableHeadSortLabel}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.fldNm ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                        } else {
                            tableSortLabel = headCell.label;
                        }
                        return (
                            <TableCell
                                key={idx}
                                align={headCell.headAlign}
                                sortDirection={orderBy === headCell.fldNm ? order : false}
                                sx={[styleType.tableHeadCell, { width: headCell.wAuto ? null : headCell.width, minWidth: headCell.wAuto ? headCell.width : null }]}
                            >
                                {tableSortLabel}
                            </TableCell>
                        )
                    } else {
                        return null;
                    }
                })}
            </TableRow>
        </TableHead>
    );
}

export default function EnhancedTable({ tableInfo }: any) {
    const tableKey = Math.random().toString(36).substring(2, 11);
    const validationResult = chkSetupInfoValidation(tableInfo);
    const [order, setOrder] = React.useState<Order>(tableInfo["ordFld"]["direction"]);
    const [orderBy, setOrderBy] = React.useState(tableInfo["ordFld"]["name"]);
    const chkBoxExist = Util.isNotEmpty(tableInfo["checkedHookParam"]); // 체크박스 표출여부 : 체크 핸들러 훅이 파라미터로 존재할 경우에 표출
    let colSpan = chkBoxExist ? 1 : 0; // td colspan, 체크박스 표출 시 + 1
    tableInfo["fldInfs"].forEach((item: any) => {
        if (Util.isNotEmpty(item.type)) {
            colSpan = colSpan + 1;
        }
    });
    if (!Array.isArray(tableInfo["dataArr"])) {
        tableInfo["dataArr"] = [];
    }

    // interface HeadCell {
    //     fldNm: string;
    //     label: string;
    //     headAlign: string;
    //     dataAlign: string;
    // }
    const headCells: readonly fldInfProps[] = tableInfo["fldInfs"];
    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: any,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const [itemsPerPage, setItemsPerPage] = React.useState(tableInfo["maxRowNums"][0]); // 한페이지에 포함시킬 ROW 수
    const [itemOffset, setItemOffset] = React.useState(0); // 페이지 번호
    const [emptyRowCnt, setEmptyRowCnt] = React.useState(0); // 남은 행 갯수
    const pageCount = Math.ceil(tableInfo["dataArr"].length / itemsPerPage); // 페이지 수
    const currentItems = React.useMemo(() => {
        const currItems = stableSort(tableInfo["dataArr"], getComparator(order, orderBy)).slice(
            itemOffset * itemsPerPage,
            itemOffset * itemsPerPage + itemsPerPage,
        );
        setEmptyRowCnt(itemsPerPage - currItems.length)
        return currItems;
    }, [order, orderBy, itemOffset, itemsPerPage, tableInfo]
    ); // 현재 페이지에 표출할 데이터 배열
    const handlePageClick = (event: any) => { // 하단 버튼 이벤트 함수 : 이전/다음, 1/2/3/4/5...
        // const newOffset = (event.selected * itemsPerPage) % tableInfo["dataArr"].length;
        setItemOffset(event.selected);
    };
    const handleRowNumClick = (maxRowNum: number) => {
        setItemsPerPage(maxRowNum);
    };
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = tableInfo["dataArr"].map((n: any) => n);
            tableInfo["checkedHookParam"]["setCheckDataArr"](newSelected);
            return;
        }
        tableInfo["checkedHookParam"]["setCheckDataArr"]([]);
    };
    const handleClick = (row: any) => {
        const selectedIndex = tableInfo["checkedHookParam"]["checkDataArr"].indexOf(row);
        let newSelected: readonly any[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(tableInfo["checkedHookParam"]["checkDataArr"], row);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(tableInfo["checkedHookParam"]["checkDataArr"].slice(1));
        } else if (selectedIndex === tableInfo["checkedHookParam"]["checkDataArr"].length - 1) {
            newSelected = newSelected.concat(tableInfo["checkedHookParam"]["checkDataArr"].slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                tableInfo["checkedHookParam"]["checkDataArr"].slice(0, selectedIndex),
                tableInfo["checkedHookParam"]["checkDataArr"].slice(selectedIndex + 1),
            );
        }
        tableInfo["checkedHookParam"]["setCheckDataArr"](newSelected);
    };
    if (!validationResult) {
        return (
            <></>
        );
    } else {
        return (
            <Box>
                <Paper sx={[tableInfo["styleType"]["paper"], tableInfo["styleType"]["tableBorder"]]}>
                    <div className='w1200 ofxA'>
                        <div className='w1200'>
                            <TableContainer sx={{ display: !tableInfo["headVsb"] ? "none" : "block" }}>
                                <Table aria-labelledby="tableTitle">
                                    <EnhancedTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        styleType={tableInfo["styleType"]}
                                        headCells={headCells}
                                        visible={true}
                                        chkBoxExist={chkBoxExist}
                                        rowCount={tableInfo["dataArr"].length}
                                        numSelected={chkBoxExist ? tableInfo["checkedHookParam"]["checkDataArr"].length : 0}
                                        onSelectAllClick={handleSelectAllClick}
                                    />
                                </Table>
                            </TableContainer>
                            <TableContainer sx={{ height: tableInfo["bodyHeight"] }}>
                                <Table aria-labelledby="tableTitle">
                                    <EnhancedTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        styleType={tableInfo["styleType"]}
                                        headCells={headCells}
                                        visible={false}
                                        chkBoxExist={chkBoxExist}
                                        rowCount={tableInfo["dataArr"].length}
                                        numSelected={chkBoxExist ? tableInfo["checkedHookParam"]["checkDataArr"].length : 0}
                                        onSelectAllClick={handleSelectAllClick}
                                    />
                                    <TableBody sx={tableInfo["styleType"]["tableBody"]}>
                                        {currentItems.map((row: any, index: any) => {
                                            const rendering = () => {
                                                const resEls: any[] = [];
                                                headCells.map((item: fldInfProps, idx: number) => {
                                                    const toolTipId = tableKey + "-" + index + "-" + idx;
                                                    if (Util.isNotEmpty(item.type)) {
                                                        if (item.type === "text") {
                                                            if (item.fldNm === undefined) {
                                                                return null;
                                                            }
                                                            const fldTxt = Util.nvl(row[item.fldNm], Util.nvl(item.dfltVal, ""));
                                                            return resEls.push(
                                                                <TableCell key={index + "-" + idx} align={item.dataAlign as 'inherit' | 'left' | 'center' | 'right' | 'justify'} sx={tableInfo["styleType"]["tableBodyCell"]}>
                                                                    <span
                                                                        className={item.class || ''}
                                                                        onClick={() => {
                                                                            if (item.func === undefined) {
                                                                                return;
                                                                            }
                                                                            item.func(row);
                                                                        }}
                                                                        data-tooltip-id={toolTipId}
                                                                        data-tooltip-content={fldTxt}
                                                                    >
                                                                        <LinesEllipsis text={fldTxt.toString()} maxLine='1' ellipsis='...' trimRight />
                                                                        <ToolTip id={toolTipId} place='top' />
                                                                    </span>
                                                                </TableCell>
                                                            );
                                                        } else if (item.type === "phone") {
                                                            if (item.fldNm === undefined) {
                                                                return null;
                                                            }
                                                            const fldTxt = Util.nvl(Util.cvtPhoneStrForm(row[item.fldNm], "-"), Util.nvl(item.dfltVal, ""));
                                                            return resEls.push(
                                                                <TableCell key={index + "-" + idx} align={item.dataAlign as 'inherit' | 'left' | 'center' | 'right' | 'justify'} sx={tableInfo["styleType"]["tableBodyCell"]}>
                                                                    <span
                                                                        className={item.class}
                                                                        onClick={() => {
                                                                            if (item.func === undefined) {
                                                                                return;
                                                                            }
                                                                            item.func(row)
                                                                        }}
                                                                        data-tooltip-id={toolTipId}
                                                                        data-tooltip-content={fldTxt}
                                                                    >
                                                                        <LinesEllipsis text={fldTxt.toString()} maxLine='1' ellipsis='...' trimRight />
                                                                        <ToolTip id={toolTipId} place='top' />
                                                                    </span>
                                                                </TableCell>
                                                            );
                                                        } else if (item.type === "code") {
                                                            if (item.fldNm === undefined) {
                                                                return null;
                                                            }
                                                            if (item.codeTy === undefined) {
                                                                return null;
                                                            }
                                                            const fldTxt = Util.nvl(Util.isEmpty(row[item.fldNm]) ? item.dfltVal : Util.getValToCode(item.codeTy, row[item.fldNm].toString()), "");
                                                            return resEls.push(
                                                                <TableCell key={index + "-" + idx} align={item.dataAlign as 'inherit' | 'left' | 'center' | 'right' | 'justify'} sx={tableInfo["styleType"]["tableBodyCell"]}>
                                                                    <span
                                                                        className={item.class}
                                                                        onClick={() => {
                                                                            if (item.func === undefined) {
                                                                                return;
                                                                            }
                                                                            item.func(row)
                                                                        }}
                                                                        data-tooltip-id={toolTipId}
                                                                        data-tooltip-content={fldTxt}
                                                                    >
                                                                        <LinesEllipsis text={fldTxt.toString()} maxLine='1' ellipsis='...' trimRight />
                                                                        <ToolTip id={toolTipId} place='top' />
                                                                    </span>
                                                                </TableCell>
                                                            );
                                                        } else if (item.type === "el") {
                                                            if (item.el === undefined) {
                                                                return null;
                                                            }
                                                            return resEls.push(
                                                                <TableCell key={index + "-" + idx} align={'center'} sx={tableInfo["styleType"]["tableBodyCell"]}>
                                                                    <div className='dpFlx jcC'>
                                                                        {item.el(row)}
                                                                    </div>
                                                                </TableCell>
                                                            );
                                                        } else {
                                                            return null;
                                                        }
                                                    } else {
                                                        return null;
                                                    }
                                                });
                                                return resEls;
                                            };
                                            const isItemSelected = chkBoxExist ? tableInfo["checkedHookParam"]["checkDataArr"].indexOf(row) !== -1 : false;
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                <TableRow
                                                    tabIndex={-1}
                                                    key={index}
                                                    sx={[
                                                        { height: Util.isEmpty(tableInfo["trHeight"]) ? "" : tableInfo["trHeight"] + "px" },
                                                        Util.isNotEmpty(tableInfo["rowClickFunc"]) ? tableInfo["styleType"]["tableBodyRowHover"] : {}
                                                    ]}
                                                    onClick={
                                                        () => {
                                                            if (Util.isNotEmpty(tableInfo["rowClickFunc"])) {
                                                                tableInfo["rowClickFunc"](row);
                                                            }
                                                        }
                                                    }
                                                    // role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    selected={isItemSelected}
                                                >
                                                    {chkBoxExist
                                                    ?
                                                        <TableCell padding="checkbox" sx={[tableInfo["styleType"].tableHeadChkBoxCell]}>
                                                            <Checkbox
                                                                color="primary"
                                                                checked={isItemSelected}
                                                                inputProps={{
                                                                    'aria-labelledby': labelId,
                                                                }}
                                                                onChange={() => {handleClick(row)}}
                                                            />
                                                        </TableCell>
                                                    : <></>
                                                    }
                                                    {rendering()}
                                                </TableRow>
                                            );
                                        })}
                                        {(tableInfo["dataArr"].length === 0) && (
                                            <TableRow sx={{ height: Util.isEmpty(tableInfo["trHeight"]) ? "" : tableInfo["trHeight"] + "px" }}>
                                                <TableCell colSpan={colSpan} align='center' sx={tableInfo["styleType"]["tableBodyCell"]}>
                                                    <span>조회할 자료가 없습니다.</span>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        {(emptyRowCnt > 0 && tableInfo["remainArea"]) && (
                                            <TableRow style={{ height: (Util.isEmpty(tableInfo["trHeight"]) ? "" : tableInfo["trHeight"]) * emptyRowCnt }}>
                                                <TableCell colSpan={colSpan} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                    <div className="dpFlx jcSb mt7 h35">
                        <div className='w200'>
                            <span className='fs13 pr3 ls12'>검색결과 :</span>
                            <span className='fs13 fwB ls12 fcCrimson'>{tableInfo["dataArr"].length}</span>
                        </div>
                        {/* pageCount - 총 게시글의 개수(총 row 수)
                        pageRangeDisplayed - 한 페이지에 표시할 게시글의 수
                        marginPagesDisplayed -
                        breakLabel - 페이지 수가 많을 경우 건너뛸 수 있는 버튼
                        previousLabel - 이전페이지로 가는 버튼의 value값
                        nextLabel - 다음페이지로 가는 버튼의 value값
                        onPageChange - 페이지 버튼을 눌렀을 때 일어나는 이벤트 이를 이용해 페이지 증감
                        containerClassName - css적용할 때 사용
                        activeClassName - 현재 페이지에 css처리해주기 위한 클래스명을 적으면 됨
                        previousClassName/NextClassName - 이전/다음버튼 css적용위한 클래스명을 적으면 됨 */}
                        <ReactPaginate
                            breakLabel="..."
                            previousLabel={<NavigateBeforeIcon style={{ fontSize: 35, color: 'grey', paddingRight: 10 }} />}
                            nextLabel={<NavigateNextIcon style={{ fontSize: 35, color: 'grey', paddingLeft: 10 }} />}
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={2}
                            pageCount={pageCount}
                            renderOnZeroPageCount={null}
                            containerClassName='react-paginate'
                        // activeClassName={"currentPage"}
                        // previousClassName={"pageLabel-btn"}
                        // nextClassName={"pageLabel-btn"}
                        />
                        <div className='w200'>
                            <div className='buttons float-r'>
                                {tableInfo["maxRowNums"].length > 1
                                    ?
                                    tableInfo["maxRowNums"].map((maxRowNum: number, index: number) => {
                                        return (
                                            <label key={index} className={'page-chg-btn' + (maxRowNum === itemsPerPage ? ' active' : '')} onClick={() => handleRowNumClick(maxRowNum)}>
                                                <input type='radio' name='options' id={"options" + { index }} value={maxRowNum} />
                                                <span className='pg-cnt ls12'>{maxRowNum}</span>
                                            </label>
                                        )
                                    })
                                    :
                                    <>
                                        {/* <span className='fs13 pr3'>페이지당 조회 건수 :</span>
                                        <span className='fs13 fwB fcCrimson'>{tableInfo["maxRowNums"]}</span> */}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </Paper>
            </Box>
        );
    }
}