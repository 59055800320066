import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

export default function SearchInput({ name, data, setData, onClick, className }: any) {
    return (
        <Paper className={className} sx={{ ".MuiInputBase-input": { padding: 0 }, boxShadow: 0, boxSizing: "border-box", display: 'flex', borderRadius: 0 }}>
            <InputBase
                sx={{ ml: 1, flex: 1, fontFamily: "NanumSquareNeo-cBd", fontSize: 13, letterSpacing: "-1.1px" }}
                placeholder="검색어를 입력해 주세요"
                inputProps={{ 'aria-label': 'search google maps' }}
                name={name}
                value={data || ""}
                onChange={(e) => {
                    setData(e.target.value);
                }}
                onKeyPress={(e) => {
                    if (["Enter", "NumpadEnter"].indexOf(e.code) !== -1) {
                        onClick();
                    }
                }}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={onClick}>
                <SearchIcon />
            </IconButton>
        </Paper>
    );
}