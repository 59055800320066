import { useNavigate, useParams } from "react-router-dom";
import Axios from 'js/common/Axios';
import React from 'react';
import ViewListIcon from '@mui/icons-material/ViewList';
import IconButton from 'js/common/ui/button/IconButton';
import Util from "js/common/Util";
import MuiListFilter from "js/common/ui/list/MuiListFilter";
import CustomStyle from 'js/common/ui/style/CustomStyle';
import AppContext from "js/common/AppContext";
import TextBox from "js/common/ui/input/TextBox";
import TextAreaBox from "js/common/ui/input/TextAreaBox";
import SelectBox from "js/common/ui/input/SelectBox";
import Dropzone from "react-dropzone";
import CloseIcon from '@mui/icons-material/Close';
import Modal from "js/common/ui/modal/Modal";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { NumericFormat } from "react-number-format";

const JuryWrite = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [jurySelTime, setJurySelTime] = React.useState<Date>(new Date());
    const [juryInfo, setJuryInfo] = React.useState<any>({});

    const [reqJurordataArr, setReqJurordataArr] = React.useState<any[]>([]);
    const [defJurordataArr, setDefJurordataArr] = React.useState<any[]>([]);
    const [replydataArr, setReplydataArr] = React.useState<any[]>([]);
    const [testUserList, setTestUserList] = React.useState<any[]>([]);
    const [replyTestUserList, setReplyTestUserList] = React.useState<any[]>([]);

    const [lawLabel, setLawLabel] = React.useState<string>("");
    const [lawOpenCode, setLawOpenCode] = React.useState<string>();
    const [lawTime, setLawTime] = React.useState<string>();
    const [reqUserNm, setReqUserNm] = React.useState<string>("");
    const [defUserNm, setDefUserNm] = React.useState<string>("");
    const [RRatedAt, setRRatedAt] = React.useState<string>();
    const [viewPosCode, setViewPosCode] = React.useState<string>();
    const [lawTitle, setLawTitle] = React.useState<string>();
    const [lawCtt, setLawCtt] = React.useState<string>("");
    const [viewCnt, setViewCnt] = React.useState<string>("0");

    const [reqModalOpen, setReqModalOpen] = React.useState<boolean>(false);
    const [reqJurorUserSn, setReqJurorUserSn] = React.useState<string>();
    const [reqJurorVdctCtt, setReqJurorVdctCtt] = React.useState<string>("");
    const [defModalOpen, setDefModalOpen] = React.useState<boolean>(false);
    const [defJurorUserSn, setDefJurorUserSn] = React.useState<string>();
    const [defJurorVdctCtt, setDefJurorVdctCtt] = React.useState<string>("");
    const [replyModalOpen, setReplyModalOpen] = React.useState<boolean>(false);
    const [replyUserSn, setReplyUserSn] = React.useState<string>();
    const [replyCtt, setReplyCtt] = React.useState<string>("");

    interface uldJuryPicProps {
        picSn: number
        picFileNm: string
        fileExtsnNm: string
        fileSize: number
    }
    const [juryPics, setJuryPics] = React.useState<File[]>([]);
    const [uldJuryPics, setUldJuryPics] = React.useState<uldJuryPicProps[]>([]);

    const insJuryInfo = () => {
        if (Util.isEmpty(lawOpenCode)) {
            AppContext.openAlert({
                title: "공개여부 미선택",
                msg: "공개여부를 선택하세요.",
                icon: "check"
            });
            return;
        }
        if (Util.isEmpty(lawTime)) {
            AppContext.openAlert({
                title: "재판시간 미선택",
                msg: "재판시간을 선택하세요.",
                icon: "check"
            });
            return;
        }
        if (Util.isEmpty(reqUserNm)) {
            AppContext.openAlert({
                title: "원고 미입력",
                msg: "원고를 입력하세요.",
                icon: "check"
            });
            return;
        }
        if (Util.isEmpty(defUserNm)) {
            AppContext.openAlert({
                title: "피고 미입력",
                msg: "피고를 입력하세요.",
                icon: "check"
            });
            return;
        }
        if (Util.isEmpty(RRatedAt)) {
            AppContext.openAlert({
                title: "19금여부 미선택",
                msg: "19금여부를 선택하세요.",
                icon: "check"
            });
            return;
        }
        if (Util.isEmpty(viewPosCode)) {
            AppContext.openAlert({
                title: "사용자조회상태 미선택",
                msg: "사용자조회상태를 선택하세요.",
                icon: "check"
            });
            return;
        }
        if (Util.isEmpty(lawTitle)) {
            AppContext.openAlert({
                title: "재판제목 미입력",
                msg: "재판제목을 입력하세요.",
                icon: "check"
            });
            return;
        }
        if (Util.isEmpty(lawCtt)) {
            AppContext.openAlert({
                title: "내용 미입력",
                msg: "내용을 입력하세요.",
                icon: "check"
            });
            return;
        }
        let url = "";
        let fgNm = "";
        if (Util.isEmpty(params.lawSn)) {
            url = "insJuryInfo.do";
            fgNm = "등록";
        } else {
            url = "updJuryInfo.do";
            fgNm = "수정";
        }
        AppContext.openAlert({
            title: "재판 " + fgNm,
            msg: "재판을 " + fgNm + "하시겠습니까?",
            icon: "check",
            confirmText: fgNm,
            handleConfirm: () => {
                AppContext.showSpinner();
                Axios.dataAccess({
                    url: "admin/jury/" + url,
                    methodType: "post",
                    isMultipart: true,
                    paramData: {
                        lawSn: Number(params.lawSn),
                        lawTime: lawTime,
                        lawOpenCode: lawOpenCode,
                        RRatedAt: RRatedAt,
                        reqUserNm: reqUserNm,
                        defUserNm: defUserNm,
                        lawLabel: lawLabel,
                        lawTitle: lawTitle,
                        lawCtt: lawCtt,
                        viewPosCode: viewPosCode,
                        juryPics: juryPics,
                        viewCnt: viewCnt
                    },
                    onSuccessFn: (res: any) => {
                        AppContext.openAlert({
                            title: "재판정보 저장 완료",
                            msg: "재판 정보가 저장되었습니다.",
                            icon: "check"
                        });
                        navigate("/admin/jury/view/" + res.item + "/juryList/0");
                        AppContext.hideSpinner();
                    }
                });
            }
        });
    }

    React.useEffect(() => {
        let tempLawLabel = "";
        if (lawOpenCode === "LOC001") {
            tempLawLabel = "공";
        } else if (lawOpenCode === "LOC002") {
            tempLawLabel = "비";
        } else {
            return;
        }
        tempLawLabel = tempLawLabel + "-" + reqUserNm.substring(0, 1) + defUserNm.substring(0, 1) + "-" + Util.getSpecificDate("", "day", 0, "YYMMDD", "") + "-*****"
        setLawLabel(tempLawLabel);
    }, [lawOpenCode, reqUserNm, defUserNm]);

    React.useEffect(() => {
        if (Util.isEmpty(params.lawSn)) {
            setLawOpenCode("LOC001");
            setLawTime("1800");
            setRRatedAt("N");
            setViewPosCode("POS001");
            return;
        }
        Axios.dataAccess({
            url: "admin/jury/selJuryInfo.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                lawSn: Number(params.lawSn)
            },
            onSuccessFn: (res: any) => {
                setJuryInfo(res.item.juryInfo);
                setLawOpenCode(res.item.juryInfo.lawOpenCode);
                setLawTime(res.item.juryInfo.lawTime);
                setReqUserNm(res.item.juryInfo.reqUserNm);
                setDefUserNm(res.item.juryInfo.defUserNm);
                setLawTitle(res.item.juryInfo.lawTitle);
                setLawCtt(res.item.juryInfo.lawCtt);
                setRRatedAt(res.item.juryInfo.RRatedAt);
                setViewPosCode(res.item.juryInfo.viewPosCode);
                setViewCnt(res.item.juryInfo.viewCnt);

                const tempJuryPics: uldJuryPicProps[] = [];
                res.item.picList.forEach((picInfo: any) => {
                    tempJuryPics.push({
                        picSn: picInfo.picSn,
                        picFileNm: picInfo.picFileNm,
                        fileExtsnNm: picInfo.fileExtsnNm,
                        fileSize: picInfo.fileSize
                    });
                });
                setUldJuryPics(tempJuryPics);

                setReqJurordataArr(res.item.reqJurorList);
                setDefJurordataArr(res.item.defJurorList);
                setReplydataArr(res.item.replyList);
            }
        });
    }, [params.lawSn, jurySelTime]);

    return (
        <div className='dpFlx jcC'>
            <div className='w1200'>
                <div className="dpFlx jcC aiC h120">
                    <span className="fm-c fc222222 fs40 ls12">재판 {Util.isEmpty(params.lawSn) ? "등록" : "수정"}</span>
                </div>
                <div className="dpFlx aiC jcSb">
                    <div className='dpFlx aiC'>
                        <ViewListIcon style={{ fontSize: 24 }} />
                        <span className="fs16 fm-c ls1 pl5">재판 기본정보</span>
                    </div>
                    <div className='dpFlx jcSb aiC'>
                        {Util.isNotEmpty(params.lawSn) ?
                            <IconButton btnName="수정취소" iconPos="left" iconNm="delete" className="h35 bdN fs14 fcWhite bgcA4A4A4 mr3" onClick={() => {
                                AppContext.openAlert({
                                    title: "알림",
                                    msg: "수정을 취소하시겠습니까?",
                                    icon: "check",
                                    confirmText: "수정취소",
                                    handleConfirm: () => navigate("/admin/jury/view/" + params.lawSn + "/juryList/0")
                                });
                            }} /> :
                            <IconButton btnName="목록으로" iconPos="left" iconNm="list" className="h35 bdN fs14 fcWhite bgcA4A4A4 mr3" onClick={() => navigate("/admin/jury")} />
                        }
                        <IconButton btnName="저장하기" iconPos="right" iconNm="arrowRight" className="h35 fs14 fcWhite bdN bgc1E413A" onClick={insJuryInfo} />
                    </div>
                </div>
                <table className="form-table td-ls06 td-p-row td-fs13 tr-h43 mt10">
                    <colgroup>
                        <col className="w20p" />
                        <col className="w30p" />
                        <col className="w20p" />
                        <col className="w30p" />
                    </colgroup>
                    <tbody>
                        <tr className={Util.isEmpty(juryInfo.lawSn) ? "dpNone" : ""}>
                            <th className="taC">재판식별번호</th>
                            <td className="taC">{juryInfo.lawSn}</td>
                            <th className="taC">의뢰자</th>
                            <td className="taC">{juryInfo.fstInsNm}</td>
                        </tr>
                        <tr>
                            <th className="taC">사건번호</th>
                            <td className="taC">{Util.isEmpty(juryInfo.lawSn) ? lawLabel : juryInfo.lawLabel}</td>
                            <th className="taC">시작시간</th>
                            <td className="taC">{Util.isEmpty(juryInfo.lawSn) ? Util.getSpecificDate("-", "day", 0, "SS", "") : juryInfo.reqDt}</td>
                        </tr>
                        <tr className={Util.isEmpty(juryInfo.lawSn) ? "dpNone" : ""}>
                            <th className="taC">조회수</th>
                            <td className="taC">
                                <NumericFormat
                                    value={viewCnt}
                                    maxLength={7}
                                    decimalScale={2}
                                    onValueChange={(values) => setViewCnt(values.value)}
                                    placeholder="조회수를 입력해주세요."
                                    className="w100p h36 bdBox bd1 bdcDDDDDD pw10 bgcWhite pw5 taL ls11 fs15"
                                />
                            </td>
                            <th className="taC">주간베스트순위</th>
                            <td className="taC">{juryInfo.weekRank}</td>
                        </tr>
                        <tr>
                            <th className="taC">공개여부</th>
                            <td className="taC">
                                <SelectBox
                                    value={lawOpenCode}
                                    codeArray={Util.getCodeArray("1002")}
                                    placeholder="공개여부를 선택해주세요."
                                    height={36}
                                    fontSize={14}
                                    paddingRight={5}
                                    onChangeFunc={(data: string) => setLawOpenCode(data)}
                                    border="1px solid #dddddd"
                                />
                            </td>
                            <th className="taC">재판시간</th>
                            <td className="taC">
                                <SelectBox
                                    value={lawTime}
                                    codeArray={Util.getCodeArray("1013")}
                                    placeholder="재판시간을 선택해주세요."
                                    height={36}
                                    fontSize={14}
                                    paddingRight={5}
                                    onChangeFunc={(data: string) => setLawTime(data)}
                                    border="1px solid #dddddd"
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="taC">원고</th>
                            <td className="taC">
                                <TextBox
                                    className="bd1 pw5 bgcWhite"
                                    fontFamily="fm-c"
                                    inputClassName='pw5 taL ls11'
                                    value={reqUserNm}
                                    height={36}
                                    fontSize={14}
                                    maxLength={200}
                                    placeholder="원고 정보를 입력하세요."
                                    onChangeFunc={(text: string) => setReqUserNm(text)}
                                />
                            </td>
                            <th className="taC">피고</th>
                            <td className="taC">
                                <TextBox
                                    className="bd1 pw5 bgcWhite"
                                    fontFamily="fm-c"
                                    inputClassName='pw5 taL ls11'
                                    value={defUserNm}
                                    height={36}
                                    fontSize={14}
                                    maxLength={200}
                                    placeholder="피고 정보를 입력하세요."
                                    onChangeFunc={(text: string) => setDefUserNm(text)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="taC">19금여부</th>
                            <td className="taC">
                                <SelectBox
                                    value={RRatedAt}
                                    codeArray={Util.getCodeArray("1014")}
                                    placeholder="19금 여부를 선택해주세요."
                                    height={36}
                                    fontSize={14}
                                    paddingRight={5}
                                    onChangeFunc={(data: string) => setRRatedAt(data)}
                                    border="1px solid #dddddd"
                                />
                            </td>
                            <th className="taC">사용자조회상태</th>
                            <td className="taC">
                                <SelectBox
                                    value={viewPosCode}
                                    codeArray={Util.getCodeArray("1007")}
                                    placeholder="사용자조회상태를 선택해주세요."
                                    height={36}
                                    fontSize={14}
                                    paddingRight={5}
                                    onChangeFunc={(data: string) => setViewPosCode(data)}
                                    border="1px solid #dddddd"
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="taC">재판제목</th>
                            <td className="taC" colSpan={3}>
                                <TextBox
                                    className="bd1 pw5 bgcWhite"
                                    fontFamily="fm-c"
                                    inputClassName='pw5 taL ls11'
                                    value={lawTitle}
                                    height={36}
                                    fontSize={13}
                                    maxLength={200}
                                    placeholder="재판 제목을 입력하세요."
                                    onChangeFunc={(text: string) => setLawTitle(text)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="taC">내용</th>
                            <td colSpan={3}>
                                <div className="dpFlx aiE jcC flxdCol mb10">
                                    <TextAreaBox
                                        value={lawCtt}
                                        setValue={setLawCtt}
                                        className="w100p mh300 hAuto rszN olN bdBox fs14 fm-b fc2B2B2B ls07 bd1 bdcDDDDDD mh5 mh50 p10 lh20"
                                        autoHeight={true}
                                        placeholder="내용을 입력하세요."
                                        maxLength={4000}
                                    />
                                    <span className="fs12 ls07 fc222222 fm-b taR">
                                        글자수 :
                                        <span className="fm-c fcCrimson ml5">{lawCtt.length}</span>
                                        /4000
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {Util.isNotEmpty(params.lawSn) ?
                    <React.Fragment>
                        <div className='dpFlx aiC jcSb mt30 mb5'>
                            <div className="dpFlx aiC">
                                <ViewListIcon style={{ fontSize: 24 }} />
                                <span className="fs16 fmForest-c ls1 pl5">원고 배심원</span>
                            </div>
                            <IconButton btnName="원고 배심원 추가" iconPos="right" iconNm="arrowRight" className="h35 fs14 fcWhite bdN bgc1E413A" onClick={() => {
                                Axios.dataAccess({
                                    url: "admin/jury/selTestUserList.do",
                                    methodType: "post",
                                    paramType: "object",
                                    paramData: {
                                        lawSn: params.lawSn
                                    },
                                    onSuccessFn: (res: any) => {
                                        setTestUserList(res.list);
                                        setReqModalOpen(true);
                                    }
                                });
                            }} />
                        </div>
                        <MuiListFilter tableInfo={{
                            headVsb: true,
                            styleType: CustomStyle,
                            fldInfs: [
                                { type: 'text', fldNm: 'jurorVdctCtt', wAuto: true, headAlign: "center", dataAlign: "center", label: '판결내용' },
                                { type: 'text', fldNm: 'fstInsDt', width: 160, headAlign: "center", dataAlign: "center", label: '판결일자' },
                                { type: 'text', fldNm: 'jurorUserNm', width: 120, headAlign: "center", dataAlign: "center", label: '배심원' },
                                { type: 'el', width: 30, headAlign: "center", label: '삭제', el: (item: any) => (
                                    <DeleteForeverOutlinedIcon style={{ fontSize: 25, color: 'black', cursor: 'pointer', outline: 0 }} onClick={() => {
                                        AppContext.openAlert({
                                            title: "배심원 판결정보 삭제",
                                            msg: "배심원 판결정보를 삭제하시겠습니까?",
                                            icon: "check",
                                            confirmText: "삭제하기",
                                            handleConfirm: () => {
                                                Axios.dataAccess({
                                                    url: "admin/jury/delJuryJurorInfo.do",
                                                    methodType: "post",
                                                    paramType: "json",
                                                    paramData: {
                                                        lawSn: item.lawSn,
                                                        jurorUserSn: item.jurorUserSn
                                                    },
                                                    onSuccessFn: () => {
                                                        AppContext.openAlert({
                                                            title: "판결정보 삭제 완료",
                                                            msg: "판결정보가 삭제되었습니다.",
                                                            icon: "check"
                                                        });
                                                        setJurySelTime(new Date());
                                                    }
                                                });
                                            }
                                        });
                                    }} />
                                )}
                            ],
                            ordFld: { name: "fstInsDt", direction: "asc" },
                            dataArr: reqJurordataArr,
                            maxRowNums: [10],
                            trHeight: 40,
                            remainArea: false
                        }}>
                        </MuiListFilter>
                        <Modal modalOpen={reqModalOpen} setModalOpen={setReqModalOpen} title="원고 배심원 추가" dim={true}>
                            <div className="w500 p5">
                                <table className="form-table td-ls06 td-p-row fs13 tr-h43 mt10">
                                    <colgroup>
                                        <col className="w30p" />
                                        <col className="w70p" />
                                    </colgroup>
                                    <tbody>
                                    <tr>
                                        <th className="taC">배심원</th>
                                        <td className="taC">
                                            <SelectBox
                                                value={reqJurorUserSn}
                                                codeArray={testUserList}
                                                placeholder="배심원을 선택해주세요."
                                                height={36}
                                                fontSize={14}
                                                paddingRight={5}
                                                onChangeFunc={(data: string) => setReqJurorUserSn(data)}
                                                border="1px solid #dddddd"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="taC">판결내용</th>
                                        <td className="taC">
                                            <div className="dpFlx aiC jcC">
                                                <TextAreaBox
                                                    value={reqJurorVdctCtt}
                                                    setValue={setReqJurorVdctCtt}
                                                    className="w100p h100p rszN olN bdBox fs14 fm-b fc2B2B2B ls07 bd1 bdcDDDDDD mh5 mh50 p10 lh20"
                                                    autoHeight={true}
                                                    placeholder="판결내용을 입력하세요."
                                                    maxLength={1000}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div className='w100p h40 dpFlx aiC jcC mt10'>
                                    <IconButton btnName="저장" iconPos="right" iconNm="check" className="w70 h32 bdrd3 fs14 fcWhite bgc1E413A bdN mr3" onClick={() => {
                                        if (Util.isEmpty(reqJurorUserSn)) {
                                            AppContext.openAlert({
                                                title: "배심원 선택",
                                                msg: "배심원을 선택하세요.",
                                                icon: "check"
                                            });
                                            return;
                                        }
                                        if (Util.isEmpty(reqJurorVdctCtt)) {
                                            AppContext.openAlert({
                                                title: "판결내용 입력",
                                                msg: "판결내용을 입력하세요.",
                                                icon: "check"
                                            });
                                            return;
                                        }
                                        AppContext.openAlert({
                                            title: "배심원 판결정보 저장",
                                            msg: "배심원 판결정보를 저장하시겠습니까?",
                                            icon: "check",
                                            confirmText: "저장하기",
                                            handleConfirm: () => {
                                                AppContext.showSpinner();
                                                Axios.dataAccess({
                                                    url: "admin/jury/insJuryJurorInfo.do",
                                                    methodType: "post",
                                                    paramType: "json",
                                                    paramData: {
                                                        lawSn: Number(params.lawSn),
                                                        jurorUserSn: reqJurorUserSn,
                                                        jurorRsltCode: "JRC001",
                                                        jurorVdctCtt: reqJurorVdctCtt
                                                    },
                                                    onSuccessFn: () => {
                                                        AppContext.hideSpinner();
                                                        setReqModalOpen(false);
                                                        setReqJurorUserSn("");
                                                        setReqJurorVdctCtt("");
                                                        setJurySelTime(new Date());
                                                    }
                                                });
                                            }
                                        });
                                    }} />
                                </div>
                            </div>
                        </Modal>
                        <div className='dpFlx aiC jcSb mt30 mb5'>
                            <div className="dpFlx aiC">
                                <ViewListIcon style={{ fontSize: 24 }} />
                                <span className="fs16 fmForest-c ls1 pl5">피고 배심원</span>
                            </div>
                            <IconButton btnName="피고 배심원 추가" iconPos="right" iconNm="arrowRight" className="h35 fs14 fcWhite bdN bgc1E413A" onClick={() => {
                                Axios.dataAccess({
                                    url: "admin/jury/selTestUserList.do",
                                    methodType: "post",
                                    paramType: "object",
                                    paramData: {
                                        lawSn: params.lawSn
                                    },
                                    onSuccessFn: (res: any) => {
                                        setTestUserList(res.list);
                                        setDefModalOpen(true);
                                    }
                                });
                            }} />
                        </div>
                        <MuiListFilter tableInfo={{
                            headVsb: true,
                            styleType: CustomStyle,
                            fldInfs: [
                                { type: 'text', fldNm: 'jurorVdctCtt', wAuto: true, headAlign: "center", dataAlign: "center", label: '판결내용' },
                                { type: 'text', fldNm: 'fstInsDt', width: 160, headAlign: "center", dataAlign: "center", label: '판결일자' },
                                { type: 'text', fldNm: 'jurorUserNm', width: 120, headAlign: "center", dataAlign: "center", label: '배심원' },
                                { type: 'el', width: 30, headAlign: "center", label: '삭제', el: (item: any) => (
                                    <DeleteForeverOutlinedIcon style={{ fontSize: 25, color: 'black', cursor: 'pointer', outline: 0 }} onClick={() => {
                                        AppContext.openAlert({
                                            title: "배심원 판결정보 삭제",
                                            msg: "배심원 판결정보를 삭제하시겠습니까?",
                                            icon: "check",
                                            confirmText: "삭제하기",
                                            handleConfirm: () => {
                                                Axios.dataAccess({
                                                    url: "admin/jury/delJuryJurorInfo.do",
                                                    methodType: "post",
                                                    paramType: "json",
                                                    paramData: {
                                                        lawSn: item.lawSn,
                                                        jurorUserSn: item.jurorUserSn
                                                    },
                                                    onSuccessFn: () => {
                                                        AppContext.openAlert({
                                                            title: "판결정보 삭제 완료",
                                                            msg: "판결정보가 삭제되었습니다.",
                                                            icon: "check"
                                                        });
                                                        setJurySelTime(new Date());
                                                    }
                                                });
                                            }
                                        });
                                    }} />
                                )}
                            ],
                            ordFld: { name: "fstInsDt", direction: "asc" },
                            dataArr: defJurordataArr,
                            maxRowNums: [10],
                            trHeight: 40,
                            remainArea: false
                        }}>
                        </MuiListFilter>
                        <Modal modalOpen={defModalOpen} setModalOpen={setDefModalOpen} title="피고 배심원 추가" dim={true}>
                            <div className="w500 p5">
                                <table className="form-table td-ls06 td-p-row fs13 tr-h43 mt10">
                                    <colgroup>
                                        <col className="w30p" />
                                        <col className="w70p" />
                                    </colgroup>
                                    <tbody>
                                    <tr>
                                        <th className="taC">배심원</th>
                                        <td className="taC">
                                            <SelectBox
                                                value={defJurorUserSn}
                                                codeArray={testUserList}
                                                placeholder="배심원을 선택해주세요."
                                                height={36}
                                                fontSize={14}
                                                paddingRight={5}
                                                onChangeFunc={(data: string) => setDefJurorUserSn(data)}
                                                border="1px solid #dddddd"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="taC">판결내용</th>
                                        <td className="taC">
                                            <div className="dpFlx aiC jcC">
                                                <TextAreaBox
                                                    value={defJurorVdctCtt}
                                                    setValue={setDefJurorVdctCtt}
                                                    className="w100p h100p rszN olN bdBox fs14 fm-b fc2B2B2B ls07 bd1 bdcDDDDDD mh5 mh50 p10 lh20"
                                                    autoHeight={true}
                                                    placeholder="판결내용을 입력하세요."
                                                    maxLength={1000}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div className='w100p h40 dpFlx aiC jcC mt10'>
                                    <IconButton btnName="저장" iconPos="right" iconNm="check" className="w70 h32 bdrd3 fs14 fcWhite bgc1E413A bdN mr3" onClick={() => {
                                        if (Util.isEmpty(defJurorUserSn)) {
                                            AppContext.openAlert({
                                                title: "배심원 선택",
                                                msg: "배심원을 선택하세요.",
                                                icon: "check"
                                            });
                                            return;
                                        }
                                        if (Util.isEmpty(defJurorVdctCtt)) {
                                            AppContext.openAlert({
                                                title: "판결내용 입력",
                                                msg: "판결내용을 입력하세요.",
                                                icon: "check"
                                            });
                                            return;
                                        }
                                        AppContext.openAlert({
                                            title: "배심원 판결정보 저장",
                                            msg: "배심원 판결정보를 저장하시겠습니까?",
                                            icon: "check",
                                            confirmText: "저장하기",
                                            handleConfirm: () => {
                                                AppContext.showSpinner();
                                                Axios.dataAccess({
                                                    url: "admin/jury/insJuryJurorInfo.do",
                                                    methodType: "post",
                                                    paramType: "json",
                                                    paramData: {
                                                        lawSn: Number(params.lawSn),
                                                        jurorUserSn: defJurorUserSn,
                                                        jurorRsltCode: "JRC002",
                                                        jurorVdctCtt: defJurorVdctCtt
                                                    },
                                                    onSuccessFn: () => {
                                                        AppContext.hideSpinner();
                                                        setDefModalOpen(false);
                                                        setDefJurorUserSn("");
                                                        setDefJurorVdctCtt("");
                                                        setJurySelTime(new Date());
                                                    }
                                                });
                                            }
                                        });
                                    }} />
                                </div>
                            </div>
                        </Modal>
                        <div className='dpFlx aiC jcSb mt30 mb5'>
                            <div className="dpFlx aiC">
                                <ViewListIcon style={{ fontSize: 24 }} />
                                <span className="fs16 fmForest-c ls1 pl5">댓글목록</span>
                            </div>
                            <IconButton btnName="댓글 작성" iconPos="right" iconNm="arrowRight" className="h35 fs14 fcWhite bdN bgc1E413A" onClick={() => {
                                Axios.dataAccess({
                                    url: "admin/jury/selTestUserList.do",
                                    methodType: "post",
                                    paramType: "object",
                                    paramData: {
                                        lawSn: -1
                                    },
                                    onSuccessFn: (res: any) => {
                                        setReplyTestUserList(res.list);
                                        setReplyModalOpen(true);
                                    }
                                });
                            }} />
                        </div>
                        <MuiListFilter tableInfo={{
                            headVsb: true,
                            styleType: CustomStyle,
                            fldInfs: [
                                { type: 'text', fldNm: 'replyCtt', wAuto: true, headAlign: "center", dataAlign: "center", label: '댓글내용' },
                                { type: 'text', fldNm: 'fstInsDt', width: 160, headAlign: "center", dataAlign: "center", label: '댓글작성일자' },
                                { type: 'text', fldNm: 'nickName', width: 120, headAlign: "center", dataAlign: "center", label: '작성자' },
                                { type: 'el', width: 30, headAlign: "center", label: '삭제', el: (item: any) => (
                                    <DeleteForeverOutlinedIcon style={{ fontSize: 25, color: 'black', cursor: 'pointer', outline: 0 }} onClick={() => {
                                        AppContext.openAlert({
                                            title: "댓글정보 삭제",
                                            msg: "댓글을 삭제하시겠습니까?",
                                            icon: "check",
                                            confirmText: "삭제하기",
                                            handleConfirm: () => {
                                                Axios.dataAccess({
                                                    url: "admin/jury/delJuryReplyInfo.do",
                                                    methodType: "post",
                                                    paramType: "json",
                                                    paramData: {
                                                        lawSn: item.lawSn,
                                                        lawReplySn: item.lawReplySn
                                                    },
                                                    onSuccessFn: () => {
                                                        AppContext.openAlert({
                                                            title: "댓글정보 삭제 완료",
                                                            msg: "댓글이 삭제되었습니다.",
                                                            icon: "check"
                                                        });
                                                        setJurySelTime(new Date());
                                                    }
                                                });
                                            }
                                        });
                                    }} />
                                )}
                            ],
                            ordFld: { name: "fstInsDt", direction: "asc" },
                            dataArr: replydataArr,
                            maxRowNums: [10],
                            trHeight: 40,
                            remainArea: false
                        }}>
                        </MuiListFilter>
                        <Modal modalOpen={replyModalOpen} setModalOpen={setReplyModalOpen} title="댓글 추가" dim={true}>
                            <div className="w500 p5">
                                <table className="form-table td-ls06 td-p-row fs13 tr-h43 mt10">
                                    <colgroup>
                                        <col className="w30p" />
                                        <col className="w70p" />
                                    </colgroup>
                                    <tbody>
                                    <tr>
                                        <th className="taC">댓글 작성자</th>
                                        <td className="taC">
                                            <SelectBox
                                                value={replyUserSn}
                                                codeArray={replyTestUserList}
                                                placeholder="댓글 작성자를 선택해주세요."
                                                height={36}
                                                fontSize={14}
                                                paddingRight={5}
                                                onChangeFunc={(data: string) => setReplyUserSn(data)}
                                                border="1px solid #dddddd"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="taC">댓글내용</th>
                                        <td className="taC">
                                            <div className="dpFlx aiC jcC">
                                                <TextAreaBox
                                                    value={replyCtt}
                                                    setValue={setReplyCtt}
                                                    className="w100p h100p rszN olN bdBox fs14 fm-b fc2B2B2B ls07 bd1 bdcDDDDDD mh5 mh50 p10 lh20"
                                                    autoHeight={true}
                                                    placeholder="댓글내용을 입력하세요."
                                                    maxLength={1000}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div className='w100p h40 dpFlx aiC jcC mt10'>
                                    <IconButton btnName="저장" iconPos="right" iconNm="check" className="w70 h32 bdrd3 fs14 fcWhite bgc1E413A bdN mr3" onClick={() => {
                                        if (Util.isEmpty(replyUserSn)) {
                                            AppContext.openAlert({
                                                title: "작성자 선택",
                                                msg: "작성자를 선택하세요.",
                                                icon: "check"
                                            });
                                            return;
                                        }
                                        if (Util.isEmpty(replyCtt)) {
                                            AppContext.openAlert({
                                                title: "댓글내용 입력",
                                                msg: "댓글내용을 입력하세요.",
                                                icon: "check"
                                            });
                                            return;
                                        }
                                        AppContext.openAlert({
                                            title: "댓글정보 저장",
                                            msg: "댓글정보를 저장하시겠습니까?",
                                            icon: "check",
                                            confirmText: "저장하기",
                                            handleConfirm: () => {
                                                AppContext.showSpinner();
                                                Axios.dataAccess({
                                                    url: "admin/jury/insJuryReplyInfo.do",
                                                    methodType: "post",
                                                    paramType: "json",
                                                    paramData: {
                                                        lawSn: Number(params.lawSn),
                                                        replyUserSn: replyUserSn,
                                                        replyCtt: replyCtt
                                                    },
                                                    onSuccessFn: () => {
                                                        AppContext.hideSpinner();
                                                        setReplyModalOpen(false);
                                                        setReplyUserSn("");
                                                        setReplyCtt("");
                                                        setJurySelTime(new Date());
                                                    }
                                                });
                                            }
                                        });
                                    }} />
                                </div>
                            </div>
                        </Modal>
                    </React.Fragment>
                : <></>}
                <div className='dpFlx aiC mt30 mb10'>
                    <ViewListIcon style={{ fontSize: 24 }} />
                    <span className="fs16 fmForest-c ls1 pl5">재판 첨부사진</span>
                </div>
                <Dropzone
                    onDrop={(acceptFiles: File[]) => {
                        if (uldJuryPics.length + juryPics.length + acceptFiles.length > 10) {
                            AppContext.openAlert({
                                title: "첨부사진 갯수 제한",
                                msg: "재판글 내 첨부사진은 10개를 초과할 수 없습니다.",
                                icon: "check"
                            });
                            return;
                        }
                        for (const acceptFile of acceptFiles) {
                            const extNm = acceptFile.name.substring(acceptFile.name.lastIndexOf(".") + 1).toLowerCase();
                            if (AppContext.imageWhiteExt.indexOf(extNm) === -1) {
                                AppContext.openAlert({
                                    title: "사진 확장자 체크",
                                    msg: extNm + " 확장자는 업로드 불가한 확장자의 사진파일 입니다.",
                                    icon: "error"
                                });
                                break;
                            }
                            juryPics.push(acceptFile);
                            setJuryPics(juryPics);
                        }
                    }}
                >
                    {({getRootProps, getInputProps}) => (
                        <section>
                            <div className="w100p mh100 bdDash1 bdcCECECE csP pl10 pr10 pb10 bdBox" {...getRootProps()}>
                                <input {...getInputProps()} />
                                {uldJuryPics.length === 0 && juryPics.length === 0 ?
                                    <div className="dpFlx aiC jcC h90 bdBox">
                                        <span className="fm-b fs14 fc333333 ls08 pt10">첨부할 사진을 드래그하세요.</span>
                                    </div>
                                : <></>}
                                {uldJuryPics.map((item: any, idx: number) => {
                                    return (
                                        <div key={idx} className="bdrd5 bd1 bdcCECECE p10 csP dpInBlock mr10 mt10 bgcFFE6CE" onClick={(e) => {
                                            e.stopPropagation();
                                            Axios.dataAccess({
                                                url: "common/fileDownload.do",
                                                methodType: "get",
                                                isFileDown: true,
                                                paramData: {
                                                    callType: "jury",
                                                    fileName: params.lawSn + "-" + item.picSn + "." + item.fileExtsnNm,
                                                    orgFileName: item.picFileNm
                                                }
                                            });
                                        }}>
                                            <div className="dpFlx aiC jcSb">
                                                <span className="fm-b fs13 fcBlack ls08">{item.picFileNm}</span>
                                                <CloseIcon
                                                    style={{ fontSize: 20, color: 'black', paddingLeft: 5 }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        AppContext.openAlert({
                                                            title: "첨부사진 삭제",
                                                            msg: "첨부사진을 삭제하시겠습니까?",
                                                            icon: "check",
                                                            confirmText: "사진삭제",
                                                            handleConfirm: () => {
                                                                AppContext.showSpinner();
                                                                Axios.dataAccess({
                                                                    url: "admin/jury/delJuryPicInfo.do",
                                                                    methodType: "post",
                                                                    paramData: {
                                                                        lawSn: Number(params.lawSn),
                                                                        picSn: item.picSn,
                                                                        fileExtsnNm: item.fileExtsnNm
                                                                    },
                                                                    onSuccessFn: () => {
                                                                        const tempFiles = [];
                                                                        for (let i = 0; i < uldJuryPics.length; i++) {
                                                                            if (i !== idx) {
                                                                                tempFiles.push(uldJuryPics[i]);
                                                                            }
                                                                        }
                                                                        setUldJuryPics(tempFiles);
                                                                        AppContext.openAlert({
                                                                            title: "첨부사진 삭제완료",
                                                                            msg: "재판글 첨부사진이 삭제되었습니다.",
                                                                            icon: "check"
                                                                        });
                                                                        AppContext.hideSpinner();
                                                                    }
                                                                });
                                                            }
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                                {juryPics.map((item: any, idx: number) => {
                                    return (
                                        <div key={idx} className="bdrd5 bd1 bdcCECECE p10 csP dpInBlock mr10 mt10" onClick={(e) => {
                                            e.stopPropagation();
                                        }}>
                                            <div className="dpFlx aiC jcSb">
                                                <span className="fm-b fs13 fcBlack ls08">{item.name}</span>
                                                <CloseIcon
                                                    style={{ fontSize: 20, color: 'black', paddingLeft: 5 }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        const tempFiles = [];
                                                        for (let i = 0; i < juryPics.length; i++) {
                                                            if (i !== idx) {
                                                                tempFiles.push(juryPics[i]);
                                                            }
                                                        }
                                                        setJuryPics(tempFiles);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </section>
                    )}
                </Dropzone>
                <div className='dpFlx jcC aiC mt20'>
                    {Util.isNotEmpty(params.lawSn) ?
                        <IconButton btnName="수정취소" iconPos="left" iconNm="delete" className="h35 bdN fs14 fcWhite bgcA4A4A4 mr3" onClick={() => {
                            AppContext.openAlert({
                                title: "알림",
                                msg: "수정을 취소하시겠습니까?",
                                icon: "check",
                                confirmText: "수정취소",
                                handleConfirm: () => navigate("/admin/jury/view/" + params.lawSn + "/juryList/0")
                            });
                        }} /> :
                        <IconButton btnName="목록으로" iconPos="left" iconNm="list" className="h35 bdN fs14 fcWhite bgcA4A4A4 mr3" onClick={() => navigate("/admin/jury")} />
                    }
                    <IconButton btnName="저장하기" iconPos="right" iconNm="arrowRight" className="h35 fs14 fcWhite bdN bgc1E413A" onClick={insJuryInfo} />
                </div>
            </div>
        </div>
    )
}
export default JuryWrite;