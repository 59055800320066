import { useRecoilState } from "recoil";
import { alertState } from "./RecoilAlert";

export default function UseAlert() {
    const [alert, setAlert] = useRecoilState(alertState);

    const showAlert = ({title, message, iconFg, confirmText, handleConfirm, closeText, handleClose}: any) => {
        const alertProps = {
            title: title,
            message: message,
            iconFg: iconFg,
            confirmText: confirmText,
            handleConfirm: handleConfirm,
            closeText: closeText,
            handleClose: handleClose
        };
        setAlert({ alertProps });
    };
    const hideAlert = () => {
        setAlert(null);
    };
    return {
        alert,
        setAlert,
        showAlert,
        hideAlert
    };
}