import Util from "js/common/Util";
import SearchInput from "js/common/ui/SearchInput";
import MuiListFilter from "js/common/ui/list/MuiListFilter";
import React from "react";
import ForestTableStyle from 'js/common/ui/style/CustomStyle';
import AppContext from "js/common/AppContext";
import Axios from "js/common/Axios";
import UserDialog from "./dialog/UserDialog";
import Modal from "js/common/ui/modal/Modal";
import SportsKabaddiOutlinedIcon from '@mui/icons-material/SportsKabaddiOutlined';
import CustomButton from "js/common/ui/CustomButton";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useRecoilValue } from "recoil";
import * as Atom from "js/common/Atom";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const UserMng = () => {
    const navigate = useNavigate();
    const userInfo = useRecoilValue(Atom.userInfo);
    const fldInfs = [
        { fldNm: 'userSn', pk: true },
        { type: 'text', fldNm: 'nickName', width: 80, headAlign: "center", dataAlign: "center", label: '닉네임' },
        { type: 'text', fldNm: 'emailAddress', wAuto: true, headAlign: "center", dataAlign: "center", label: '이메일' },
        { type: 'text', fldNm: 'kakaoNickName', width: 100, headAlign: "center", dataAlign: "center", label: '카카오이름' },
        { type: 'code', fldNm: 'genderCode', width: 50, headAlign: "center", dataAlign: "center", label: '성별', codeTy: "1015" },
        { type: 'code', fldNm: 'ageRangeCode', width: 60, headAlign: "center", dataAlign: "center", label: '연령대', codeTy: "1016" },
        { type: 'code', fldNm: 'isAdmin', width: 90, headAlign: "center", dataAlign: "center", label: '관리자여부', codeTy: "1011" },
        { type: 'text', fldNm: 'fstInsYmd', width: 80, headAlign: "center", dataAlign: "center", label: '가입일자' },
        { type: 'text', fldNm: 'pageDt', width: 130, headAlign: "center", dataAlign: "center", label: '마지막접속' },
        { type: 'el', fldNm: 'lawCnt', width: 45, headAlign: "center", label: '재판', el: (item: any) => (
            <span className={"fs12 " + (item.lawCnt > 0 ? "fwB tdU tuOs2" : "")} onClick={(e) => {
                e.stopPropagation();
                navigate("/admin/user/jury/" + item.userSn);
            }}>
                {item.lawCnt}
            </span>
        )},
        { type: 'el', fldNm: 'jurorCnt', width: 45, headAlign: "center", label: '판결', el: (item: any) => (
            <span className={"fs12 " + (item.jurorCnt > 0 ? "fwB tdU tuOs2" : "")} onClick={(e) => {
                e.stopPropagation();
                navigate("/admin/user/juror/" + item.userSn);
            }}>
                {item.jurorCnt}
            </span>
        )},
        { type: 'text', fldNm: 'replyCnt', width: 45, headAlign: "center", dataAlign: "center", label: '댓글' },
        { type: 'el', fldNm: 'userSttCode', width: 70, headAlign: "center", label: '회원상태', el: (item: any) => (
            <div className={'dpFlx aiC jcC w70 h30 bdrd3 bdBox ' + (item.userSttCode === "USR001" ? "bgc007CE8" : "bgcWhite bd1 bdc007CE8")}>
                <span className={"fs12 " + (item.userSttCode === "USR001" ? "fcWhite" : "fc007CE8")}>{Util.getValToCode("1009", item.userSttCode)}</span>
            </div>
        )}
    ];
    const ordFld = { name: "pageDt", direction: "desc" };
    const [dataArr, setDataArr] = React.useState([]);
    const [filterDataArr, setFilterDataArr]: any[] = React.useState([]);
    const [srchText, setSrchText] = React.useState<string>("");
    const [userModalOpen, setUserModalOpen] = React.useState<boolean>(false); // 유저정보 조회 모달 호출변수
    const [userModalTitle, setUserModalTitle] = React.useState<string>("");
    const [userSn, setUserSn]  = React.useState<number | null>();
    const [userSelTime, setUserSelTime] = React.useState<Date>(new Date());

    const onClickSrchBtn = () => {
        setFilterDataArr(Util.filterData(dataArr, fldInfs, srchText));
    }

    React.useEffect(() => {
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "admin/user/selUserList.do",
            methodType: "post",
            onSuccessFn: (res: any) => {
                setDataArr(res.list);
                setFilterDataArr(res.list);
                AppContext.hideSpinner();
            }
        });
    }, [userSelTime]);

    return (
        <React.Fragment>
            <div className="dpFlx jcC aiC h120">
                <span className="fm-c fc222222 fs40 ls12">회원관리</span>
            </div>
            <div className="dpFlx jcC">
                <div className='w1200'>
                    <div className='dpFlx jcSb aiC pb5'>
                        <div className='dpFlx jcSb aiC'>
                            <SportsKabaddiOutlinedIcon />
                            <span className='fs23 ls12 ml10'>사용자 목록</span>
                        </div>
                        <div className='dpFlx jcSb aiC'>
                            <SearchInput data={srchText} setData={setSrchText} onClick={onClickSrchBtn} className="w200 h35 pl3 bd1 bdcCECECE" />
                            <CustomButton
                                btnName="테스트 계정 추가"
                                className="h35 fs12-i bd1-i bdcBlack-i bgcWhite-i fcBlack-i ml5-i"
                                endIcon={<AddCircleOutlineOutlinedIcon />}
                                onClick={() => {
                                    setUserModalTitle("테스트 계정 추가");
                                    setUserSn(null);
                                    setUserModalOpen(true);
                                }}
                            />
                            <CustomButton
                                btnName="콜택스 바로가기"
                                className={"h35 fs12-i bd1-i bdcBlack-i bgcWhite-i fcBlack-i ml5-i " + (userInfo.userSn === 32 ? "" : "dpNone-i")}
                                endIcon={<AddCircleOutlineOutlinedIcon />}
                                onClick={() => {
                                    axios.post(
                                        `https://calltax.kr/backend/portal/join/checkWecakeApi.do`,
                                        {
                                            clientId: "LLOK-2G4J-42OP-DHCW",
                                            wcUserId: userInfo.userId,
                                            wcUserNm: userInfo.userNm,
                                            wcUserHp: userInfo.phoneNum,
                                            wcUserMail: userInfo.emailAddress
                                        },
                                        {
                                            headers: {
                                                'Content-type': "application/json"
                                            }
                                        }
                                    ).then((res) => {
                                        if (res.data._resultCode_ === "failed") {
                                            alert(res.data._msg_);
                                        } else {
                                            window.open("http://calltax.kr/portal/wecake/" + userInfo.userId + "/" + res.data.item, "_blank", "noopener, noreferrer");
                                        }
                                    }).catch(error => {
                                        alert("API 호출 실패" + error);
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <MuiListFilter tableInfo={{
                        headVsb: true,
                        styleType: ForestTableStyle,
                        fldInfs: fldInfs,
                        ordFld: ordFld,
                        dataArr: filterDataArr,
                        maxRowNums: [10, 20, 30],
                        trHeight: 40,
                        remainArea: false,
                        rowClickFunc: (item: any) => {
                            setUserModalTitle("사용자 정보 조회");
                            setUserSn(item.userSn);
                            setUserModalOpen(true);
                        }
                    }}>
                    </MuiListFilter>
                </div>
            </div>
            <Modal modalOpen={userModalOpen} setModalOpen={setUserModalOpen} title={userModalTitle} dim={true}>
                <UserDialog setModalOpen={setUserModalOpen} userSn={userSn} setUserSelTime={setUserSelTime} />
            </Modal>
        </React.Fragment>
    )
}
export default UserMng