import React from 'react';
import UseAlert from 'js/common/ui/alert/UseAlert';
import { Container } from 'semantic-ui-react';
import Header from 'js/layout/Header';
import Body from 'js/layout/Body';
import Footer from 'js/layout/Footer';
import Spinner from 'js/common/ui/Spinner';
import AppContext from 'js/common/AppContext';
import Axios from 'js/common/Axios';
import { useSetRecoilState } from "recoil";
import * as Atom from "js/common/Atom";
import { useNavigate, useLocation } from 'react-router-dom';

function App() {
    const location = useLocation();
    const navigate = useNavigate();
    const { showAlert, hideAlert } = UseAlert();
    const [landerCmplt, setLanderCmplt] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [loadingDim, setLoadingDim] = React.useState<boolean>(false);
    const setUserInfo = useSetRecoilState(Atom.userInfo);
    const setIsAdmin = useSetRecoilState(Atom.isAdmin);
    const [appLoad, setAppLoad] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (appLoad) {
            return;
        }
        if (process.env.REACT_APP_ENV === "local") {
            if (process.env.REACT_APP_BACKEND_URL === undefined) {
                return;
            }
            AppContext.backendUrl = process.env.REACT_APP_BACKEND_URL;
        } else {
            AppContext.backendUrl = window.location.origin + "/backend";
        }
        Axios.dataAccess({
            url: 'common/getSystemInfo.do',
            methodType: 'post',
            onSuccessFn: (res: any) => {
                setAppLoad(true);
                AppContext.codeList = res.item.codeList;
                AppContext.fileWhiteExt = res.item.fileWhiteExt;
                AppContext.imageWhiteExt = res.item.imageWhiteExt;
                AppContext.maxfileSize = res.item.maxfileSize;
                AppContext.accessTokenExpireTime = res.item.accessTokenExpireTime;
                AppContext.refreshTokenExpireTime = res.item.refreshTokenExpireTime;
                AppContext.showSpinner = () => {
                    setLoading(true);
                    setLoadingDim(false);
                }
                AppContext.hideSpinner = () => {
                    setLoading(false);
                    setLoadingDim(false);
                }
                AppContext.showSpinnerDim = () => {
                    setLoading(true);
                    setLoadingDim(true);
                }
                AppContext.openAlert = ({title, msg, icon, closeText, handleClose, confirmText, handleConfirm}: any) => {
                    showAlert({
                        title: title,
                        message: msg,
                        iconFg: icon,
                        closeText: closeText,
                        handleClose: handleClose,
                        confirmText: confirmText,
                        handleConfirm: handleConfirm
                    });
                };
                const tokenCrtDt = localStorage.getItem("tokenCrtDt");
                if (tokenCrtDt !== null) { // 토큰정보 있으면
                    Axios.dataAccess({
                        url: 'system/home/getBaseInfo.do',
                        methodType: 'post',
                        onSuccessFn: (res: any) => {
                            // 유저 정보 세팅
                            setUserInfo(res.item.userInfo);
                            setIsAdmin(res.item.userInfo.isAdmin);
                            localStorage.setItem("isAdmin", res.item.userInfo.isAdmin ? "1" : "0");

                            if (!res.item.userInfo.isAdmin && location.pathname.startsWith('/admin', 0)) { // 관리자가 아닌데 관리자 경로 접근할 경우
                                navigate('/'); // 메인페이지로
                            }
                            setLanderCmplt(true);
                        }
                    });
                } else { // 토큰정보 없으면
                    navigate('/'); // 메인페이지로
                    setLanderCmplt(true);
                }
            },
        });
    }, [showAlert, setIsAdmin, setUserInfo, navigate, location.pathname, appLoad]);

    React.useEffect(() => {
        const keydown = (e: any) => {
            if (e.keyCode === 27) {
                hideAlert();
            }
        }
        window.addEventListener('keydown', keydown)
        return () => window.removeEventListener('keydown', keydown)
    }, [hideAlert]);

    React.useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [location.pathname]);
    
    if (!landerCmplt) {
        return (
            <Spinner loading={true} loadingDim={true} />
        )
    } else {
        return (
            <React.Fragment>
                <Container>
                    <Header />
                    <Body />
                    <Footer />
                </Container>
                <Spinner loading={loading} loadingDim={loadingDim} />
            </React.Fragment>
        )
    }
}
export default App;