import { useNavigate, useLocation } from "react-router-dom";
import Util from "js/common/Util";
import SearchInput from "js/common/ui/SearchInput";
import MuiListFilter, { fldInfProps } from "js/common/ui/list/MuiListFilter";
import React from "react";
import ForestTableStyle from 'js/common/ui/style/CustomStyle';
import AppContext from "js/common/AppContext";
import Axios from "js/common/Axios";
import CustomButton from 'js/common/ui/CustomButton';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import SportsKabaddiOutlinedIcon from '@mui/icons-material/SportsKabaddiOutlined';

interface BoardProps {
    boardCode: string
}
const Board = ({boardCode}: BoardProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const fldInfs: fldInfProps[] = [
        { fldNm: 'boardSn', pk: true },
        { type: 'text', fldNm: 'rowNum', width: 45, headAlign: "center", dataAlign: "center", label: '번호' },
        { type: 'text', fldNm: 'boardTitle', wAuto: true, headAlign: "center", dataAlign: "center", label: '게시글 제목' },
        { type: 'text', fldNm: 'boardHit', width: 110, headAlign: "center", dataAlign: "center", label: '조회수' },
        { type: 'text', fldNm: 'fileCnt', width: 50, headAlign: "center", dataAlign: "center", label: '파일' },
        { type: 'text', fldNm: 'replyCnt', width: 50, headAlign: "center", dataAlign: "center", label: '댓글' }
    ];
    if (location.pathname === "/admin/notice" || location.pathname === "/system/expert") {
        fldInfs.push({ type: 'el', width: 30, headAlign: "center", label: '삭제', el: (item: any) => (
            <DeleteForeverOutlinedIcon style={{ fontSize: 25, color: 'black', cursor: 'pointer', outline: 0 }} onClick={(e) => {
                e.stopPropagation();
                delBoardInfo(item.boardSn);
            }} />
        )});
    }
    const ordFld = { name: "rowNum", direction: "asc" };
    const [dataArr, setDataArr] = React.useState<any[]>([]);
    const [filterDataArr, setFilterDataArr] = React.useState<any[]>([]);
    const [srchText, setSrchText] = React.useState<string>("");
    const [boardSelTime, setBoardSelTime] = React.useState<Date>(new Date());

    const onClickSrchBtn = () => {
        setFilterDataArr(Util.filterData(dataArr, fldInfs, srchText));
    }

    const delBoardInfo = (boardSn: number) => {
        AppContext.openAlert({
            title: "게시글 삭제",
            msg: "게시글을 삭제하시겠습니까?",
            icon: "check",
            confirmText: "삭제",
            handleConfirm: () => {
                AppContext.showSpinner();
                Axios.dataAccess({
                    url: "admin/board/delBoardInfo.do",
                    methodType: "post",
                    paramType: "object",
                    paramData: {
                        boardSn: boardSn
                    },
                    onSuccessFn: () => {
                        AppContext.hideSpinner();
                        setBoardSelTime(new Date());
                    }
                });
            }
        });
    }

    React.useEffect(() => {
        AppContext.showSpinner();
        Axios.dataAccess({
            url: "portal/board/selBoardList.do",
            methodType: "post",
            paramType: "json",
            paramData: {
                boardCode: boardCode
            },
            onSuccessFn: (res: any) => {
                setDataArr(res.list);
                setFilterDataArr(res.list);
                AppContext.hideSpinner();
            }
        });
    }, [boardCode, boardSelTime]);

    return (
        <React.Fragment>
            <div className="dpFlx jcC aiC h150">
                <span className="fm-c fc222222 fs40 ls12">
                    {location.pathname === "/admin/notice" ? "공지사항 관리" : ""}
                    {location.pathname === "/" ? "공지사항" : ""}
                    {location.pathname === "/portal/notice" ? "공지사항" : ""}
                    {location.pathname === "/system/expert" ? "전문가 커뮤니티" : ""}
                </span>
            </div>
            <div className="dpFlx jcC">
                <div className='w1200'>
                    <div className='dpFlx jcSb aiC pb5'>
                        <div className='dpFlx jcSb aiC'>
                            <SportsKabaddiOutlinedIcon />
                            <span className='fs23 ls12 ml10'>
                                {location.pathname === "/admin/notice" ? "공지사항 관리" : ""}
                                {location.pathname === "/portal/notice" ? "공지사항" : ""}
                                {location.pathname === "/system/expert" ? "전문가 커뮤니티" : ""}
                            </span>
                        </div>
                        <div className='dpFlx jcSb aiC'>
                            <SearchInput data={srchText} setData={setSrchText} onClick={onClickSrchBtn} className="w200 h35 pl3 bd1 bdcCECECE" />
                            {location.pathname === "/admin/notice" ?
                                <CustomButton
                                    btnName="공지사항등록"
                                    className="h35 fs12-i bd1-i bdcBlack-i bgcWhite-i fcBlack-i ml5-i"
                                    endIcon={<AddCircleOutlineOutlinedIcon />}
                                    onClick={() => {
                                        navigate("/admin/notice/reg");
                                    }}
                                />
                            : <></>}
                            {location.pathname === "/system/expert" ?
                                <CustomButton
                                    btnName="게시글등록"
                                    className="h35 fs12-i bd1-i bdcBlack-i bgcWhite-i fcBlack-i ml5-i"
                                    endIcon={<AddCircleOutlineOutlinedIcon />}
                                    onClick={() => {
                                        navigate("/system/expert/reg");
                                    }}
                                />
                            : <></>}
                        </div>
                    </div>
                    <MuiListFilter tableInfo={{
                        headVsb: true,
                        styleType: ForestTableStyle,
                        fldInfs: fldInfs,
                        ordFld: ordFld,
                        dataArr: filterDataArr,
                        maxRowNums: [10, 20, 30],
                        trHeight: 40,
                        remainArea: false,
                        rowClickFunc: (item: any) => {
                            if (location.pathname === "/admin/notice" || location.pathname === "/") {
                                navigate("/admin/notice/view/" + item.boardSn);
                            } else if (location.pathname === "/portal/notice") {
                                navigate("/portal/notice/view/" + item.boardSn);
                            } else if (location.pathname === "/system/expert") {
                                navigate("/system/expert/view/" + item.boardSn);
                            }
                        }
                    }}>
                    </MuiListFilter>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Board;