import React from 'react';
import JSEncrypt from 'jsencrypt'
import { useForm } from "react-hook-form";
import Axios from 'js/common/Axios';
import AppContext from 'js/common/AppContext';

function Join() {
    const [emailAdres, setEmailAdres] = React.useState<string>(''); // 이메일
    const [userNm, setUserNm] = React.useState<string>(''); // 이름
    const [passwordPlainTxt, setPasswordPlainTxt] = React.useState<string>(''); // 비밀번호
    const [confirmPw, setConfirmPw] = React.useState<string>(''); // 비밀번호 확인
    const [mbtlnum, setMbtlnum] = React.useState<string>(''); // 전화번호
    const [certNum, setCertNum] = React.useState<string>(''); // 인증번호

    const [emailDupChk, setEmailDupChk] = React.useState<boolean>(); // 이메일 중복확인버튼 flag 
    const [certPhone, setCertPhone] = React.useState<boolean>(); // 핸드폰 인증확인버튼 flag
    const { handleSubmit} = useForm();
    
    const handleInputEmail = (e: { target: { value: React.SetStateAction<string> } }) => {
        setEmailAdres(e.target.value);
    }
    const handleInputUserName = (e: { target: { value: React.SetStateAction<string> } }) => {
        setUserNm(e.target.value);
    }
    const handleInputPassword = (e: { target: { value: React.SetStateAction<string> } }) => {
        setPasswordPlainTxt(e.target.value);
    }
    const handleInputConfirmPw = (e: { target: { value: React.SetStateAction<string> } }) => {
        setConfirmPw(e.target.value);
    }
    const handleInputMbtlnum = (e: { target: { value: React.SetStateAction<string> } }) => {
        setMbtlnum(e.target.value);
    }
    const handleInputCertNum = (e: { target: { value: React.SetStateAction<string> } }) => {
        setCertNum(e.target.value);
    }

    // 가입 버튼 클릭 (onSubmit)
    const onSubmit = (data: any, event: any) => {
        event.preventDefault();

        if (!emailDupChk) {
            alert("이메일 중복확인을 해주세요. ");
        }
        if (!certPhone) {
            alert("전화번호 인증 확인을 해주세요. ");
        }

        // Axios 요청 - 비밀번호 암호화 키 요청
        Axios.dataAccess({
            url: "portal/auth/gen-key.do",
            methodType: "post",
            paramData: {},
            onSuccessFn: function (res: any) {
                const encrypt = new JSEncrypt();
                encrypt.setPublicKey(res.pubKeyStr); // 공개키 셋팅
                const encPw = encrypt.encrypt(data.passwordPlainTxt)// 암호화된 비밀번호
                console.log("암호화 비밀번호 확인", encPw);

            }
        })
        // Axios 요청 - 회원가입 폼 데이터 전송
        Axios.dataAccess({
            url: "portal/auth/signup.do",
            methodType: "post",
            paramData: {
                emailAdres: emailAdres, // 이메일
                userNm: userNm, // 사용자이름
                password: passwordPlainTxt, // 비밀번호 
                mbtlnum: data.mbtlnum, // 전화번호 
            },
            onSuccessFn: function (res: any) {
                console.log('회원가입 완료 확인!', res);
            }
        });
    }

    // Axios 요청 - 이메일 중복확인 요청
    const onClickDupChkBtn = () => {
        setEmailDupChk(true);
        Axios.dataAccess({
            url: "portal/join/emailDupChk.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                emailAdres: emailAdres, // 이메일
            },
            onSuccessFn: function (res: any) {
                AppContext.openAlert({
                    title: "이메일 중복확인 완료",
                    msg: "등록한 이메일은 사용가능합니다.",
                    icon: "check",
                });
            }
        });
    }

    // Axios 요청 - 전화번호 중복확인 요청
    const onClickCertPhoneBtn = () => {
        setCertPhone(true);
        Axios.dataAccess({
            url: "portal/join/mbtlnumDupChk.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                mbtlnum: mbtlnum // 전화번호
            },
            onSuccessFn: function () {
                AppContext.openAlert({
                    title: "전화번호 중복확인 완료",
                    msg: "등록한 전화번호는 사용가능합니다.",
                    icon: "check",
                });
            }
        });
        // Axios 요청 - 인증번호 발송 요청
        Axios.dataAccess({
            url: "portal/join/certPhone.do",
            methodType: "post",
            paramData: {
                mbtlnum: mbtlnum // 전화번호
            },
            onSuccessFn: function () {
                AppContext.openAlert({
                    title: "인증번호 발송완료",
                    msg: "등록한 전화번호로 인증번호를 발송했습니다.",
                    icon: "check",
                });
            }
        });
    }

    // Axios 요청 - 전화번호 인증번호 확인
    const onClickConfirmCertNumBtn = () => {
        console.log("certNum", certNum);
        if (certNum === '0000') { 
            AppContext.openAlert({
                title: "인증번호 확인완료",
                msg: "인증 되었습니다.",
                icon: "check",
            });
        }
        // TODO 문자인증 연계후 구현 예정
        // Axios.dataAccess({
        //     url: "portal/join/certNumChk.do",
        //     methodType: "post",
        //     paramData: {
        //         mbtlnum: mbtlnum, // 전화번호
        //         certNum: certNum // 인증번호
        //     },
        //     onSuccessFn: function (res: any) {
        //         console.log('전화번호 인증 중복확인!', res);
        //     }
        // });
    }

    return (
        <div className="join dpFlx jcC fm-b">
            <div className="w460 mt88 mb110">
                <h2 className='lh40 ls06 m0 mb25 taC fs39'>회원가입</h2>
                <div className="p40 bd1 bdcDFDFDF bdrd3 bgcFAFAFA">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="dpFlx mb10">
                            <input type="email" name={emailAdres} value={emailAdres} onChange={handleInputEmail} placeholder="이메일 주소" title="이메일 주소" 
                                className="flx1 mxw278 h42 pl14 pr14 fs14 ls06 bd1 bdcDFDFDF focusBorder bdrd5 bdBox fm-b" 
                            />
                            <button type="button" className="csP w92 ml8 bd0 bdrd5 fs14 fcWhite bgc96c832 fm-b" id="dupChkBtn" onClick={onClickDupChkBtn}>중복확인</button>
                        </div>
                        <div className="dpFlx mb10">
                            <input type="" name={userNm} value={userNm} onChange={handleInputUserName} placeholder="성명" title="성명"
                                className="flx1 mxw278 h42 pl14 pr14 fs14 ls06 bd1 bdcDFDFDF focusBorder bdrd5 bdBox fm-b"
                            />
                        </div>
                        <div className="dpFlx mb10">
                            <input type="password" name={passwordPlainTxt} value={passwordPlainTxt} onChange={handleInputPassword} title="비밀번호" placeholder="비밀번호"
                                className="flx1 mxw278 h42 pl14 pr14 fs14 ls06 bd1 bdcDFDFDF focusBorder bdrd5 bdBox fm-b" 
                            />
                        </div>
                        <div className="dpFlx mb10">
                            <input type="password" name={confirmPw} value={confirmPw} onChange={handleInputConfirmPw} title="비밀번호 확인" placeholder="비밀번호 확인"
                                className="flx1 mxw278 h42 pl14 pr14 fs14 ls06 bd1 bdcDFDFDF focusBorder bdrd5 bdBox fm-b"
                            />
                        </div>
                        <div className="dpFlx mb10">
                            <input type="tel" name={mbtlnum} value={mbtlnum} onChange={handleInputMbtlnum} title="전화번호" placeholder="전화번호"
                                className="flx1 mxw278 h42 pl14 pr14 fs14 ls06 bd1 bdcDFDFDF focusBorder bdrd5 bdBox fm-b"
                            />
                            <button type="button" id="certPhoneBtn" onClick={onClickCertPhoneBtn} className="csP w92 ml8 bd0 bdrd5 fs14 fcWhite bgc96c832 fm-b">인증</button>
                        </div>
                        <div className="dpFlx">
                            <input type="tel" name={certNum} value={certNum} onChange={handleInputCertNum} title="인증번호 입력" placeholder="인증번호 입력"
                                className="flx1 mxw278 h42 pl14 pr14 fs14 ls06 bd1 bdcDFDFDF focusBorder bdrd5 bdBox fm-b"
                            />
                            <button type="button" id="confirmCertNumBtn" onClick={onClickConfirmCertNumBtn} className="csP w92 ml8 bd0 bdrd5 fs14 fcWhite bgc96c832 fm-b">확인</button>
                        </div>
                        <button type="submit" id="sbscrbBtn" className="csP w100p h48 lh42 ls06 mt30 bd1 bdcDFDFDF bd0 bdrd5 fs18 fcWhite bgcBtnSave fm-b">가입</button>
                    </form>
                </div >
            </div >
        </div >
    )
}
export default Join