import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from "recoil";
import GlobalAlert from "js/common/ui/alert/GlobalAlert";
import App from './js/App';
import 'css/index.css';
import 'css/common.css';
import 'css/style.css';
import 'css/table.css';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
        <RecoilRoot>
            <GlobalAlert />
            <App />
        </RecoilRoot>
    </BrowserRouter>
);

// root.render(
    /*부작용 검사 모드, 개발모드에서만 실행되지만 일단 주석처리*/
    /*<React.StrictMode>*/
    // <App />
    /*</React.StrictMode>*/
// );

/*
    // metric(측정도구) 이름
    name: 'CLS' | 'FCP' | 'FID' | 'LCP' | 'TTFB';
    // 측정된 현재값 (값이 작을수록 빠른성능을 뜻합니다)
    value: number;
    //  현재 측정값(current value)과 최신 측정값(last-reported value) 차이
    //  첫번째 리포트에서 위 둘값은 항상 같습니다.
    delta: number;
    // 특정 측정도구를 나타대는 유니크한 ID 값으로 중복되는 값들을 관리할 때 사용된다.
    id: string;
    // 계산된 측정값들의 내용들이 배열로 나열 된다.
    // ex) PerformanceNavigationTiming, LargestContentfulPaint
    entries: (PerformanceEntry | FirstInputPolyfillEntry | NavigationTimingPolyfillEntry)[];
*/
reportWebVitals(console.log);