import { Link } from "react-router-dom";

function Footer() {
    return (
        <div className="dpFlx aiC jcC bgc505050 posF w100p b0 ph10">
            <div className="w1200 h70 dpFlx aiC jcSb">
                <span className="lh18">
                    <span className='fm-b fcWhite fs12'>(주)아레오파고스</span>
                    <span className='fm-b fcWhite fs12 ml10'>대표이사 : 이광민, 박종영, 장진우</span>
                    <br />
                    <span className='fm-b fcWhite fs12 mr10'>사업자등록번호 : 869-86-03147</span>
                    <span className='fm-b fcWhite fs12 mr10'>Tel : 02-1234-0000</span>
                    <span className='fm-b fcWhite fs12 mr10'>Fax : 02-1234-0000</span>
                    <Link to="/portal/privacy">
                        <span className="fm-b fcWhite fs12 mr5">개인정보취급방침</span>
                    </Link>
                    <Link to="/portal/termuse">
                        <span className="fm-b fcWhite fs12">이용약관</span>
                    </Link>
                    <br />
                    <span className="fm-b fcWhite fs12 mr10">Copyright  ©  ssapan.co.kr  All rights reserved.</span>
                </span>
                <img className="csP w80" src={require('img/portal/logo-white.png')} alt="footer logo" />
            </div>
        </div>
    )
}
export default Footer;