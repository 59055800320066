import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@mui/material";
import useAlert from "./UseAlert";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ForestStyle from "js/common/ui/style/CustomStyle";
import IconButton from "js/common/ui/button/IconButton";
import Util from "js/common/Util";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';

const AlertIcon = ({iconFg}: any) => {
    if (iconFg === "block") {
        return (
            <BlockOutlinedIcon sx={{padding: 0, fontSize: 45, color: "crimson"}} />
        )
    } else if (iconFg === "check") {
        return (
            <CheckCircleOutlineOutlinedIcon sx={{padding: 0, fontSize: 45, color: "#247979"}} />
        )
    } else if (iconFg === "error") {
        return (
            <CancelOutlinedIcon sx={{padding: 0, fontSize: 45, color: "red"}} />
        )
    } else if (iconFg === "help") {
        return (
            <HelpOutlineOutlinedIcon sx={{padding: 0, fontSize: 45, color: "#004080"}} />
        )
    } else if (iconFg === "warning") {
        return (
            <ErrorOutlineOutlinedIcon sx={{padding: 0, fontSize: 45, color: "#800000"}} />
        )
    } else {
        return (
            <></>
        )
    }
}

export interface AlertProps {
    title?: string;
    message: string;
    iconFg:  string;
    closeText?: string;
    confirmText?: string;
    handleClose?: (...arg: any[]) => any;
    handleConfirm?: (...arg: any[]) => any;
}

const Alert = ({
    title,
    message,
    iconFg,
    closeText,
    confirmText,
    handleClose,
    handleConfirm
}: AlertProps) => {
    const { hideAlert } = useAlert();

    const onClose = () => {
        if (handleClose) {
            handleClose();
        }
        hideAlert();
    };

    const onConfirm = async () => {
        if (handleConfirm) {
            await handleConfirm();
        }
        hideAlert();
    };

    return (
        <Dialog
            open
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            // maxWidth="sm"
            // fullWidth
            sx={ForestStyle.alert}
        >
            <DialogTitle id="alert-header">
                <span className="fm-c pl15 ls11">{title}</span>
                <CloseOutlinedIcon className="csP pr10" onClick={onClose} />
            </DialogTitle>
            <DialogContent>
                <div className="pt20 pr20 pl20 pb10 mw250 mh50 dpFlx aiC">
                    <div className="dpFlx aiC w50 pr10">
                        <AlertIcon iconFg={iconFg} />
                    </div>
                    <span className="fm-b fs14 ls11">{message}</span>
                </div>
            </DialogContent>
            <DialogActions>
                {Util.isNotEmpty(confirmText) ? 
                    <IconButton btnName={confirmText} iconPos="right" iconNm="check" className="mw70 h32 bdrd3 bdN fs13 fcWhite bgc1E413A" onClick={onConfirm} autoFocus />
                : <></>}
                <IconButton btnName={Util.isEmpty(closeText) ? "닫기" : closeText} iconPos="right" iconNm="close" className="mw70 h32 bdrd3 bdN bdc878787 fs13 fcWhite bgcBtnClose" onClick={onClose} autoFocus />
            </DialogActions>
        </Dialog>
    );
};
export default Alert;