import { useRecoilState } from "recoil";
import Alert from "./Alert";
import { alertState } from "./RecoilAlert";

export const TYPES = {
    Alert: "Alert",
} as const;

const COMPONENTS: any = {
    [TYPES.Alert]: Alert
};

const GlobalAlert = () => {
    const { alertProps } = useRecoilState(alertState)[0] || {};

    const renderComponent = () => {
        if (!alertProps) {
            return null;
        }
        const ModalComponent = COMPONENTS["Alert"];

        return <ModalComponent {...alertProps} />;
    };

    return <>{renderComponent()}</>;
};
export default GlobalAlert;