import Axios from "js/common/Axios";

function SysMng() {
    const logDown = () => {
        Axios.dataAccess({
            url: "admin/sysmng/logDown.do",
            methodType: "get",
            isFileDown: true
        });
    }

    return (
        <div className='w100p h500 jcC aiC dpFlx bgcBlueviolet'>
            <span className='fcWhite fs50 csP' onClick={logDown}>관리자 시스템관리 페이지 내용이 들어갈 영역입니다.</span>
        </div>
    )
}
export default SysMng