import { useNavigate, useParams } from "react-router-dom";
import Axios from 'js/common/Axios';
import React from 'react';
import ViewListIcon from '@mui/icons-material/ViewList';
import IconButton from 'js/common/ui/button/IconButton';
import Util from "js/common/Util";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import MuiListFilter from "js/common/ui/list/MuiListFilter";
import CustomStyle from 'js/common/ui/style/CustomStyle';
import AppContext from "js/common/AppContext";

const JuryView = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [juryInfo, setJuryInfo] = React.useState<any>({});

    interface juryPicProps {
        original: string
        thumbnail: string
    }
    const [juryPicList, setJuryPicList] = React.useState<juryPicProps[]>([]);
    const [reqJurordataArr, setReqJurordataArr] = React.useState<any[]>([]);
    const [defJurordataArr, setDefJurordataArr] = React.useState<any[]>([]);
    const [replydataArr, setReplydataArr] = React.useState<any[]>([]);
    const [crimedataArr, setCrimedataArr] = React.useState<any[]>([]);

    const sendAlarmJury = () => {
        AppContext.openAlert({
            title: "재판 알림발송",
            msg: "푸시알림을 발송하시겠습니까?",
            icon: "check",
            confirmText: "발송",
            handleConfirm: () => {
                AppContext.showSpinner();
                Axios.dataAccess({
                    url: "admin/jury/sendAlarmJury.do",
                    methodType: "post",
                    paramType: "object",
                    paramData: {
                        lawSn: Number(params.lawSn)
                    },
                    onSuccessFn: (res: any) => {
                        AppContext.openAlert({
                            title: "알림발송 완료",
                            msg: "재판 알림이 발송되었습니다.",
                            icon: "check"
                        });
                        AppContext.hideSpinner();
                    }
                });
            }
        });
    }

    const goBack = () => {
        if (params.callPage === "juryList") {
            navigate("/admin/jury");
        } else if (params.callPage === "userJuryList") {
            navigate("/admin/user/jury/" + params.userSn);
        } else if (params.callPage === "userJurorList") {
            navigate("/admin/user/juror/" + params.userSn);
        }
    }

    React.useEffect(() => {
        Axios.dataAccess({
            url: "admin/jury/selJuryInfo.do",
            methodType: "post",
            paramType: "object",
            paramData: {
                lawSn: Number(params.lawSn)
            },
            onSuccessFn: (res: any) => {
                setJuryInfo(res.item.juryInfo);

                const tempJuryPicList: juryPicProps[] = [];
                res.item.picList.forEach((picInfo: any) => {
                    const picUrl = AppContext.backendUrl + "/web-data-store/jury/" + picInfo.lawSn + "-" + picInfo.picSn + "." + picInfo.fileExtsnNm;
                    tempJuryPicList.push({
                        original: picUrl,
                        thumbnail: picUrl
                    });
                });
                setJuryPicList(tempJuryPicList);
                setReqJurordataArr(res.item.reqJurorList);
                setDefJurordataArr(res.item.defJurorList);
                setReplydataArr(res.item.replyList);
                setCrimedataArr(res.item.crimeList);
            }
        });
    }, [params.lawSn]);

    return (
        <div className='dpFlx jcC'>
            <div className='w1200'>
                <div className="dpFlx jcC aiC h120">
                    <span className="fm-c fc222222 fs40 ls12">재판 조회</span>
                </div>
                <div className="dpFlx aiC jcSb">
                    <div className='dpFlx aiC'>
                        <ViewListIcon style={{ fontSize: 24 }} />
                        <span className="fs16 fm-c ls1 pl5">재판 기본정보</span>
                    </div>
                    <div className='dpFlx jcSb aiC'>
                        <IconButton btnName="목록으로" iconPos="left" iconNm="list" className="h35 bdN fs14 fcWhite bgcA4A4A4 mr3" onClick={goBack} />
                        {params.callPage === "juryList" ?
                            <React.Fragment>
                                <IconButton btnName="수정하기" iconPos="left" iconNm="write" className="h35 bdN fs14 fcWhite bgc1E413A mr3" onClick={() => navigate("/admin/jury/mod/" + params.lawSn)} />
                                <IconButton btnName="알림발송" iconPos="left" iconNm="check" className="h35 bdN fs14 fcWhite bgcBtnConfirm" onClick={sendAlarmJury} />
                            </React.Fragment>
                        : <></>}
                    </div>
                </div>
                <table className="form-table td-ls06 td-p-row td-fs13 tr-h43 mt10">
                    <colgroup>
                        <col className="w20p" />
                        <col className="w30p" />
                        <col className="w20p" />
                        <col className="w30p" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="taC">재판식별번호</th>
                            <td className="taC">{juryInfo.lawSn}</td>
                            <th className="taC">의뢰자</th>
                            <td className="taC">{juryInfo.fstInsNm}</td>
                        </tr>
                        <tr>
                            <th className="taC">사건번호</th>
                            <td className="taC">{juryInfo.lawLabel}</td>
                            <th className="taC">공개여부</th>
                            <td className="taC">{Util.getValToCode("1002", juryInfo.lawOpenCode)}</td>
                        </tr>
                        <tr>
                            <th className="taC">재판시간</th>
                            <td className="taC">{juryInfo.lawTime}</td>
                            <th className="taC">시작시간</th>
                            <td className="taC">{juryInfo.reqDt}</td>
                        </tr>
                        <tr>
                            <th className="taC">19금여부</th>
                            <td className="taC">{Util.getValToCode("1014", juryInfo.RRatedAt)}</td>
                            <th className="taC">주간베스트순위</th>
                            <td className="taC">{juryInfo.weekRank}</td>
                        </tr>
                        <tr>
                            <th className="taC">원고</th>
                            <td className="taC">{juryInfo.reqUserNm}</td>
                            <th className="taC">피고</th>
                            <td className="taC">{juryInfo.defUserNm}</td>
                        </tr>
                        <tr>
                            <th className="taC">조회수</th>
                            <td className="taC">{juryInfo.viewCnt}</td>
                            <th className="taC">사용자조회상태</th>
                            <td className="taC">{Util.getValToCode("1007", juryInfo.viewPosCode)}</td>
                        </tr>
                        <tr>
                            <th className="taC">재판제목</th>
                            <td className="taL" colSpan={3}>{juryInfo.lawTitle}</td>
                        </tr>
                        <tr>
                            <th className="taC">내용</th>
                            <td className="taL" colSpan={3}>
                                <div className="mt10 mb10">
                                    <span className="lh20 wsPw">{juryInfo.lawCtt}</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className='dpFlx aiC mt30 mb10'>
                    <ViewListIcon style={{ fontSize: 24 }} />
                    <span className="fs16 fmForest-c ls1 pl5">원고 배심원</span>
                </div>
                <MuiListFilter tableInfo={{
                    headVsb: true,
                    styleType: CustomStyle,
                    fldInfs: [
                        { type: 'text', fldNm: 'jurorVdctCtt', wAuto: true, headAlign: "center", dataAlign: "center", label: '판결내용' },
                        { type: 'text', fldNm: 'fstInsDt', width: 160, headAlign: "center", dataAlign: "center", label: '판결일자' },
                        { type: 'text', fldNm: 'jurorUserNm', width: 120, headAlign: "center", dataAlign: "center", label: '배심원' },
                    ],
                    ordFld: { name: "fstInsDt", direction: "asc" },
                    dataArr: reqJurordataArr,
                    maxRowNums: [10],
                    trHeight: 40,
                    remainArea: false
                }}>
                </MuiListFilter>
                <div className='dpFlx aiC mt30 mb10'>
                    <ViewListIcon style={{ fontSize: 24 }} />
                    <span className="fs16 fmForest-c ls1 pl5">피고 배심원</span>
                </div>
                <MuiListFilter tableInfo={{
                    headVsb: true,
                    styleType: CustomStyle,
                    fldInfs: [
                        { type: 'text', fldNm: 'jurorVdctCtt', wAuto: true, headAlign: "center", dataAlign: "center", label: '판결내용' },
                        { type: 'text', fldNm: 'fstInsDt', width: 160, headAlign: "center", dataAlign: "center", label: '판결일자' },
                        { type: 'text', fldNm: 'jurorUserNm', width: 120, headAlign: "center", dataAlign: "center", label: '배심원' },
                    ],
                    ordFld: { name: "fstInsDt", direction: "asc" },
                    dataArr: defJurordataArr,
                    maxRowNums: [10],
                    trHeight: 40,
                    remainArea: false
                }}>
                </MuiListFilter>
                <div className='dpFlx aiC mt30 mb10'>
                    <ViewListIcon style={{ fontSize: 24 }} />
                    <span className="fs16 fmForest-c ls1 pl5">댓글목록</span>
                </div>
                <MuiListFilter tableInfo={{
                    headVsb: true,
                    styleType: CustomStyle,
                    fldInfs: [
                        { type: 'text', fldNm: 'replyCtt', wAuto: true, headAlign: "center", dataAlign: "center", label: '댓글내용' },
                        { type: 'text', fldNm: 'fstInsDt', width: 160, headAlign: "center", dataAlign: "center", label: '댓글작성일자' },
                        { type: 'text', fldNm: 'nickName', width: 120, headAlign: "center", dataAlign: "center", label: '작성자' },
                    ],
                    ordFld: { name: "fstInsDt", direction: "asc" },
                    dataArr: replydataArr,
                    maxRowNums: [10],
                    trHeight: 40,
                    remainArea: false
                }}>
                </MuiListFilter>
                <div className='dpFlx aiC mt30 mb10'>
                    <ViewListIcon style={{ fontSize: 24 }} />
                    <span className="fs16 fmForest-c ls1 pl5">신고목록</span>
                </div>
                <MuiListFilter tableInfo={{
                    headVsb: true,
                    styleType: CustomStyle,
                    fldInfs: [
                        { type: 'code', fldNm: 'crimeCode', width: 200, headAlign: "center", dataAlign: "center", label: '신고사유', codeTy: "1006" },
                        { type: 'text', fldNm: 'crimeCtt', wAuto: true, headAlign: "center", dataAlign: "center", label: '신고내용' },
                        { type: 'text', fldNm: 'fstInsDt', width: 160, headAlign: "center", dataAlign: "center", label: '신고일자' },
                        { type: 'text', fldNm: 'fstInsNm', width: 120, headAlign: "center", dataAlign: "center", label: '신고자' },
                    ],
                    ordFld: { name: "fstInsDt", direction: "asc" },
                    dataArr: crimedataArr,
                    maxRowNums: [10],
                    trHeight: 40,
                    remainArea: false
                }}>
                </MuiListFilter>
                {juryPicList.length > 0 ?
                    <React.Fragment>
                        <div className='dpFlx aiC mt30 mb10'>
                            <ViewListIcon style={{ fontSize: 24 }} />
                            <span className="fs16 fmForest-c ls1 pl5">재판 첨부사진</span>
                        </div>
                        <ImageGallery items={juryPicList} />
                    </React.Fragment>
                : <></>}
                <div className='dpFlx jcC aiC mt20'>
                    <IconButton btnName="목록으로" iconPos="left" iconNm="list" className="h35 bdN fs14 fcWhite bgcA4A4A4 mr3" onClick={goBack} />
                    {params.callPage === "juryList" ?
                        <React.Fragment>
                            <IconButton btnName="수정하기" iconPos="left" iconNm="write" className="h35 bdN fs14 fcWhite bgc1E413A mr3" onClick={() => navigate("/admin/jury/mod/" + params.lawSn)} />
                            <IconButton btnName="알림발송" iconPos="left" iconNm="check" className="h35 bdN fs14 fcWhite bgcBtnConfirm" onClick={sendAlarmJury} />
                        </React.Fragment>
                    : <></>}
                </div>
            </div>
        </div>
    )
}
export default JuryView;