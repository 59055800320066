import { atom } from "recoil";
import { AlertProps } from "./Alert";

export interface AlertType {
    alertProps: AlertProps;
}
export type Type = AlertType;

export const alertState = atom<Type | null>({
    key: "alertState",
    default: null
});